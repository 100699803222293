import styled from "styled-components";
import { Colors, ScreenSizes } from "../../../../styles/Themes";
import { useSelector } from "react-redux";

const RedeemItemShowEl = styled.div`
  width: 560px;
  height: 200px;
  display: flex;
  padding: 8px 24px;
  box-sizing: border-box;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  background-color: ${Colors.gr700};
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 262px;
    height: 174px;
  }
`;

const RedeemTitleEl = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RedeemTitleText = styled.span`
  color: ${props => props.theme.highlight};
  text-align: center;
  font-size: 16px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 800;
  line-height: 160%;
  letter-spacing: -0.16px;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 14px;
    letter-spacing: -0.14px;
  }
`;
const RedeemItemEl = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
`;

const RedeemItemVideo = styled.video`
  width: 112px;
  height: auto;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 80px;
  }
`;

const RedeemItemInfoEl = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const RedeemItemName = styled.span`
  color: ${props => props.theme.highlight};
  font-size: 20px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 14px;
  }
`;

const RedeemItemQuantity = styled.span`
  color: #fff;
  font-size: 20px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 100px;
  display: flex;
  justify-content: flex-end;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 14px;
  }
`;

const TitleLine = styled.div`
  width: 424px;
  border: 0.5px solid #484747;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 136px;
  }
`;

function ConfirmRedeemItem({ showData, redeemQuantity }) {
  return (
    <RedeemItemShowEl>
      <RedeemTitleEl>
        <RedeemTitleText>Receiving</RedeemTitleText>
        <TitleLine />
      </RedeemTitleEl>
      <RedeemItemEl>
        <RedeemItemVideo autoPlay muted loop playsInline>
          <source src={showData.publicData.redeemAssetMedia} type="video/mp4" />
        </RedeemItemVideo>
        <RedeemItemInfoEl>
          <RedeemItemName>{showData.publicData.redeemTokenName}</RedeemItemName>
        </RedeemItemInfoEl>
        <RedeemItemQuantity>x {redeemQuantity}</RedeemItemQuantity>
      </RedeemItemEl>
    </RedeemItemShowEl>
  );
}
export default ConfirmRedeemItem;
