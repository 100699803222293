import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isMobileMenuOpen: false,
  isBlur: false,
  isWalletModalOpen: false,
  isFreeClaimModalOpen: false,
  isNotEligibleModalOpen: false,
  isNoticeModalOpen: false,
  isBurnModalOpen: false,
  isBonusBurnModalOpen: false,
  isArchiveModalOpen: false,
  isRedeemModalOpen: false,
  isBTAModalOpen: false,
  archiveModalChapter: 0,
  archiveModalStage: 0,
  burnModalStep: 0,
  hiddenCount: 0,
  isProcessing: false,
};

const componentSlice = createSlice({
  name: "component",
  initialState,
  reducers: {
    setBurnModalStep(state, action) {
      state.burnModalStep = action.payload;
    },
    setMobileMenuOpen(state) {
      state.isMobileMenuOpen = !state.isMobileMenuOpen;
    },
    setIsBlur(state, action) {
      state.isBlur = action.payload;
    },
    setIsWalletModalOpen(state, action) {
      state.isWalletModalOpen = action.payload;
    },
    setIsNotEligibleModalOpen(state, action) {
      state.isNotEligibleModalOpen = action.payload;
    },
    setIsFreeClaimModalOpen(state, action) {
      state.isFreeClaimModalOpen = action.payload;
    },
    increaseHiddenCount(state) {
      state.hiddenCount++;
    },
    setIsNoticeModalOpen(state, action) {
      state.isNoticeModalOpen = action.payload;
    },
    setIsBurnModalOpen(state, action) {
      state.isBurnModalOpen = action.payload;
    },
    setIsBonusBurnModalOpen(state, action) {
      state.isBonusBurnModalOpen = action.payload;
    },
    setIsArchiveModalOpen(state, action) {
      state.isArchiveModalOpen = action.payload;
    },
    setIsBTAModalOpen(state, action) {
      state.isBTAModalOpen = action.payload;
    },
    setIsRedeemModalOpen(state, action) {
      state.isRedeemModalOpen = action.payload;
    },
    setArchiveModalChapter(state, action) {
      console.log("setArchiveModalChapter : ", action.payload);
      state.archiveModalChapter = action.payload;
    },
    setArchiveModalStage(state, action) {
      console.log("setArchiveModalStage : ", action.payload);
      state.archiveModalStage = action.payload;
    },
    setIsProcessing(state, action) {
      console.log("setIsProcessing : ", action.payload);
      state.isProcessing = action.payload;
    },
  },
});

export const componentActions = componentSlice.actions;
export default componentSlice.reducer;
