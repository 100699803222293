import styled from "styled-components";

const LoadingEl = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProgressImg = styled.img`
  width: 48px;
  height: 48px;
`;

function LoadingPage() {
  return (
    <LoadingEl>
      <ProgressImg src={"/images/progress_gif.gif"} />
    </LoadingEl>
  );
}
export default LoadingPage;
