import styled from "styled-components";
import { ScreenSizes } from "../../styles/Themes";

const VideoEl = styled.div`
  width: 100%;
  /* padding: 16px 0px 16px 0px; */
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 0px 5px 0px 5px;
  }
`;

const MainVideo = styled.video`
  width: 600px;
  height: auto;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 100%;
    height: auto;
  }
`;

function LoginVideo(){
return (
    <VideoEl>
        <MainVideo autoPlay muted loop playsInline>
          <source
            src={"https://burningsouls.com/public/videos/BTA_artwork.mp4"}
            type="video/mp4"
          />
        </MainVideo>
      </VideoEl>
);
}export default LoginVideo;