import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios";
import BaseContractInfo from "../contracts/BaseContractInfo.json";
import TestBaseContractInfo from "../contracts/TestBaseContractInfo.json";

//  STATUS VALUE
//  0 : WAIT
//  1 : LOADING
//  2 : FINISH
//  3 : ERROR
const initialServerSlice = {
  settingDataStatus: 0,
  settingData: undefined,
  rawBurnSet: undefined,
  showStage: { state: 0, chapter: 0, stage: 0 },
  showData: undefined,
  isMultiBurn: false,
  maxRedeemCount: 0,
  rawArchiveData: undefined,
  isExternal: false,

  isBonusOpen: false,
  isBonusMain: false,
  isBtaFinish: false,
  btaUser:"",

  bonusRawBurnSet: undefined,
  bonusShowStage: { state: 0, chapter: 0, stage: 0 },
  bonusShowData: undefined,
  bonusIsMultiBurn: false,
  bonusMaxRedeemCount: 0,
  bonusRawArchiveData: undefined,
  bonusIsExternal: false,

  BTARawBurnSet: undefined,
  BTAShowData: undefined,
  BTAIsMultiBurn: true,
  BTARawArchiveData: undefined,
};

const serverSlice = createSlice({
  name: "serverSlice",
  initialState: initialServerSlice,
  reducers: {
    getMaxRedeemCount(state, action) {
      let maxCount = 999999;
      const burnSet = state.showData.publicData.burnSet;
      const tokenIdsInWallet = action.payload;
      console.log("getMaxRedeemCount", current(burnSet));
      for (let i = 0; i < burnSet.length; i++) {
        const minTokenId = Number(burnSet[i].items[0].minTokenId);
        const maxTokenId = Number(burnSet[i].items[0].maxTokenId);
        const requiredCount = burnSet[i].requiredCount;
        let ownCount = 0;
        for (let j = 0; j < tokenIdsInWallet.length; j++) {
          if (
            minTokenId <= tokenIdsInWallet[j] &&
            tokenIdsInWallet[j] <= maxTokenId
          ) {
            ownCount++;
          }
        }
        if (maxCount > Math.floor(ownCount / requiredCount)) {
          maxCount = Math.floor(ownCount / requiredCount);
        }
      }
      state.maxRedeemCount = maxCount;
    },
    getBonusMaxRedeemCount(state, action) {
      let maxCount = 999999;
      const burnSet = state.bonusShowData.publicData.burnSet;
      const tokenIdsInWallet = action.payload;
      console.log("getMaxRedeemCount", current(burnSet));
      for (let i = 0; i < burnSet.length; i++) {
        const minTokenId = Number(burnSet[i].items[0].minTokenId);
        const maxTokenId = Number(burnSet[i].items[0].maxTokenId);
        const requiredCount = burnSet[i].requiredCount;
        let ownCount = 0;
        for (let j = 0; j < tokenIdsInWallet.length; j++) {
          if (
            minTokenId <= tokenIdsInWallet[j] &&
            tokenIdsInWallet[j] <= maxTokenId
          ) {
            ownCount++;
          }
        }
        if (maxCount > Math.floor(ownCount / requiredCount)) {
          maxCount = Math.floor(ownCount / requiredCount);
        }
      }
      state.bonusMaxRedeemCount = maxCount;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncGetSettingData.pending, (state, action) => {
        state.settingDataStatus = 1;
      })
      .addCase(asyncGetSettingData.fulfilled, (state, action) => {
        //how to control end stage?
        console.log("asyncGetSettingData", action.payload);
        state.settingData = action.payload.setting;
        console.log("isBonusOpen", action.payload.bonus.isBonusOpen);
        state.isBonusOpen = action.payload.bonus.isBonusOpen;
        state.isBtaFinish = action.payload.bta.isFinish;
        state.isBonusMain = action.payload.main.isBonusMain;
        state.btaUser = action.payload.bta.btaUser;
        console.log("btaStatus", state.isBtaFinish);
        state.showStage = selectData(state.settingData);
        state.bonusShowStage = bonusSelectData(state.settingData);

        console.log("state.bonusShowStage : ", state.bonusShowStage);
        state.rawBurnSet =
          state.settingData[state.showStage.chapter][
            state.showStage.stage
          ].publicData.burnSet;
        if (action.payload.bonus.isBonusOpen) {
          state.bonusRawBurnSet =
            state.settingData[state.bonusShowStage.chapter][
              state.bonusShowStage.stage
            ].publicData.burnSet;
        }
        state.BTARawBurnSet = state.settingData[5][0].publicData.burnSet;
        const showBurnSet = getShowBurnSet(
          state.settingData,
          state.showStage.chapter,
          state.showStage.stage
        );
        const isExternal = isBurnSetExternal(showBurnSet);
        state.isExternal = isExternal;

        let bonusShowBurnSet;
        if (action.payload.bonus.isBonusOpen) {
          bonusShowBurnSet = getShowBurnSet(
            state.settingData,
            state.bonusShowStage.chapter,
            state.bonusShowStage.stage
          );
        }

        const BTAShowBurnSet = getShowBurnSet(state.settingData, 5, 0);

        let bonusIsExternal;
        if (action.payload.bonus.isBonusOpen) {
          bonusIsExternal = isBurnSetExternal(bonusShowBurnSet);
        }
        state.bonusIsExternal = bonusIsExternal;

        state.rawArchiveData = getArchiveData(
          state.settingData,
          state.showStage.chapter,
          state.showStage.stage,
          state.showStage.state
        );
        state.bonusRawArchiveData = getArchiveData(
          state.settingData,
          state.bonusShowStage.chapter,
          state.bonusShowStage.stage,
          state.bonusShowStage.state
        );
        state.BTARawArchiveData = getArchiveData(state.settingData, 5, 0, 2);
        console.log("serverSlice rawArchiveData", state.BTARawArchiveData);

        let showData = JSON.parse(
          JSON.stringify(
            state.settingData[state.showStage.chapter][state.showStage.stage]
          )
        );

        let bonusShowData;
        if (action.payload.bonus.isBonusOpen) {
          bonusShowData = JSON.parse(
            JSON.stringify(
              state.settingData[state.bonusShowStage.chapter][
                state.bonusShowStage.stage
              ]
            )
          );
        }

        let BTAShowData = JSON.parse(JSON.stringify(state.settingData[5][0]));
        showData.publicData.burnSet = showBurnSet;
        if (action.payload.bonus.isBonusOpen) {
          bonusShowData.publicData.burnSet = bonusShowBurnSet;
        }
        BTAShowData.publicData.burnSet = BTAShowBurnSet;

        state.isMultiBurn =
          state.settingData[state.showStage.chapter][state.showStage.stage]
            .publicData.burnSet.length > 1;

        if (action.payload.bonus.isBonusOpen) {
          state.bonusIsMultiBurn =
            state.settingData[state.bonusShowStage.chapter][
              state.bonusShowStage.stage
            ].publicData.burnSet.length > 1;
        }

        state.showData = showData;
        state.bonusShowData = bonusShowData;
        state.BTAShowData = BTAShowData;
        console.log("serverSlice bonusShowData", bonusShowData);

        console.log("serverSlice settingData", state.settingData);
        console.log("serverSlice showData", state.showData);

        state.settingDataStatus = 2;
      })
      .addCase(asyncGetSettingData.rejected, (state, action) => {
        state.settingDataStatus = 3;
        console.log(action.payload);
      });
      
  },
});

const asyncGetSettingData = createAsyncThunk(
  "serverSlice/asyncGetSettingData",
  async () => {
    let result = {};
    const bonusRes = await axios.get(process.env.REACT_APP_API_URL + "/public/isBonusOpen");
    const mainRes = await axios.get(process.env.REACT_APP_API_URL + "/public/isBonusMain");
    const btaRes = await axios.get(process.env.REACT_APP_API_URL + "/public/btaStatus");
    const settingRes = await axios.get(process.env.REACT_APP_API_URL + "/public/burnSettingData");
    result.bonus = bonusRes.data;
    result.main = mainRes.data;
    result.bta = btaRes.data;
    result.setting = settingRes.data;
    return result;
  }
);

function selectData(settingData) {
  //  STAGE STATUS VALUE
  //  0 : WAIT
  //  1 : NOW
  //  2 : FINISH
  let waitStage = { chapter: 9999, stage: 9999 }; //waitStage need to be minimum stage
  let finishStage = { chapter: 0, stage: 0 }; //finishStage need to be maximum stage

  const now = Math.floor(new Date().getTime() / 1000);

  for (let chapter = 0; chapter < 4; chapter++) {
    for (let stage = 0; stage < settingData[chapter].length; stage++) {
      let stageData = settingData[chapter][stage];
      if (stageData.publicData.endDate === 0) {
        //endDate === 0 means burnRedeem is on going
        return { state: 1, chapter, stage };
      }
      if (
        stageData.publicData.startDate <= now &&
        now <= stageData.publicData.endDate
      ) {
        //burnRedeem is on going but before finish
        return { state: 1, chapter, stage };
      }
      if (
        stageData.publicData.endDate < now &&
        stageData.publicData.endDate !== 0
      ) {
        //this stage is finish, but we need to update when we find next finish state
        //because that is max(finishStage)
        finishStage = { chapter, stage };
      }
      if (now < stageData.publicData.startDate) {
        //this stage is before start
        //and we need to save first find waitStage === min(waitStage)
        if (waitStage.chapter === 9999) {
          waitStage = { chapter, stage };
        }
      }
    }
  }
  if (waitStage.chapter !== 9999) {
    //it means there is an waitStage
    return { state: 0, chapter: waitStage.chapter, stage: waitStage.stage };
  } else {
    //it means there is no waitStage
    //and there is only finishStage
    return { state: 2, chapter: finishStage.chapter, stage: finishStage.stage };
  }
}

function bonusSelectData(settingData) {
  //  STAGE STATUS VALUE
  //  0 : WAIT
  //  1 : NOW
  //  2 : FINISH
  let waitStage = { chapter: 9999, stage: 9999 }; //waitStage need to be minimum stage
  let finishStage = { chapter: 4, stage: 0 }; //finishStage need to be maximum stage

  const now = Math.floor(new Date().getTime() / 1000);

  for (let stage = 0; stage < settingData[4].length; stage++) {
    let stageData = settingData[4][stage];
    if (stageData.publicData.endDate === 0) {
      //endDate === 0 means burnRedeem is on going
      return { state: 1, chapter : 4, stage };
    }
    if (
      stageData.publicData.startDate <= now &&
      now <= stageData.publicData.endDate
    ) {
      //burnRedeem is on going but before finish
      return { state: 1, chapter : 4, stage };
    }
    if (
      stageData.publicData.endDate < now &&
      stageData.publicData.endDate !== 0
    ) {
      //this stage is finish, but we need to update when we find next finish state
      //because that is max(finishStage)
      finishStage = { chapter : 4, stage };
    }
    if (now < stageData.publicData.startDate) {
      //this stage is before start
      //and we need to save first find waitStage === min(waitStage)
      if (waitStage.stage === 9999) {
        waitStage = { chapter : 4, stage };
      }
    }
  }
  if (waitStage.stage !== 9999) {
    //it means there is an waitStage
    return { state: 0, chapter: waitStage.chapter, stage: waitStage.stage };
  } else {
    //it means there is no waitStage
    //and there is only finishStage
    return { state: 2, chapter: finishStage.chapter, stage: finishStage.stage };
  }
}

function getShowBurnSet(settingData, chapter, stage) {
  const rawBurnSet = settingData[chapter][stage].publicData.burnSet;
  console.log("getShowBurnSet",settingData);
  console.log("burnSet", rawBurnSet);
  console.log("set", new Set(rawBurnSet));
  console.log(JSON.stringify(rawBurnSet[0]) === JSON.stringify(rawBurnSet[1]));
  console.log(JSON.stringify(rawBurnSet[0]) === JSON.stringify(rawBurnSet[2]));
  let uniqueBurnSet = rawBurnSet.filter((item1, inedx1) => {
    return (
      rawBurnSet.findIndex((item2, index2) => {
        return JSON.stringify(item1) === JSON.stringify(item2);
      }) === inedx1
    );
  });

  for (let i = 0; i < uniqueBurnSet.length; i++) {
    uniqueBurnSet[i].requiredCount = 0;
    for (let j = 0; j < rawBurnSet.length; j++) {
      if (
        JSON.stringify(uniqueBurnSet[i].items) ===
        JSON.stringify(rawBurnSet[j].items)
      ) {
        uniqueBurnSet[i].requiredCount++;
      }
    }
  }
  console.log(`uniqueBurnSet`, uniqueBurnSet);
  return uniqueBurnSet;
}

function isBurnSetExternal(burnSet){
  let ContractAddress;
  if (process.env.REACT_APP_ENV_VER === "DEV") {
    ContractAddress = TestBaseContractInfo.ContractAddress;
  } else {
    ContractAddress = BaseContractInfo.ContractAddress;
  }
  let result = false;
  for(let i = 0 ; i < burnSet.length ; i++){
    if(burnSet[i].items[0].contractAddress.toLowerCase() !== ContractAddress.toLowerCase()){
      console.log("isBurnSetExternal : ",burnSet[i].items[0].contractAddress.toLowerCase() + "/" + ContractAddress.toLowerCase());
      result = true;
      break;
    }
  }
  return result;
}

function getArchiveData(settingData, showChapter, showStage, showState) {
  //  STAGE STATUS VALUE
  //  0 : WAIT
  //  1 : NOW
  //  2 : FINISH
  let lastStage = showStage;
  if (showState === 2) {
    lastStage++;
  }
  //if show state is finish
  //need to push last stage to archive
  let resultData = [[], [], [], [], [], []];
  console.log("getArchiveData", showChapter, lastStage, showState);
  for (let chapter = 0; chapter <= showChapter; chapter++) {
    for (let stage = 0; stage < settingData[chapter].length; stage++) {
      if (chapter === showChapter && stage === lastStage) break;
      resultData[chapter].push(
        JSON.parse(JSON.stringify(settingData[chapter][stage]))
      );
    }
  }
  return resultData;
}

export { asyncGetSettingData };
export const serverActions = serverSlice.actions;
export default serverSlice.reducer;
