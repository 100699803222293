import styled from "styled-components";
import { Colors, ScreenSizes } from "../../../styles/Themes";
import { useDispatch, useSelector } from "react-redux";
import { componentActions } from "../../../store/componentSlice";
import BTAArchiveItem from "./BTAArchiveItem";

const ModalBackgroundEl = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BaseArchiveEl = styled.div`
  background-color: ${Colors.gr02};
  border: 1px solid ${Colors.wt};
  color: ${Colors.wt};
  font-family: NanumSquare;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 280px;
    height: auto;
  }
`;

const TitleEl = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.wt};
  padding: 24px 32px 24px 32px;
  box-sizing: border-box;
  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 12px 16px 12px 16px;
  }
`;

const Title = styled.span`
  font-size: 24px;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 18px;
  }
`;

const CloseIcon = styled.img`
  ${(props) => props.isMinting === 1 && "visibility: hidden;"}
  cursor: pointer;
`;

const ArchiveItemEl = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  position: relative;
  @media (max-width: ${ScreenSizes.mobile}) {
  }
`;

const CloseButton = styled.button`
  width: 112px;
  height: 34px;
  font-family: NanumSquare;
  font-weight: 700;
  font-size: 16px;
  color: ${Colors.gr02};
  cursor: pointer;
  border: none;
  border-radius: 8px;
  margin-bottom: 24px;
  margin-top: 24px;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 104px;
    height: 34px;
    font-size: 16px;
    margin-top: 16px;
  }
`;

function BTABaseArchiveModal() {
  const dispatch = useDispatch();
  const chapter = useSelector((state) => state.component.archiveModalChapter);
  const rawArchiveData = useSelector((state) => state.web3TokenInfo.archiveData);
  const bonusArchiveData = useSelector((state) => state.web3TokenInfo.bonusArchiveData);

  function openModalHandler(isOpen) {
    dispatch(
      componentActions.setIsBTAModalOpen(isOpen)
    );
    dispatch(componentActions.setIsBlur(isOpen));
  }

  return (
    <ModalBackgroundEl>
      <BaseArchiveEl>
        <TitleEl>
          <Title>Soul Index</Title>
          <CloseIcon
            src={"/icons/cross_modal.svg"}
            alt={"close button"}
            onClick={(e) => {
              openModalHandler(false, e);
            }}
          />
        </TitleEl>
        <ArchiveItemEl>
          <BTAArchiveItem />
        </ArchiveItemEl>
        <CloseButton
          onClick={(e) => {
            openModalHandler(false, e);
          }}
        >
          Close
        </CloseButton>
      </BaseArchiveEl>
    </ModalBackgroundEl>
  );
}
export default BTABaseArchiveModal;
