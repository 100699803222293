import styled from "styled-components";
import { Colors, ScreenSizes } from "../../../../styles/Themes";
import { useDispatch, useSelector } from "react-redux";
import { asyncWeb3SetApprovalForAll } from "../../../../store/web3BurnSlice";
import { useWeb3React } from "@web3-react/core";
import { useEffect } from "react";
import { componentActions } from "../../../../store/componentSlice";

const SetApprovalForAllEl = styled.div`
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const SetApprovalForAllWrapper = styled.div`
  width: 100%;
  padding: 0px 72px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
`;

const Title = styled.span`
  width: 100%;
  text-align: center;
  font-size: 32px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${props => props.theme.highlight};
`;

const Explain = styled.span`
  height: 180px;
  color: #fff;
  font-size: 24px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const LoadingEl = styled.div`
  width: 100%;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoaingImg = styled.img`
  width: 48px;
`;

const ConfirmButton = styled.button`
  color: ${Colors.gr01};
  width: 199px;
  height: 59px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f2f2f2;
  background: ${props => props.theme.highlight};
  border-radius: 8px;
  padding: 0px;
  font-family: NanumSquare;
  font-size: 24px;
  font-weight: 700;
  position: absolute;
  bottom: 40px;

  ${(props) => props.$isLoading && "opacity:0.3"};
  ${(props) => !props.$isLoading && "cursor: pointer"};
  &:hover {
    filter: brightness(80%);
  }
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 163px;
    height: 40px;
    border-radius: 6px;
    font-size: 18px;
  }
`;

function SetApprovalForAll() {
  const dispatch = useDispatch();
  const { account, library } = useWeb3React();
  const setApprovalForAllStatus = useSelector(
    (state) => state.web3Burn.setApprovalForAllStatus
  );

  useEffect(() => {
    dispatch(componentActions.setIsProcessing(false));
    if (setApprovalForAllStatus === 2) {
      dispatch(componentActions.setBurnModalStep(4));
    }else if(setApprovalForAllStatus === 3){
      console.log("Redirect to Error in SetApprovalForAll");
      dispatch(componentActions.setBurnModalStep(6));
    }
  }, [setApprovalForAllStatus]);

  function setApproveHandler() {
    if (setApprovalForAllStatus === 1) return;
    dispatch(
      asyncWeb3SetApprovalForAll({ account, provider: library.provider })
    );
  }

  return (
    <SetApprovalForAllEl>
      <SetApprovalForAllWrapper>
        <Title>SET APPROVAL FOR ALL</Title>
        {setApprovalForAllStatus === 1 ? (
          <LoadingEl>
            <LoaingImg src={"/images/progress_gif.gif"} />
          </LoadingEl>
        ) : (
          <Explain>
            In order for your burn-redeem request to be fulfilled, you must
            agree to set approval for all.
            <br />
            <br />
            This is so that your sacrificial souls can, in fact, be burned.
          </Explain>
        )}
      </SetApprovalForAllWrapper>
      <ConfirmButton
        $isLoading={setApprovalForAllStatus === 1}
        onClick={setApproveHandler}
      >
        I understand
      </ConfirmButton>
    </SetApprovalForAllEl>
  );
}
export default SetApprovalForAll;
