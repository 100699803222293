import { useEffect } from "react";
import styled from "styled-components";
import { ScreenSizes } from "../../../../styles/Themes";
import { useDispatch, useSelector } from "react-redux";
import { asyncWeb3BurnRedeem } from "../../../../store/web3BurnSlice";
import { useWeb3React } from "@web3-react/core";
import { componentActions } from '../../../../store/componentSlice';

const BurnLoadingEl = styled.div`
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
`;

const Explain = styled.span`
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  letter-spacing: -0.16px;
  @media (max-width: ${ScreenSizes.mobile}) {
font-size: 14px;
letter-spacing: -0.14px;
  }
`;

const LoaingImg = styled.img`
  width: 48px;
`;

function BurnLoading({ redeemQuantity, fee }) {
  const dispatch = useDispatch();
  const { account, library } = useWeb3React();
  const burnRedeemStatus = useSelector(
    (state) => state.web3Burn.burnRedeemStatus
  );
  useEffect(() => {
      dispatch(componentActions.setIsProcessing(true));
      dispatch(
        asyncWeb3BurnRedeem({
          redeemQuantity,
          account,
          provider: library.provider,
          fee,
        })
      );
  }, []);

  useEffect(()=>{
    if(burnRedeemStatus === 2){
      dispatch(componentActions.setBurnModalStep(5));
    }else if(burnRedeemStatus === 3){
      console.log("Redirect to Error in BurnLoading");
      dispatch(componentActions.setBurnModalStep(6));
    }
  },[burnRedeemStatus]);
  return (
    <BurnLoadingEl>
      <Explain>Raising new Soul(s) from the ashes...</Explain>
      <LoaingImg src={"/images/progress_gif.gif"} />
    </BurnLoadingEl>
  );
}
export default BurnLoading;
