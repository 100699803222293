import styled from "styled-components";
import { Colors } from "../styles/Themes";

const IconEl = styled.svg`
  fill: ${(props) => (props.highlight ? props.theme.highlight : Colors.wt)};
`;

function HomeIcon(props) {
  return (
    <IconEl
      width="20"
      height="18"
      viewBox="0 0 20 18"
      xmlns="http://www.w3.org/2000/svg"
      highlight={props.highlight}
    >
      <path d="M10 3.19L15 7.69V15.5H13V9.5H7V15.5H5V7.69L10 3.19ZM10 0.5L0 9.5H3V17.5H9V11.5H11V17.5H17V9.5H20L10 0.5Z" />
    </IconEl>
  );
}

function AboutIcon(props) {
  return (
    <IconEl
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      highlight={props.highlight}
    >
      <path d="M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z" />
    </IconEl>
  );
}

function MainLogo(props) {
  return (
    <IconEl
      width="100%"
      height="100%"
      viewBox="0 0 229 20"
      xmlns="http://www.w3.org/2000/svg"
      highlight={true}
    >
      <g>
        <path
          d="M160.919 19.2793C158.883 19.2613 157.225 19.0991 155.622 18.6486C154.252 18.2703 153.009 17.6757 151.964 16.6847C151.261 16.036 150.721 15.2793 150.324 14.4144C150 13.7117 149.874 12.973 149.802 12.2162C149.748 11.7658 149.694 11.2973 149.712 10.8468C149.712 9.72973 149.874 8.61261 150.324 7.56757C150.505 7.13514 150.667 6.7027 150.901 6.28829C152.072 4.18018 153.874 2.95496 156.144 2.34235C158.234 1.78379 160.396 1.63964 162.541 1.44145C163.766 1.33334 164.991 1.27928 166.216 1.53154C167.712 1.81982 168.937 2.52253 169.874 3.74775C170.342 4.36036 170.703 5.02703 170.955 5.76577C171.64 7.65766 171.568 9.58559 171.405 11.5315C171.333 12.4685 171.045 13.3514 170.685 14.1802C170.09 15.5676 169.171 16.7207 167.874 17.5495C165.658 18.991 163.153 19.2432 160.955 19.2793H160.919ZM160.775 16.5766C161.82 16.5766 162.739 16.4685 163.658 16.1982C164.955 15.8018 166.018 15.0811 166.847 14.018C167.405 13.3153 167.73 12.4685 167.91 11.6216C168.288 9.90991 168.234 8.1982 167.424 6.57658C167.153 6.03604 166.739 5.58559 166.252 5.24325C165.405 4.64865 164.433 4.41442 163.424 4.3964C161.081 4.32433 158.811 4.66667 156.685 5.72973C155.369 6.37838 154.396 7.36937 153.838 8.7027C153.514 9.47748 153.333 10.3243 153.351 11.1892C153.351 11.4414 153.46 11.6757 153.496 11.9099C153.604 12.5045 153.748 13.0631 154.054 13.5856C154.649 14.6306 155.532 15.3333 156.613 15.7838C157.982 16.3784 159.424 16.5766 160.793 16.5766H160.775Z"
        />
        <path
          d="M173.532 12.5585C173.496 11.2432 173.802 10.1081 173.946 8.95493C174.09 7.89187 174.414 6.88286 174.703 5.87385C174.973 4.88286 175.351 3.90988 175.748 2.95493C175.892 2.59457 176.018 2.23421 176.162 1.87385C176.432 1.18916 176.901 0.918893 177.604 1.00898C178.613 1.15313 179.604 1.38736 180.613 1.4234C180.829 1.4234 180.829 1.53151 180.739 1.69367C180.144 2.70268 179.64 3.74772 179.117 4.79277C178.451 6.1261 177.91 7.47745 177.478 8.90088C177.045 10.3603 176.811 11.8198 177.045 13.3333C177.225 14.5585 177.982 15.3333 179.045 15.9099C180.901 16.9009 182.703 16.6486 184.451 15.6756C185.225 15.2432 185.838 14.5946 186.27 13.8018C186.775 12.8648 187.081 11.8558 187.369 10.8468C187.73 9.56754 188.126 8.28826 188.559 7.027C188.901 6.03601 189.225 5.04502 189.568 4.07205C189.874 3.18916 190.108 2.27024 190.469 1.38736C190.667 0.900875 191.009 0.612587 191.55 0.594569C192.342 0.558533 193.153 0.576551 193.964 0.558533C194.198 0.558533 194.288 0.630605 194.198 0.864839C193.658 2.21619 193.333 3.63961 192.865 5.027C192.865 5.04502 192.865 5.08105 192.847 5.09907C192.613 6.3243 192.198 7.51349 191.928 8.72069C191.604 10.1441 191.243 11.5495 190.793 12.9369C190.577 13.6216 190.378 14.3063 190.09 14.9729C189.171 17.1531 187.586 18.5946 185.369 19.3513C182.667 20.2883 179.982 20.054 177.333 19.1351C176.342 18.7928 175.514 18.1802 174.883 17.3153C174.288 16.5045 173.946 15.6036 173.766 14.6306C173.622 13.8919 173.46 13.1711 173.514 12.5585H173.532Z"
        />
        <path
          d="M218.577 0.936951C220.468 1.00902 222.306 1.3874 224.108 1.90992C225.297 2.25227 226.468 2.64866 227.513 3.35136C227.748 3.49551 227.964 3.65767 228.162 3.83785C228.432 4.0901 228.432 4.50452 228.126 4.6847C227.82 4.86488 227.622 5.13515 227.405 5.36938C226.396 6.46848 225.532 6.19821 224.703 5.56758C222.649 4.03605 220.378 3.51353 217.874 3.92794C217.225 4.03605 216.685 4.34235 216.27 4.86488C215.91 5.33335 216 6.27028 216.36 6.72073C216.937 7.42343 217.748 7.76578 218.523 8.14416C219.676 8.72073 220.829 9.27929 221.982 9.81983C222.811 10.2162 223.622 10.6126 224.36 11.1532C225.045 11.6396 225.586 12.2342 225.874 13.0811C226.288 14.2883 226 15.4234 225.514 16.4865C224.955 17.7117 223.928 18.5225 222.739 19.1351C220.847 20.1081 218.865 20.0541 216.829 19.6937C214.793 19.3333 212.829 18.7568 210.973 17.8559C210.198 17.4775 209.477 17.009 208.811 16.4505C208.486 16.1802 208.468 15.6577 208.793 15.4234C209.225 15.1171 209.604 14.7387 210.036 14.4324C210.613 14.036 211.189 14.018 211.784 14.4324C212.306 14.7748 212.775 15.1892 213.315 15.5135C214.775 16.3604 216.36 16.8649 218.036 16.955C218.901 17.009 219.766 17.009 220.595 16.7387C222.036 16.2703 222.45 14.9009 221.838 13.9099C221.568 13.4595 221.099 13.1712 220.631 12.9189C218.829 11.964 217.009 11.0811 215.153 10.2342C214.126 9.74776 213.171 9.11713 212.559 8.12614C212.306 7.72974 212.216 7.26127 212.162 6.81082C212.072 6.01803 212.162 5.24326 212.468 4.52253C213.225 2.86488 214.45 1.71173 216.234 1.24326C216.991 1.04506 217.766 0.972987 218.541 0.972987L218.577 0.936951Z"
        />
        <path
          d="M147.748 14.1441C147.838 14.991 147.55 15.8919 147.117 16.7387C146.613 17.7117 145.838 18.4684 144.901 19.027C143.315 19.9819 141.604 20.1261 139.802 19.9099C138.27 19.7297 136.775 19.4774 135.333 18.991C134.036 18.5585 132.829 17.9639 131.73 17.1351C131.495 16.9549 131.279 16.7567 131.045 16.5585C130.721 16.2702 130.775 15.8558 131.117 15.5856C131.441 15.3513 131.748 15.0991 132.072 14.8648C133.027 14.1982 133.622 14.3783 134.432 14.9729C135.315 15.6036 136.18 16.2522 137.207 16.6486C139.027 17.3513 140.883 17.5315 142.739 16.8648C143.838 16.4684 144.414 15.4594 144.162 14.3063C144 13.5675 143.369 13.2612 142.757 13.009C141.117 12.3603 139.459 11.7838 137.892 10.9729C136.829 10.4144 135.856 9.7297 135.189 8.68466C135.009 8.41438 134.919 8.09006 134.865 7.76574C134.703 6.84682 134.757 5.9279 135.153 5.08105C135.496 4.34231 136.09 3.76574 136.793 3.35132C139.333 1.87385 142.09 1.29727 144.991 1.31529C146.486 1.31529 147.892 1.69367 149.135 2.55853C149.369 2.72069 149.55 2.95493 149.712 3.18916C149.802 3.31529 149.748 3.38736 149.658 3.47745C149.171 3.99997 148.757 4.57655 148.216 5.027C147.766 5.40538 147.225 5.36934 146.685 5.13511C146.234 4.93691 145.856 4.6306 145.405 4.45042C144.342 4.03601 143.243 4.05403 142.162 4.3243C141.243 4.54051 140.288 4.75673 139.586 5.44141C138.757 6.23421 138.577 7.24321 139.622 8.03601C140.541 8.73871 141.64 8.99096 142.703 9.35132C143.802 9.74772 144.937 9.99997 145.946 10.6126C146.577 10.991 147.081 11.4774 147.405 12.1621C147.694 12.7567 147.784 13.3693 147.766 14.1441H147.748Z"
        />
        <path
          d="M196.252 19.8559C195.55 19.8559 194.847 19.8559 194.144 19.8559C193.495 19.8559 193.117 19.5676 193.477 18.7928C193.495 18.7568 193.514 18.7027 193.514 18.6487C193.766 17.4414 194.126 16.2523 194.396 15.0631C194.577 14.2523 194.703 13.4234 194.937 12.6126C195.153 11.8559 195.261 11.0811 195.441 10.3243C195.784 8.93694 196.126 7.54955 196.504 6.16216C196.829 4.99099 197.189 3.81982 197.55 2.66667C197.622 2.41442 197.766 2.16216 197.838 1.90991C197.982 1.42343 198.324 1.18919 198.829 1.27928C199.928 1.47748 201.009 1.71171 202.126 1.81982C202.288 1.81982 202.342 1.90991 202.306 2.07207C202.018 3.04505 201.712 4.01802 201.369 4.97297C201.369 5.02703 201.333 5.06306 201.315 5.11712C201.045 6.05406 200.793 6.99099 200.504 7.90991C200.108 9.18919 199.73 10.4865 199.369 11.7658C199.243 12.2342 199.117 12.7027 198.991 13.1712C198.757 13.964 198.541 14.7748 198.288 15.5676C198.198 15.8198 198.036 16.0541 197.982 16.3243C197.892 16.7928 198.018 16.991 198.486 17.045C199.063 17.1171 199.64 17.045 200.216 17.009C202.486 16.8649 204.739 16.5766 206.991 16.3063C207.153 16.2883 207.315 16.2883 207.477 16.3063C207.73 16.3423 207.874 16.5946 207.82 16.8468C207.766 17.045 207.73 17.2432 207.694 17.4414C207.477 18.5225 206.865 18.991 205.874 19.0811C202.685 19.3874 199.495 19.7477 196.288 19.9099L196.252 19.8559Z"
        />
        <path
          d="M93.0089 10.7748C92.9188 10.8469 92.9188 10.955 92.9008 11.0271C92.3602 13.4234 91.5855 15.7658 90.9368 18.1442C90.9008 18.2883 90.8287 18.4505 90.7746 18.5766C90.5584 19.009 90.2161 19.2253 89.7296 19.1892C89.2611 19.1532 88.8287 18.991 88.3963 18.8108C87.3512 18.3964 86.3062 18.0541 85.171 17.982C84.8467 17.964 84.5404 17.982 84.2341 17.982C83.7296 17.982 83.5855 17.8198 83.6755 17.3334C84.3602 14.2523 85.2251 11.2433 86.6305 8.41444C87.5674 6.54056 88.5404 4.66669 89.117 2.64867C89.3332 1.92795 89.6395 1.60362 90.4323 1.58561C91.5494 1.56759 92.6305 1.20723 93.7116 0.882903C93.8017 0.864885 93.8737 0.828849 93.9638 0.810831C94.3602 0.720741 94.5224 0.792813 94.7026 1.15317C95.0449 1.85588 95.3692 2.55858 95.6936 3.26128C96.5404 5.04507 97.3873 6.84687 98.2161 8.63065C98.2521 8.72074 98.3062 8.81083 98.3602 8.90092C98.4323 8.84687 98.4503 8.7748 98.4683 8.70273C99.081 6.41444 99.9458 4.19822 100.793 1.982C101.045 1.33335 101.531 1.11714 102.342 1.29732C103.423 1.54957 104.504 1.69371 105.621 1.63966C106.018 1.62164 106.414 1.56759 106.793 1.53155C107.189 1.49552 107.297 1.60362 107.279 1.982C107.279 2.16218 107.225 2.32435 107.189 2.50453C106.252 6.86489 104.937 11.0991 103.423 15.2973C103.099 16.1802 102.811 17.0811 102.486 17.982C102.252 18.6307 102 18.8108 101.315 18.6667C100.288 18.4505 99.2611 18.5045 98.2341 18.6126C96.7927 18.7748 96.3242 18.5406 95.8377 17.1712C95.081 15.0631 94.0539 13.0631 93.135 11.0271C93.099 10.937 93.081 10.8469 92.9728 10.7748H93.0089Z"
        />
        <path
          d="M63.3873 8.66667C63.1711 9.40541 63.081 10.0721 63.0089 10.7568C62.8648 12.2342 62.9549 13.7297 62.9729 15.2072C62.9729 16.1622 62.9729 17.1171 62.7927 18.0541C62.5765 19.2432 62.108 19.5315 60.9368 19.1712C60.144 18.9369 59.4053 18.5766 58.6305 18.2522C57.7296 17.8739 56.8107 17.5856 55.8197 17.4775C55.1891 17.4054 55.063 17.2432 55.2251 16.6126C55.4594 15.6577 55.7116 14.7207 55.9639 13.7838C56.7747 10.7928 57.7296 7.83784 59.1531 5.06306C59.6756 4.03604 60.1981 3.00901 60.7206 1.98198C60.9729 1.4955 61.3513 1.24325 61.9098 1.26126C62.8828 1.26126 63.8558 1.15316 64.8287 1.18919C65.2071 1.18919 65.5855 1.24325 65.9639 1.31532C66.7206 1.47748 67.2612 1.90991 67.5675 2.61261C67.6936 2.9009 67.8017 3.20721 67.8738 3.4955C68.054 4.1982 68.3062 4.88288 68.6125 5.54955C69.063 6.5045 69.4954 7.47748 69.9458 8.45045C69.9819 8.54054 69.9999 8.63063 70.126 8.72072C70.4864 8.23423 70.8287 7.72973 71.1891 7.24324C72.126 5.90991 72.7567 4.43243 73.0269 2.84685C73.2071 1.85586 73.6936 1.31532 74.6846 1.08108C75.6576 0.864868 76.5945 0.486489 77.5675 0.234237C78.1981 0.0720749 78.2882 0.126129 78.1981 0.792795C77.9639 2.63063 77.8377 4.46847 77.6395 6.30631C77.3332 9.02703 76.8468 11.7117 75.8197 14.2883C75.3332 15.4955 74.7747 16.6847 74.1981 17.8739C73.9999 18.2883 73.7296 18.4144 73.2792 18.3423C73.081 18.3063 72.8828 18.2522 72.7206 18.1802C71.4774 17.6216 70.1621 17.5135 68.8107 17.6577C67.9458 17.7477 67.6035 17.5495 67.1891 16.7748C67.135 16.6667 67.063 16.5405 67.0269 16.4324C66.3783 14.4324 65.3152 12.6126 64.3963 10.7387C64.108 10.1622 63.8197 9.56757 63.5314 8.99099C63.4954 8.9009 63.4413 8.82883 63.3513 8.66667H63.3873Z"
        />
        <path
          d="M15.4593 10.1261C15.6575 10.2523 15.8377 10.3604 16.0359 10.4865C16.5764 10.8469 17.0809 11.2613 17.5314 11.7297C19.189 13.4595 18.6665 16.9189 16.108 17.9279C15.2431 18.2703 14.3602 18.4685 13.4413 18.5946C10.9007 18.991 8.3602 19.3153 5.80165 19.5676C4.08993 19.7297 2.37822 19.5676 0.666509 19.4595C0.0719145 19.4234 -0.0902477 19.1892 0.0719145 18.6126C0.432275 17.2793 0.828672 15.946 1.20705 14.6126C2.37822 10.4865 3.54939 6.36037 4.64849 2.21623C4.7566 1.78379 4.91876 1.63965 5.31516 1.65767C7.06291 1.72974 8.77462 1.44145 10.4863 1.15316C12.2161 0.864876 13.9458 0.82884 15.6395 1.35136C16.7206 1.6937 17.6935 2.21623 18.5584 2.95497C20.3422 4.50451 20.3062 8.2883 17.4053 9.51352C16.7926 9.78379 16.144 9.94595 15.4593 10.1261ZM10.3422 11.7838C10.3422 11.7838 10.162 11.7838 10.0719 11.7838C9.31516 11.8378 8.59444 12.0541 7.8557 12.1802C7.74759 12.1802 7.67552 12.2703 7.63948 12.3604C7.47732 12.9009 7.27912 13.4234 7.13498 13.964C6.99084 14.5586 7.18903 14.7928 7.80165 14.8108C7.89174 14.8108 7.98183 14.8108 8.07192 14.8108C8.63048 14.7387 9.18903 14.6847 9.74759 14.5946C10.2701 14.5225 10.7566 14.3423 11.189 14.018C11.5674 13.7297 11.7836 13.3694 11.8377 12.9009C11.8737 12.4324 11.6575 12.1081 11.2251 11.946C10.9368 11.8378 10.6485 11.8018 10.3602 11.8018L10.3422 11.7838ZM10.144 8.10812C10.8287 8.00001 11.6034 7.98199 12.3422 7.74776C12.5584 7.67568 12.7566 7.54956 12.9188 7.40541C13.2791 7.08109 13.4233 6.68469 13.2791 6.21623C13.171 5.83785 12.8287 5.60361 12.3062 5.56758C11.6215 5.53154 10.9548 5.56758 10.2701 5.67568C9.72957 5.74776 9.27912 6.01803 9.00885 6.50451C8.77462 6.91893 8.72056 7.36938 8.77462 7.83785C8.82867 8.32433 8.97282 8.41442 9.44129 8.23424C9.63948 8.16217 9.83768 8.07208 10.144 8.10812Z"
        />
        <path
          d="M45.4776 1.26436e-05C46.8469 1.26436e-05 48.1803 0.234247 49.4956 0.630643C51.1712 1.13515 52.8289 1.71172 54.4866 2.2883C55.0451 2.4865 55.5857 2.72073 56.0721 3.04506C56.973 3.63965 57.3334 4.46848 57.2433 5.51353C57.1172 7.31533 56.3244 8.82884 55.1893 10.1802C54.6848 10.7748 54.1262 11.3153 53.5136 11.8018C53.3694 11.9279 53.2253 12.0721 53.0812 12.1982C52.5766 12.7027 52.4325 13.2793 52.7208 13.946C53.2253 15.0811 53.5496 16.2883 53.9821 17.4415C54.0361 17.5856 54.0541 17.6757 53.8739 17.7297C52.955 18 52.1082 18.4685 51.2433 18.8649C50.7388 19.0991 50.2343 19.2973 49.6938 19.4415C49.1532 19.5856 48.8649 19.4415 48.6667 18.9369C48.1983 17.6757 47.6397 16.4505 47.1712 15.1892C46.8289 14.2883 46.1262 13.9279 45.2794 13.7478C44.901 13.6577 44.6667 13.8018 44.5406 14.1802C44.2343 15.1712 43.7839 16.1081 43.5316 17.1171C43.3514 17.8919 42.901 18.1802 42.0902 18.0901C41.5676 18.036 41.0631 17.9099 40.5406 17.9279C39.7478 17.9279 39.0271 18.1802 38.3244 18.4685C38.1082 18.5586 37.8739 18.7027 37.6577 18.5406C37.4595 18.3784 37.5316 18.1081 37.5857 17.8919C37.91 16.2523 38.2703 14.6487 38.6487 13.027C39.4956 9.40542 40.4145 5.81983 41.4956 2.25226C41.5496 2.05407 41.6397 1.83785 41.7118 1.63965C42.0181 0.756769 42.6667 0.306319 43.5676 0.144157C44.1983 0.0360487 44.8469 1.26436e-05 45.4776 -0.0180054V1.26436e-05ZM49.1532 10.1261C50.3424 10.1442 51.3875 9.47749 51.7118 8.52253C51.928 7.8919 51.7478 7.3874 51.1893 7.04506C51.0091 6.93695 50.8289 6.84686 50.6307 6.75677C49.5676 6.30632 48.4505 6.10812 47.3154 5.8919C46.8109 5.80181 46.5766 5.96398 46.5406 6.46848C46.4866 7.11713 46.3604 7.74776 46.1983 8.37839C46.0541 8.93695 46.1983 9.22524 46.7388 9.47749C47.5316 9.85587 48.3785 10.018 49.1532 10.1081V10.1261Z"
        />
        <path
          d="M116.577 19.5315C114.991 19.4595 113.135 19.3514 111.297 18.9369C109.117 18.4505 107.748 17.0451 106.973 15.009C106.27 13.1351 106.324 11.2252 106.847 9.31533C107.496 6.91893 108.595 4.7928 110.342 3.00902C111.478 1.85587 112.865 1.17118 114.469 1.06307C117.225 0.882893 119.946 1.15316 122.595 1.94596C123.568 2.23424 124.451 2.70271 125.243 3.35136C125.748 3.76578 125.784 3.96397 125.37 4.45046C124.775 5.15316 124.198 5.87388 123.604 6.59461C122.757 7.5856 121.928 7.76578 120.721 7.26127C120.451 7.15316 120.198 7.00902 119.928 6.86488C118.955 6.36037 117.928 6.0901 116.829 6.07208C114.433 6.01803 112.955 7.42343 112.342 9.44145C112.09 10.2523 111.964 11.0811 112.198 11.9099C112.577 13.3333 113.568 14.0901 114.919 14.4505C115.55 14.6126 116.198 14.6306 116.847 14.5406C117.171 14.5045 117.388 14.3243 117.514 14.036C117.694 13.6396 117.784 13.2072 117.91 12.7928C117.964 12.5946 117.838 12.5766 117.712 12.5586C116.865 12.4505 116.018 12.3243 115.153 12.2342C114.865 12.1982 114.847 12.1261 114.919 11.8739C115.171 10.991 115.406 10.1081 115.64 9.22523C115.784 8.70271 116 8.57659 116.523 8.64866C117.604 8.7928 118.685 8.95497 119.784 9.04506C120.991 9.13515 122.198 9.09911 123.424 9.00902C123.712 8.991 124.018 8.97298 124.306 8.97298C124.613 8.97298 124.721 9.09911 124.685 9.40542C124.649 9.63965 124.577 9.87388 124.523 10.0901C123.874 12.5586 123.207 15.0451 122.577 17.5135C122.433 18.0541 122.144 18.4324 121.622 18.6487C120.469 19.1532 119.063 19.5856 116.577 19.5315Z"
        />
        <path
          d="M20.2881 13.4414C20.3421 12.2522 20.6304 10.8108 20.9728 9.36935C21.5313 6.88287 22.3061 4.45043 22.8647 1.96395C22.9367 1.65764 23.1349 1.45944 23.4593 1.38737C24.1079 1.24323 24.7385 1.04503 25.3872 0.972956C25.9998 0.918902 26.5403 0.66665 27.1349 0.558541C27.3331 0.522505 27.5133 0.486469 27.7295 0.504487C28.198 0.540523 28.3962 0.792776 28.3602 1.24323C28.3421 1.44142 28.3061 1.63962 28.234 1.8198C27.5313 3.5135 27.207 5.3153 26.7385 7.06305C26.4142 8.30629 26.1079 9.54953 25.8917 10.8108C25.6034 12.3964 26.18 13.2432 27.7656 13.6036C28.4322 13.7477 29.0809 13.8018 29.7656 13.6937C30.5584 13.5676 31.171 13.1712 31.5854 12.4504C31.8917 11.9279 32.0899 11.3874 32.2521 10.8108C33.0809 8.018 33.9277 5.20719 34.5944 2.36034C34.5944 2.28827 34.6304 2.23422 34.6484 2.16215C34.7926 1.71169 35.0088 1.54953 35.4773 1.58557C36.3421 1.67566 37.189 1.65764 38.0358 1.53151C38.6665 1.42341 39.2611 1.27926 39.8737 1.1171C40.3962 0.972956 40.5043 1.1171 40.3602 1.6216C40.0539 2.73872 39.7115 3.85584 39.4052 4.97296C38.7025 7.6216 38.0178 10.2883 37.3151 12.9369C36.9908 14.1982 36.5403 15.4234 35.8917 16.5585C35.7115 16.8829 35.5133 17.1892 35.2971 17.4775C34.234 18.9549 32.7746 19.5676 30.9908 19.4234C30.18 19.3513 29.3692 19.2252 28.5584 19.0991C27.3331 18.9009 26.1079 18.8288 24.8827 18.8108C24.4502 18.8108 24.0358 18.7387 23.6214 18.6667C22.252 18.3964 21.3692 17.5315 20.8106 16.3243C20.4322 15.5135 20.2881 14.6486 20.3061 13.4594L20.2881 13.4414Z"
        />
        <path
          d="M77.6936 19.6216C77.3873 19.6216 77.081 19.5856 76.7747 19.5495C76.5585 19.5135 76.5044 19.4594 76.5765 19.2252C77.1711 17.4594 77.6756 15.6757 78.072 13.8558C78.5405 11.6757 78.8468 9.45945 79.081 7.22522C79.3332 5.06306 79.5314 2.88288 80.0179 0.738733C80.1801 0.0360309 80.3603 -0.0720772 81.081 0.0180129C82.6125 0.216211 84.09 0.612607 85.5855 0.990986C85.9459 1.08108 86.3062 1.15315 86.6486 1.26126C87.099 1.4054 87.2071 1.65765 86.9909 2.07207C86.5044 3.02702 86.0179 3.98198 85.5314 4.93693C84.2702 7.35134 83.5314 9.94594 82.9909 12.5946C82.6486 14.3063 82.3062 16.018 81.9999 17.7297C81.8918 18.2703 81.6395 18.6126 81.117 18.7928C80.3062 19.0631 79.5134 19.4054 78.6666 19.5495C78.3422 19.6036 78.0179 19.6396 77.6936 19.6216Z"
        />
      </g>
      
    </IconEl>
  );
}

export { HomeIcon, AboutIcon, MainLogo };
