import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RootPage from "./pages/RootPage";
import MainPage from "./pages/MainPage";
import AboutPage from "./pages/AboutPage";
import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetSettingData } from "./store/serverSlice";
import { asyncGetAllTokenInfo } from "./store/web3TokenInfoSlice";
import BonusPage from "./pages/BonusPage";
import { ThemeProvider } from "styled-components";
import { ChapterColors } from "./styles/Themes";
import BTAPage from "./pages/BTAPage";

function App() {
  const { account, library } = useWeb3React();
  const dispatch = useDispatch();
  const showData = useSelector((state) => state.server.BTAShowData);
  const isBonusOpen = useSelector((state) => state.server.isBonusOpen);
  const isBonusMain = useSelector((state) => state.server.isBonusMain);
  console.log("isBonusMain : ",isBonusMain);

  const [chapterColor, setChapterColor] = useState(5);

  if (process.env.REACT_APP_ENV_VER !== "DEV") {
    console.log = function no_console() {};
    console.warn = function no_console() {};
  }
  useEffect(() => {
    dispatch(asyncGetSettingData());
  }, []);

  useEffect(() => {
    if (showData !== undefined) {
      setChapterColor(showData.chapter - 1);
    }
  }, [showData]);

  useEffect(() => {
    if (process.env.REACT_APP_ENV_VER === "DEV") {
      console.log("ENV_VERSION : ", process.env.REACT_APP_ENV_VER);
    }
    if (account !== undefined) {
      dispatch(asyncGetAllTokenInfo({ account, provider: library.provider }));
    }
  }, [dispatch, account]);

  return (
    <ThemeProvider theme={ChapterColors[chapterColor]}>
      {isBonusOpen && isBonusMain ? (
        <RouterProvider
          router={createBrowserRouter([
            {
              path: "/",
              element: <RootPage />,
              children: [
                {
                  path: "/bta",
                  element: <BTAPage setChapterColor={setChapterColor} />,
                },
                {
                  path: "/about",
                  element: <AboutPage />,
                },
                {
                  path: "/",
                  element: <BonusPage setChapterColor={setChapterColor} />,
                },
                {
                  path: "/*",
                  element: <MainPage />,
                },
              ],
            },
          ])}
        ></RouterProvider>
      ) : (
        <RouterProvider
          router={createBrowserRouter([
            {
              path: "/",
              element: <RootPage />,
              children: [
                {
                  path: "/",
                  element: <BTAPage setChapterColor={setChapterColor} />,
                },
                {
                  path: "/about",
                  element: <AboutPage />,
                },
                {
                  path: "/bonus",
                  element: <BonusPage setChapterColor={setChapterColor} />,
                },
                {
                  path: "/*",
                  element: <MainPage />,
                },
              ],
            },
          ])}
        ></RouterProvider>
      )}
    </ThemeProvider>
  );
}

export default App;
