import styled from "styled-components";
import { Colors, ScreenSizes } from "../../../../styles/Themes";

const BurnItemEl = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 512px;
  padding-right: 5px;
  box-sizing: border-box;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 230px;
  }
`;

const BurnItemVideo = styled.video`
  width: 67px;
  height: auto;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 48px;
  }
`;

const InfoEl = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 352px;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 141px;
  }
`;

const BurnItemName = styled.span`
  color: #fff;
  font-size: 20px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 12px;
  }
`;

const BurnItemNeed = styled.span`
  color: #fff;
  font-size: 20px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 12px;
  }
`;

function ConfirmBurnItem({ burnSet, redeemQuantity }) {
  return (
    <BurnItemEl>
      <BurnItemVideo autoPlay muted loop playsInline>
        <source src={burnSet.items[0].uri.animation} type="video/mp4" />
      </BurnItemVideo>
      <InfoEl>
        <BurnItemName>{burnSet.items[0].uri.name}</BurnItemName>
        {/* <BurnItemTokenInfo>0x4798...2f27</BurnItemTokenInfo> */}
      </InfoEl>
      <BurnItemNeed>x {burnSet.requiredCount * redeemQuantity}</BurnItemNeed>
    </BurnItemEl>
  );
}
export default ConfirmBurnItem;
