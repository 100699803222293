import styled from "styled-components";
import { Colors, ScreenSizes } from "../../../../styles/Themes";
import QuantityBurnItem from "./QuantityBurnItem";
import { useDispatch } from "react-redux";
import { componentActions } from "../../../../store/componentSlice";
import { useEffect } from 'react';
import BTAEndStageRedeemQuantity from "./BTAEndStageRedeemQuantity";

const BaseEl = styled.div`
  padding: 16px 0px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  position: relative;
  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 16px 0px;
  }
`;

const BurnItemListEl = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  max-width: 512px;
  overflow-x: scroll;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${Colors.gr07}; /* 스크롤바 색상 */
    border-radius: 5px; /* 스크롤바 둥근 테두리 */
  }
  &::-webkit-scrollbar-track {
    background: ${Colors.gr05}; /*스크롤바 뒷 배경 색상*/
  }
  @media (max-width: ${ScreenSizes.mobile}) {
    gap: 4px;
  }
`;

const BurnItemsEl = styled.div`
  width: 560px;
  height: 327px;
  background-color: ${(props) =>
    props.$isOver ? "rgba(249, 77, 77, 0.16)" : Colors.gr700};
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  @media (max-width: ${ScreenSizes.mobile}) {
    gap: 12px;
    width: 262px;
    height: auto;
    padding: 12px 24px 0px 24px;
  }
`;

const BurnExplainEl = styled.div`
  display: flex;
  flex-direction: column;
`;

const BurnExplainText = styled.span`
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 800;
  line-height: 160%;
  letter-spacing: -0.24px;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 16px;
    font-weight: 800;
    line-height: normal;
  }
`;

const BurnSubExplainText = styled.span`
  color: #a9a9a9;
  text-align: center;
  font-size: 12px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 11px;
    font-weight: 400;
    line-height: 150%;
  }
`;

const ConfirmButton = styled.button`
  color: ${Colors.gr01};
  width: 199px;
  height: 59px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f2f2f2;
  background: ${props => props.theme.highlight};
  border-radius: 8px;
  padding: 0px;
  font-family: NanumSquare;
  font-size: 24px;
  font-weight: 700;
  ${(props) => props.$isOver && "opacity:0.3"};
  cursor: pointer;
  &:hover {
    filter: brightness(80%);
  }
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 163px;
    height: 40px;
    border-radius: 6px;
    font-size: 18px;
  }
`;

const HighLight = styled.span`
  color: ${(props) => props.theme.highlight};
`;

function BTABurnQuantitySelect({
  showData,
  redeemQuantity,
  setRedeemQuantity,
  maxRedeemCount,
  burnRedeemInfo,
}) {
  const dispatch = useDispatch();

  function nextStepHandler() {
    if (maxRedeemCount < redeemQuantity) return;
    dispatch(componentActions.setBurnModalStep(1));
  }

  useEffect(()=>{
    dispatch(componentActions.setIsProcessing(false));
  },[]);

  console.log("BTABurnQuantitySelect",showData.publicData.burnSet.length);
  return (
    <BaseEl>
      <BTAEndStageRedeemQuantity
        showData={showData}
        redeemQuantity={redeemQuantity}
        setRedeemQuantity={setRedeemQuantity}
        burnRedeemInfo={burnRedeemInfo}
      />
      <BurnItemsEl $isOver={maxRedeemCount < redeemQuantity}>
        <BurnExplainEl>
          <BurnExplainText>
            {maxRedeemCount < redeemQuantity ? (
              "Not Enough Sacrificial Souls."
            ) : (
              <>
                <HighLight>
                  {showData.publicData.burnSet.length}
                </HighLight>
                <span> different artworks will burn.</span>
              </>
            )}
          </BurnExplainText>
          <BurnSubExplainText>
            *Soul(s) with the lowest token id number will be burned first.
          </BurnSubExplainText>
        </BurnExplainEl>
        <BurnItemListEl>
          {showData.publicData.burnSet.map((burnSet, index) => {
            return (
              <QuantityBurnItem
                burnSet={burnSet}
                redeemQuantity={redeemQuantity}
                key={index}
              />
            );
          })}
        </BurnItemListEl>
      </BurnItemsEl>
      <ConfirmButton
        onClick={nextStepHandler}
        $isOver={maxRedeemCount < redeemQuantity}
      >
        Confirm Burn
      </ConfirmButton>
    </BaseEl>
  );
}
export default BTABurnQuantitySelect;
