import styled from "styled-components";
import { ScreenSizes } from "../../../styles/Themes";
import EndStageItem from "./EndStageItem";

const EndStageBurnAreaEl = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const ArrowImg = styled.img`
  width: 44px;
  height: auto;
  @media (max-width: ${ScreenSizes.mobile}) {
  }
`;

const TitleEl = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 800;
  line-height: 160%;
  letter-spacing: -0.24px;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 16px;
    font-weight: 800;
    line-height: normal;
  }
`;

const SubTitle = styled.span`
  color: #a9a9a9;
  text-align: center;
  font-size: 12px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  @media (max-width: ${ScreenSizes.mobile}) {
    color: #fff;
    text-align: center;
    font-size: 11px;
    line-height: 150%;
  }
`;

const BurnItemListEl = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 716px;
  gap: 8px;
  padding: 4px 32px;
  @media (max-width: ${ScreenSizes.mobile}) {
    gap: 4px;
    width: 330px;
    padding: 0px;
    justify-content: center;
  }
`;

function EndStageBurnArea({ showData }) {
  return (
    <EndStageBurnAreaEl>
      <ArrowImg src={"/images/burn_arrow.svg"} />
      <TitleEl>
        <Title>Souls to be Sacrificed: </Title>
        <SubTitle>
          You will need to sacrifice one of each of the following Souls.
        </SubTitle>
      </TitleEl>
      <BurnItemListEl>
        {showData.publicData.burnSet.map((burnItem, index) => {
          return <EndStageItem burnItem={burnItem} key={index} />;
        })}
      </BurnItemListEl>
    </EndStageBurnAreaEl>
  );
}
export default EndStageBurnArea;
