import styled from "styled-components";
import { ScreenSizes } from "../../../styles/Themes";
import { useDispatch } from 'react-redux';
import { componentActions } from '../../../store/componentSlice';
import { useEffect } from 'react';

const EndStageVideoEl = styled.video`
  width: 840px;
  height: auto;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 100%;
  }
`;

function EndStageVideo({ showData }) {
  const dispatch = useDispatch();

  function endStageRedeemModalHandler(isOpen) {
    dispatch(componentActions.setIsRedeemModalOpen(isOpen));
    dispatch(componentActions.setIsBlur(true));
  }
  return (
    <EndStageVideoEl autoPlay muted loop playsInline onClick={(e)=>endStageRedeemModalHandler(true)}>
      <source src={showData.publicData.redeemAssetMedia} type="video/mp4" />
    </EndStageVideoEl>
  );
}
export default EndStageVideo;
