import styled from "styled-components";
import { useSelector } from "react-redux";
import { Colors, ScreenSizes } from "../../../styles/Themes";
import { useEffect } from "react";
import { useState } from "react";
import { useWeb3React } from "@web3-react/core";

const ArchiveItemEl = styled.div`
  width: 100%;
  height: auto;
  padding: 24px 64px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 12px;
  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 16px 24px 0px 24px;
    gap: 16px;
    align-items: center;
  }
`;

const ItemVideo = styled.video`
  width: ${(props) => (props.$isEndStage ? "720px" : "400px")};
  height: auto;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 144px;
  }
`;

const ItemImage = styled.img`
  width: ${(props) => (props.$isEndStage ? "720px" : "400px")};
  height: auto;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 144px;
  }
`;

const ItemInfoEl = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (max-width: ${ScreenSizes.mobile}) {
    gap: 6px;
  }
`;

const ItemName = styled.span`
  color: #fff;
  font-size: 24px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 12px;
  }
`;

const NFTInfoEl = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${ScreenSizes.mobile}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 9px;
  }
`;

const NFTCountInfoEl = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: ${ScreenSizes.mobile}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const NFTOwnCount = styled.span`
  color: #fff;
  font-size: 16px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 10px;
    line-height: 140%;
  }
`;

const NFTOwnCountHighlight = styled.span`
  color: ${(props) => props.theme.highlight};
  font-size: 16px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 10px;
    line-height: 140%;
  }
`;

const NFTCount = styled.span`
  color: #bbb;
  font-size: 16px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 10px;
    font-weight: 400;
    line-height: 140%;
  }
`;

const NFTCountBar = styled.span`
  display: inline-block;
  color: #bbb;
  padding: 0px 5px;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: none;
  }
`;

const LinkEl = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const LinkImg = styled.img`
  width: 19px;
  height: auto;
`;

const DescriptionEl = styled.div`
  max-width: ${(props) => (props.$isEndStage ? "720px" : "400px")};
  max-height: 110px;
  overflow-y: auto;
  width: 100%;
  height: auto;
  border-radius: 8px;
  background: #1e1e1f;
  padding: 16px;
  box-sizing: border-box;
  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 12px 16px;
  }
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${Colors.gr07}; /* 스크롤바 색상 */
    border-radius: 3px; /* 스크롤바 둥근 테두리 */
  }
  &::-webkit-scrollbar-track {
    background: ${Colors.gr05}; /*스크롤바 뒷 배경 색상*/
  }
`;

const Description = styled.span`
  color: #fff;
  font-size: 16px;
  font-family: Robert Sans;
  font-style: italic;
  font-weight: 400;
  line-height: 28px;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 11px;
    line-height: 150%;
  }
`;

const SacrificedSoulsButton = styled.img`
  width: auto;
  height: 18px;
  cursor: pointer;
`;

const SacrificedSoulsModalEl = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding: 0px 24px;
  box-sizing: border-box;
  display: ${(props) => (props.$isSacrificedModalOpen ? "flex" : "none")};
  align-items: center;
  z-index: 10;
`;

const SacrificedSoulsModal = styled.div`
  width: 100%;
  border: 1px solid ${Colors.wt};
  background-color: ${Colors.gr02};
  display: flex;
  flex-direction: column;
`;

const TitleEl = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.wt};
  padding: 24px 32px 24px 32px;
  box-sizing: border-box;
  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 12px 16px 12px 16px;
  }
`;

const Title = styled.span`
  font-size: 22px;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 18px;
  }
`;

const CloseIcon = styled.img`
  ${(props) => props.isMinting === 1 && "visibility: hidden;"}
  cursor: pointer;
`;

const ContentEl = styled.div`
  padding: 24px 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  max-height: 400px;
  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 18px 10px;
    min-height: 200px;
    max-height: 300px;
    gap: 5px;
    font-size: 12px;
  }
`;

const SoulList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  gap: 10px;
  @media (max-width: ${ScreenSizes.mobile}) {
    gap: 5px;
    font-size: 12px;
  }
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${Colors.gr07}; /* 스크롤바 색상 */
    border-radius: 3px; /* 스크롤바 둥근 테두리 */
  }
`;

const SoulItem = styled.span`
  color: ${(props) => (props.highlight ? Colors.warning : "white")};
`;

function ArchiveItem({ archiveData }) {
  const tokenIdArray = useSelector((state) => state.web3TokenInfo.tokenIdArray);
  const chapter = useSelector((state) => state.component.archiveModalChapter);
  const stage = useSelector((state) => state.component.archiveModalStage);
  const showData = useSelector((state) => state.server.showData);

  const [sacrificedCount, setSacrificedCount] = useState(0);
  const [ownCount, setOwnCount] = useState(0);
  const [isSacrificedModalOpen, setIsSacrificedModalOpen] = useState(false);
  const [sacrificedSoulsList, setSacrificedSoulsList] = useState([]);

  const { account } = useWeb3React();

  const [isVideo, setIsVideo] = useState(true);
  useEffect(() => {
    if (archiveData[chapter][stage].publicData.redeemAssetMedia) {
      const urlArr =
        archiveData[chapter][stage].publicData.redeemAssetMedia.split(".");
      const ext = urlArr[urlArr.length - 1];
      if (ext !== "mp4") {
        setIsVideo(false);
      }
    }
  }, [showData]);

  useEffect(() => {
    console.log("ArchiveItem", archiveData[chapter][stage]);
    let sacriCount = 0;
    let userOwnCount = 0;
    const startId = archiveData[chapter][stage].burnRedeemInfo.startId;
    const finishId = archiveData[chapter][stage].burnRedeemInfo.finishId;
    for (let i = startId; i <= finishId; i++) {
      if (tokenIdArray[i] === "0x0000000000000000000000000000000000000000")
        sacriCount++;
      if (tokenIdArray[i] === account) userOwnCount++;
    }
    if (Number(archiveData[chapter][stage].burnRedeemInfo[2]) === 0)
      userOwnCount = 0;
    setSacrificedCount(sacriCount);
    setOwnCount(userOwnCount);

    let rawBurnSet = JSON.parse(
      JSON.stringify(archiveData[chapter][stage].publicData.burnSet)
    );

    //remove dupelication
    const uniqueBurnSet = rawBurnSet.filter((obj, idx) => {
      const isFirstFindIdx = rawBurnSet.findIndex(
        (obj2) => obj2.items[0].uri.name === obj.items[0].uri.name
      );
      return isFirstFindIdx === idx;
    });

    console.log("uniqueBurnSet", uniqueBurnSet);

    for (let i = 0; i < uniqueBurnSet.length; i++) {
      uniqueBurnSet[i].requiredCount = 0;
      for (let j = 0; j < rawBurnSet.length; j++) {
        if (
          JSON.stringify(uniqueBurnSet[i].items) ===
          JSON.stringify(rawBurnSet[j].items)
        ) {
          uniqueBurnSet[i].requiredCount++;
        }
      }
    }

    uniqueBurnSet.sort((a, b) => {
      a = a.items[0].uri.name.split(".")[0];
      b = b.items[0].uri.name.split(".")[0];
      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
    });

    console.log("sacrificedSoulsList", uniqueBurnSet);
    setSacrificedSoulsList(uniqueBurnSet);
  }, []);
  return (
    <>
      <ArchiveItemEl>
        <SacrificedSoulsModalEl $isSacrificedModalOpen={isSacrificedModalOpen}>
          <SacrificedSoulsModal>
            <TitleEl>
              <Title>Sacrificial Soul(s)</Title>
              <CloseIcon
                src={"/icons/cross_modal.svg"}
                alt={"close button"}
                onClick={() => {
                  setIsSacrificedModalOpen(false);
                }}
              />
            </TitleEl>
            <ContentEl>
              <SoulList>
                {sacrificedSoulsList.map((souls, index) => {
                  return (
                    <SoulItem
                      key={index}
                      highlight={
                        souls.items[0].uri.name.split(".")[0] === "000"
                      }
                    >
                      {souls.items[0].uri.name} X {souls.requiredCount}
                    </SoulItem>
                  );
                })}
              </SoulList>
            </ContentEl>
          </SacrificedSoulsModal>
        </SacrificedSoulsModalEl>
        {archiveData !== undefined &&
          (isVideo ? (
            <ItemVideo
              $isEndStage={stage === 999}
              autoPlay
              loop
              controls
              playsInline
            >
              <source
                src={archiveData[chapter][stage].publicData.redeemAssetMedia}
                type="video/mp4"
              />
            </ItemVideo>
          ) : (
            <ItemImage
              src={archiveData[chapter][stage].publicData.redeemAssetMedia}
            />
          ))}
        <ItemInfoEl>
          <ItemName>{archiveData[chapter][stage].publicData.name}</ItemName>
          <NFTOwnCount>
            You own : <NFTOwnCountHighlight>{ownCount}</NFTOwnCountHighlight>
          </NFTOwnCount>
          <NFTInfoEl>
            <NFTCountInfoEl>
              <NFTCount>
                {archiveData[chapter][stage].burnRedeemInfo[2]} raised
              </NFTCount>{" "}
              <NFTCountBar>|</NFTCountBar>
              <NFTCount>{sacrificedCount} sacrificed</NFTCount>{" "}
              <NFTCountBar>|</NFTCountBar>
              <NFTCount>
                {archiveData[chapter][stage].burnRedeemInfo[2] -
                  sacrificedCount}{" "}
                surviving
              </NFTCount>
            </NFTCountInfoEl>
            <LinkEl>
              <SacrificedSoulsButton
                src={"/icons/burn.png"}
                alt="burn items list"
                onClick={() => {
                  setIsSacrificedModalOpen(true);
                }}
              />
              <a
                href={
                  process.env.REACT_APP_ETHERSCAN_URL +
                  "address/" +
                  showData.publicData.redeemContractAddress
                }
                target="_blank"
                rel="noreferrer"
              >
                <LinkImg src={"/icons/etherscan.svg"} />
              </a>
              <a
                href={
                  process.env.REACT_APP_OPENSEA_URL +
                  showData.publicData.redeemContractAddress +
                  "/" +
                  archiveData[chapter][stage].burnRedeemInfo.finishId
                }
                target="_blank"
                rel="noreferrer"
              >
                <LinkImg src={"/icons/opensea.svg"} />
              </a>
            </LinkEl>
          </NFTInfoEl>
          <DescriptionEl $isEndStage={stage === 999}>
            <Description>
              {archiveData[chapter][stage].publicData.description}
            </Description>
          </DescriptionEl>
        </ItemInfoEl>
      </ArchiveItemEl>
    </>
  );
}
export default ArchiveItem;
