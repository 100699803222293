import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import CollectionItem from "./CollectionItem";
import { componentActions } from "../../store/componentSlice";
import { Colors, ScreenSizes } from "../../styles/Themes";
import ArchiveEndStageVideo from "./ArchiveEndStageVideo";

const ArchiveAreaEl = styled.div`
  padding-top: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  @media (max-width: ${ScreenSizes.mobile}) {
    padding-top: 24px;
    gap: 16px;
  }
`;

const CapterTitle = styled.span`
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 16px;
    font-weight: 700;
    line-height: 160%;
    letter-spacing: -0.16px;
  }
`;

const CollectionListEl = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 716px;
  gap: 8px;
  padding: 4px 32px;
  @media (max-width: ${ScreenSizes.mobile}) {
    gap: 4px;
    width: 330px;
    padding: 0px;
    justify-content: center;
  }
`;

function BonusArchiveArea() {
  const dispatch = useDispatch();
  const archiveData = useSelector((state) => state.web3TokenInfo.bonusArchiveData);

  function archiveModalHandler(isOpen, chapter, stage) {
    console.log("archiveModalOpenHandler stage : ",stage);
    dispatch(componentActions.setIsArchiveModalOpen(isOpen));
    dispatch(componentActions.setArchiveModalChapter(chapter));
    dispatch(componentActions.setArchiveModalStage(stage));
    dispatch(componentActions.setIsBlur(true));
  }
  return (
    <>
      {archiveData !== undefined && archiveData[4].length > 0 && (
        <ArchiveAreaEl>
          <CapterTitle>Archive - Bonus Chapter</CapterTitle>
          <ArchiveEndStageVideo
            archiveData={archiveData[4]}
            archiveModalHandler={archiveModalHandler}
            chapter={4}
          />
          <CollectionListEl>
            {archiveData[4].map((showData, index) => {
              return (
                <CollectionItem
                  chapter={4}
                  showData={showData}
                  archiveModalHandler={archiveModalHandler}
                  index={index}
                  key={index}
                />
              );
            })}
          </CollectionListEl>
        </ArchiveAreaEl>
      )}
    </>
  );
}
export default BonusArchiveArea;
