import { useEffect } from "react";
import styled from "styled-components";
import { Colors, ScreenSizes } from '../../styles/Themes';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useWeb3React } from "@web3-react/core";

const EndStageVideoEl = styled.div`
  width: 600px;
  height: auto;
  opacity: ${(props)=>(props.$isUserOwn ? "1" : "0.5")};
  &:hover{
    border: 1px solid ${Colors.archive};
  }
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 314px;
  }
`;

const EndStageVideo = styled.video`
  width: 100%;
  height: auto;
`;

function ArchiveEndStageVideo({ archiveData,archiveModalHandler ,chapter }) {
  const tokenIdArray = useSelector((state) => state.web3TokenInfo.tokenIdArray);
  const [isUserOwn, setIsUserOwn] = useState(false);
  const { account } = useWeb3React();
  useEffect(()=>{
    let userOwnCount = 0;
    const startId = archiveData[archiveData.length - 1].burnRedeemInfo.startId;
    const finishId = archiveData[archiveData.length - 1].burnRedeemInfo.finishId;
    console.log("ArchiveEndStageVideo : ",archiveData[archiveData.length - 1]);
    for (let i = startId; i <= finishId; i++) {
      if (tokenIdArray[i] === account)
        userOwnCount++;
    }
    setIsUserOwn(userOwnCount > 0);
  },[]);
  return (
    <>
      {archiveData[archiveData.length - 1].stage === 999 && (
        <EndStageVideoEl $isUserOwn={isUserOwn} onClick={(e)=>{archiveModalHandler(true,chapter,(archiveData.length - 1),e)}}>
          <EndStageVideo autoPlay muted loop playsInline>
            <source
              src={
                archiveData[archiveData.length - 1].publicData.redeemAssetMedia
              }
              type="video/mp4"
            />
          </EndStageVideo>
        </EndStageVideoEl>
      )}
    </>
  );
}
export default ArchiveEndStageVideo;
