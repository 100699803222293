import styled from "styled-components";
import { Sizes, Colors, ScreenSizes } from "../../styles/Themes";
import { useDispatch } from "react-redux";
import { componentActions } from '../../store/componentSlice';

const FooterEl = styled.footer`
  width: 100%;
  height: ${Sizes.FooterHeight};
  position: relative;
  bottom: 0px;
  background-color: ${Colors.gr02};
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 100%;
    height: ${Sizes.MobileFooterHeight};
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  height: 100%;
  padding-right: 48px;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 0px;
  }
`;

const FooterLogoBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: center;
  @media (max-width: ${ScreenSizes.mobile}) {
    flex-direction: column;
    gap: 16px;
  }
`;

const FooterLogoImg = styled.img`
  width: auto;
  height: 48px;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: auto;
    height: 48px;
  }
`;

const FooterLogoTextImg = styled.img`
  width: auto;
  height: 32px;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: auto;
    height: 32px;
  }
`;

function Footer() {
  const dispatch = useDispatch();
  function footerClickHandler(){
    dispatch(componentActions.increaseHiddenCount());
  }
  return (
    <FooterEl>
      <FooterWrapper>
        <FooterLogoBox onClick={footerClickHandler}>
          <FooterLogoImg src={"/images/footer_logo.svg"} />
          <FooterLogoTextImg src={"/images/footer_logo_text.svg"} />
        </FooterLogoBox>
      </FooterWrapper>
    </FooterEl>
  );
}
export default Footer;
