import { useEffect } from 'react';
import styled from "styled-components";
import { Colors } from '../../styles/Themes';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useWeb3React } from "@web3-react/core";

const CollectionItemEl = styled.div`
width: 102px;
height: auto;
border-radius: 2px;
/* border: 1px solid #777; */
display: ${(props)=>(props.$isEndStage ? "none" : "inline-block")};
opacity: ${(props)=>(props.$isUserOwn ? "1" : "0.3")};
&:hover{
  border: 1px solid ${Colors.archive};
}
`;

const CollectionVideo = styled.video`
width: 100%;
height: auto;
`;

function CollectionItem({showData, archiveModalHandler, chapter, index}) {
  //const archiveData = useSelector((state) => state.web3TokenInfo.archiveData);
  const tokenIdArray = useSelector((state) => state.web3TokenInfo.tokenIdArray);

  const [isUserOwn, setIsUserOwn] = useState(false);

  const { account } = useWeb3React();
  useEffect(()=>{
    console.log("CollectionItem showData : ",showData);
    let userOwnCount = 0;
    const startId = showData.burnRedeemInfo.startId;
    const finishId = showData.burnRedeemInfo.finishId;
    for (let i = startId; i <= finishId; i++) {
      if (tokenIdArray[i] === account)
        userOwnCount++;
    }
    if(Number(showData.burnRedeemInfo[2]) === 0)userOwnCount = 0;
    setIsUserOwn(userOwnCount > 0);
  },[]);
  return (
    <CollectionItemEl $isUserOwn={isUserOwn} $isEndStage={showData.stage === 999} onClick={(e)=>{archiveModalHandler(true,chapter,index,e)}}>
    {showData !== undefined &&
      <CollectionVideo autoPlay muted loop playsInline>
        <source src={showData.publicData.redeemAssetMedia} type="video/mp4" />
      </CollectionVideo>
    }
    </CollectionItemEl>
  );
}
export default CollectionItem;
