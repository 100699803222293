import styled from "styled-components";
import { Colors, ScreenSizes } from "../../../../styles/Themes";
import { useWeb3React } from "@web3-react/core";
import { useDispatch } from "react-redux";
import { asyncGetSettingData } from "../../../../store/serverSlice";
import { asyncGetAllTokenInfo } from "../../../../store/web3TokenInfoSlice";
import { useEffect } from 'react';
import { componentActions } from '../../../../store/componentSlice';

const BurnErrorEl = styled.div`
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 24px;
  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 88px 48px;
    box-sizing: border-box;
  }
`;

const Title = styled.span`
  color: ${Colors.warning};
  font-size: 32px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 20px;
  }
`;

const Explain = styled.span`
  color: #fff;
  font-size: 24px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 14px;
  }
`;

const CloseButton = styled.button`
  width: 112px;
  height: 34px;
  font-family: NanumSquare;
  font-weight: 700;
  font-size: 16px;
  color: ${Colors.gr02};
  cursor: pointer;
  border: none;
  border-radius: 8px;
  position: absolute;
  bottom: 40px;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 104px;
    height: 34px;
    font-size: 16px;
    margin-bottom: 8px;
  }
`;

function BurnError({ openModalHandler }) {
  const { account, library } = useWeb3React();
  const dispatch = useDispatch();
  function closeHandler() {
    openModalHandler(false);
    dispatch(asyncGetSettingData());
    dispatch(asyncGetAllTokenInfo({ account, provider: library.provider }));
  }
  useEffect(()=>{
    dispatch(componentActions.setIsProcessing(true));
  },[]);
  return (
    <BurnErrorEl>
      <Title>SOMETHING WENT WRONG !</Title>
      <Explain>
        Burn-redeem was unsuccessful.
        <br />
        Please close this window and try again.
      </Explain>
      <CloseButton onClick={closeHandler}>Close</CloseButton>
    </BurnErrorEl>
  );
}
export default BurnError;
