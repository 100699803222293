import styled from "styled-components";
import { Colors, ScreenSizes } from "../../../../styles/Themes";
import { useSelector, useDispatch } from "react-redux";
import ConfirmBurnItem from "./ConfirmBurnItem";
import ConfirmRedeemItem from "./ConfirmRedeemItem";
import ConfirmFee from "./ConfirmFee";
import { componentActions } from "../../../../store/componentSlice";
import ConfirmEndStageRedeemItem from "./ConfirmEndStageRedeemItem";
import { useEffect } from 'react';
import { useState } from 'react';

const ComfirmBurnEl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 0px;
  align-items: center;
  width: 100%;
  position: relative;
  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 16px 0px;
    gap: 16px;
  }
`;

const ExplainEl = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${ScreenSizes.mobile}) {
    gap: 8px;
  }
`;

const ExplainText = styled.span`
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 800;
  line-height: 160%;
  letter-spacing: -0.24px;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 16px;
    line-height: normal;
  }
`;

const SubExplainText = styled.span`
  color: ${props => props.theme.highlight};
  text-align: center;
  font-size: 16px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 11px;
    line-height: 150%;
  }
`;

const BurnItemsShowEl = styled.div`
  width: 560px;
  height: 232px;
  display: flex;
  flex-direction: column;
  background-color: ${Colors.gr700};
  border-radius: 8px;
  align-items: center;
  padding: 8px 24px;
  box-sizing: border-box;
  gap: 11px;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 262px;
    height: auto;
  }
`;

const BurningTitleEl = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 27px;
`;

const BurningTitleText = styled.span`
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 800;
  line-height: 160%;
  letter-spacing: -0.16px;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 14px;
    letter-spacing: -0.14px;
  }
`;

const TitleLine = styled.div`
  width: 424px;
  border: 0.5px solid #484747;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 136px;
  }
`;

const BurnItemListEl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 200px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${Colors.gr07}; /* 스크롤바 색상 */
    border-radius: 3px; /* 스크롤바 둥근 테두리 */
  }
  &::-webkit-scrollbar-track {
    background: ${Colors.gr05}; /*스크롤바 뒷 배경 색상*/
  }
`;

const ConfirmButton = styled.button`
  color: ${Colors.gr01};
  width: 199px;
  height: 59px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f2f2f2;
  background: ${props => props.theme.highlight};
  border-radius: 8px;
  padding: 0px;
  font-family: NanumSquare;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
  cursor: pointer;
  &:hover {
    filter: brightness(80%);
  }
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 163px;
    height: 40px;
    border-radius: 6px;
    font-size: 18px;
  }
`;

function ConfirmBurn({ isMultiBurn, showData, redeemQuantity, fees, setFees }) {
  const dispatch = useDispatch();
  const [isQuestionOpen, setIsQuestionOpen]= useState(false);
  function nextStepHandler() {
    dispatch(componentActions.setBurnModalStep(2));
  }
  useEffect(()=>{
    dispatch(componentActions.setIsProcessing(false));
  },[]);

  function openQuestionHandler(isOpen){
    setIsQuestionOpen(isOpen);
  }
  return (
    <ComfirmBurnEl>
      <ExplainEl>
        <ExplainText>Confirm Burn Redeem</ExplainText>
        <SubExplainText>
          Souls (NFTs) that get burned will be lost forever.
          <br />
          Are you sure you want to proceed?
        </SubExplainText>
      </ExplainEl>
      <BurnItemsShowEl>
        <BurningTitleEl>
          <BurningTitleText>Burning</BurningTitleText>
          <TitleLine />
        </BurningTitleEl>
        <BurnItemListEl>
          {showData.publicData.burnSet.map((burnSet, index) => {
            return (
              <ConfirmBurnItem
                burnSet={burnSet}
                redeemQuantity={redeemQuantity}
                key={index}
              />
            );
          })}
        </BurnItemListEl>
      </BurnItemsShowEl>

      {showData.stage === 999 ? (
        <ConfirmEndStageRedeemItem
          showData={showData}
          redeemQuantity={redeemQuantity}
        />
      ) : (
        <ConfirmRedeemItem
          showData={showData}
          redeemQuantity={redeemQuantity}
        />
      )}

      <ConfirmFee
        showData={showData}
        isMultiBurn={isMultiBurn}
        redeemQuantity={redeemQuantity}
        fees={fees}
        setFees={setFees}
      />
      <ConfirmButton onClick={nextStepHandler}>Confirm Burn</ConfirmButton>
    </ComfirmBurnEl>
  );
}
export default ConfirmBurn;
