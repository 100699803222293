import styled from "styled-components";
import { Colors,ScreenSizes } from "../../../../styles/Themes";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const BurnItemsEl = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BurnItemVideo = styled.video`
  width: 104px;
  height: auto;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 80px;
  }
`;

const BurnItemInfoEl = styled.div`
  padding: 8px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
`;

const BurnItemName = styled.span`
  color: ${(props) => (props.$isOver ? Colors.warning : Colors.wt)};
  font-size: 16px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
   text-overflow: ellipsis;
   overflow: hidden;
   word-break: break-word;
    
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 12px;
  }
`;

const BurnItemNeedCount = styled.span`
  font-size: 16px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${(props) => (props.$isOver ? Colors.warning : Colors.highlight)};
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 12px;
  }
`;

const BurnItemOwnCount = styled.span`
  color: var(--grey-200, #bbb);
  text-align: center;
  font-size: 12px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 11px;
  }
`;

function QuantityBurnItem({burnSet, redeemQuantity}) {
  const tokenIdsInWallet = useSelector(
    (state) => state.web3TokenInfo.tokenIdsInWallet
  );
  const [ownCount, setOwnCount] = useState(0);
  useEffect(() => {
    const minTokenId = Number(burnSet.items[0].minTokenId);
    const maxTokenId = Number(burnSet.items[0].maxTokenId);
    let count = 0;
    for (let i = 0; i < tokenIdsInWallet.length; i++) {
      if (
        minTokenId <= tokenIdsInWallet[i] &&
        tokenIdsInWallet[i] <= maxTokenId
      ) {
        count++;
      }
    }
    setOwnCount(count);
  }, []);

  return (
    <BurnItemsEl>
      <BurnItemVideo autoPlay muted loop playsInline>
        <source src={burnSet.items[0].uri.animation} type="video/mp4" />
      </BurnItemVideo>
      <BurnItemInfoEl>
        <BurnItemName
          $isOver={burnSet.requiredCount * redeemQuantity > ownCount}
        >
          {burnSet.items[0].uri.name}
        </BurnItemName>
        <BurnItemNeedCount
          $isOver={burnSet.requiredCount * redeemQuantity > ownCount}
        >
          x {burnSet.requiredCount * redeemQuantity}
        </BurnItemNeedCount>
        <BurnItemOwnCount>You own : {ownCount}</BurnItemOwnCount>
      </BurnItemInfoEl>
    </BurnItemsEl>
  );
}
export default QuantityBurnItem;
