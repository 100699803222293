import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

export const networkID = [1];

export const WalletConnect = new WalletConnectConnector({
  rpcUrl: `${process.env.REACT_APP_RPC}+${process.env.REACT_APP_INFURA_KEY}`,
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
  supportedChainIds: networkID,
});
export const injected = new InjectedConnector({
  supportedChainIds: networkID,
});
