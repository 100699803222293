import styled from "styled-components";
import { ScreenSizes } from "../../../styles/Themes";

const SubTitle = styled.span`
  color: #fff;
  text-align: center;
  font-size: 32px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.32px;

  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 16px;
    font-weight: 800;
    letter-spacing: -0.16px;
  }
`;

function EndStageSubTitle() {
  return <SubTitle>&#x1F525; BURN THEM ALL &#x1F525;</SubTitle>;
}
export default EndStageSubTitle;
