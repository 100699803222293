import styled from "styled-components";
import { Colors, ScreenSizes } from "../../styles/Themes";
import { useDispatch } from "react-redux";
import { componentActions } from "../../store/componentSlice";

const Button = styled.button`
  color: ${Colors.gr01};
  width: 222px;
  height: 59px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.highlight};
  border-radius: 8px;
  padding: 0px;
  font-family: NanumSquare;
  font-size: 24px;
  font-weight: 700;
  ${(props)=>(props.$isBurnStart ? "" : "opacity:0.3")};
  ${(props)=>(props.$isBurnStart ? "" : "cursor: pointer")};
  cursor: pointer;
  &:hover {
    filter: brightness(80%);
  }
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 163px;
    height: 40px;
    border-radius: 6px;
    font-size: 18px;
  }
`;
function BTABurnRedeemButton({ isBurnStart }) {
  const dispatch = useDispatch();
  function openModalHandler(isOpen) {
    if(!isBurnStart) return;
    dispatch(componentActions.setIsBurnModalOpen(isOpen));
    // dispatch(componentActions.setIsBonusBurnModalOpen(isOpen));
    dispatch(componentActions.setIsBlur(isOpen));
  }
  return (
    <Button
      $isBurnStart={isBurnStart}
      onClick={(e) => {
        openModalHandler(true, e);
      }}
    >
      Burn & Redeem
    </Button>
  );
}
export default BTABurnRedeemButton;
