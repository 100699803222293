import { useEffect } from "react";
import styled from "styled-components";
import { Colors } from "../../styles/Themes";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useWeb3React } from "@web3-react/core";

const CollectionItemEl = styled.div`
  width: 192px;
  height: auto;
  border-radius: 2px;
  /* border: 1px solid #777; */
  display: ${(props) => (props.$isEndStage ? "none" : "inline-block")};
  opacity: ${(props) => (props.$isUserOwn ? "1" : "0.3")};
  position: relative;
  &:hover {
    border: 1px solid ${Colors.archive};
  }
`;

const CollectionVideo = styled.video`
  width: 100%;
  height: auto;
`;

const CollectionImage = styled.img`
  width: 100%;
  height: auto;
`;

const BurnCountEl = styled.div`
  position: absolute;
  bottom: 0px;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const BurnCountText = styled.span`
  color: #fff;
  text-align: center;
  font-family: NanumSquareOTF;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 38.4px */
  letter-spacing: -0.24px;
`;

function BTACollectionItemChapter4({ showData, archiveModalHandler, chapter, index }) {
  //const archiveData = useSelector((state) => state.web3TokenInfo.archiveData);
  const tokenIdArray = useSelector((state) => state.web3TokenInfo.tokenIdArray);
  const BTABurnSet = useSelector(
    (state) => state.server.BTAShowData.publicData.burnSet
  );
  const isBtaFinish = useSelector((state) => state.server.isBtaFinish);

  const [isUserOwn, setIsUserOwn] = useState(false);
  const [burnCount, setBurnCount] = useState(0);

  const { account } = useWeb3React();
  useEffect(() => {
    for (let i = 0; i < BTABurnSet.length; i++) {
      if (BTABurnSet[i].items[0].uri.name === showData.publicData.name) {
        console.log(
          `BTACollectionItem ${BTABurnSet[i].items[0].uri.name} ${BTABurnSet[i].requiredCount}`
        );

        setBurnCount(BTABurnSet[i].requiredCount);
      }
    }

    let userOwnCount = 0;
    const startId = showData.burnRedeemInfo.startId;
    const finishId = showData.burnRedeemInfo.finishId;
    for (let i = startId; i <= finishId; i++) {
      if (tokenIdArray[i] === account) userOwnCount++;
    }
    if (Number(showData.burnRedeemInfo[2]) === 0) userOwnCount = 0;
    setIsUserOwn(userOwnCount > 0);
  }, []);

  const [isVideo, setIsVideo] = useState(true);
  useEffect(() => {
    if (showData.publicData.redeemAssetMedia) {
      const urlArr = showData.publicData.redeemAssetMedia.split(".");
      const ext = urlArr[urlArr.length - 1];
      if (ext !== "mp4") {
        setIsVideo(false);
      }
    }
  }, [showData]);
  
  return (
    <CollectionItemEl
      $isUserOwn={isUserOwn}
      $isEndStage={showData.stage === 999}
      onClick={(e) => {
        archiveModalHandler(true, chapter, index, e);
      }}
    >
      {showData !== undefined && isVideo ? (
        <CollectionVideo autoPlay muted loop playsInline>
          <source src={showData.publicData.redeemAssetMedia} type="video/mp4" />
        </CollectionVideo>
      ) : (
        <CollectionImage src={showData.publicData.redeemAssetMedia} />
      )}
      {!isBtaFinish && (
        <BurnCountEl>
          <BurnCountText>&#x1F525; X{burnCount}</BurnCountText>
        </BurnCountEl>
      )}
    </CollectionItemEl>
  );
}
export default BTACollectionItemChapter4;
