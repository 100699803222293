import styled from "styled-components";
import { Colors, ScreenSizes } from "../../../styles/Themes";
import { useDispatch } from "react-redux";
import { componentActions } from "../../../store/componentSlice";
import { injected } from "../../../lib/connectors";
import { WalletConnect } from "../../../lib/connectors";
import { useWeb3React } from "@web3-react/core";
import { useState } from 'react';

const ModalBackgroundEl = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SelectWalletEl = styled.div`
  background-color: ${Colors.wt};
  width: 608px;
  height: 474px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Colors.gr02};
  border: 1px solid ${Colors.wt};
  color: ${Colors.wt};
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 272px;
    height: 338px;
  }
`;

const TitleEl = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.wt};
  padding: 12px 16px 12px 16px;
  box-sizing: border-box;
`;

const Title = styled.span`
  font-size: 24px;
  font-family: NanumSquare;
  font-weight: 700;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 18px;
  }
`;

const CloseIcon = styled.img`
  cursor: pointer;
`;

const WalletEl = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 34px - 32px);
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 32px;
`;
const WalletItemEl = styled.div`
  display: flex;
  width: 480px;
  height: 88px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  background-color: #1e1e1f;
  cursor: pointer;
  &:hover {
    background-color: ${Colors.gr600};
  }
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 228px;
    height: 56px;
    border-radius: 8px;
  }
`;

const DisableWalletItemEl = styled.div`
  display: flex;
  width: 480px;
  height: 88px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  background-color: #101010;
  cursor: not-allowed;
  position: relative;
  &:hover {
    background-color: ${Colors.gr600};
  }
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 228px;
    height: 56px;
    border-radius: 8px;
  }
`;

const WalletConnectImg = styled.img`
  height: 32px;
  width: auto;
  opacity: 0.2;
  @media (max-width: ${ScreenSizes.mobile}) {
    height: 20px;
  }
`;

const MetamaskImg = styled.img`
  height: 44px;
  width: auto;
  @media (max-width: ${ScreenSizes.mobile}) {
    height: 27px;
  }
`;

const WarningImg = styled.img`
height: 40px;
width: auto;
position: absolute;
`;

const WarningText = styled.span`
  width: 400px;
  padding: 15px;
  border-radius: 10px;
  position: absolute;
  bottom: 0px;
  background-color: ${Colors.gr04};
  display: ${(props)=>(props.$isMouseHover ? "block" : "none")};
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 200px;
    bottom: -50px;
  }
`;

const ButtonEl = styled.div`
  display: none;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: inline-block;
    margin-bottom: 32px;
    left: 0px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const CloseButton = styled.button`
  width: 104px;
  height: 34px;
  border: none;
  font-family: NanumSquare;
  font-size: 16px;
  font-weight: 700;
  border-radius: 8px;
  cursor: pointer;
`;

function WalletConnectModal() {
  const dispatch = useDispatch();
  const { activate } = useWeb3React();
  const [isMouseHover, setIsMouseHover] = useState(false);

  async function connectWalletHandler(type) {
    let provider = null;
    if (type === "metamask") {
      provider = injected;
    } else if (type === "walletConnect") {
      provider = WalletConnect;
    }
    try {
      await activate(provider);
      openModalHandler(false);
    } catch (error) {
      console.log(error);
    }
  }

  function openModalHandler(isOpen) {
    dispatch(componentActions.setIsWalletModalOpen(isOpen));
    dispatch(componentActions.setIsBlur(isOpen));
  }
  return (
    <ModalBackgroundEl>
      <SelectWalletEl>
        <TitleEl>
          <Title>Select Wallet</Title>
          <CloseIcon
            src={"/icons/cross_modal.svg"}
            alt={"close button"}
            onClick={(e) => {
              openModalHandler(false, e);
            }}
          />
        </TitleEl>
        <WalletEl>
        <WalletItemEl
            onClick={(e) => {
              connectWalletHandler("metamask", e);
            }}
          >
            <MetamaskImg src={"/icons/metamask.svg"} />
          </WalletItemEl>
          <DisableWalletItemEl
            onMouseEnter={()=>setIsMouseHover(true)} onMouseLeave={()=>setIsMouseHover(false)}
          >
            <WalletConnectImg src={"/icons/wallet_connect.svg"} />
            <WarningImg src={"/images/warning.png"} />
          </DisableWalletItemEl>
          <WarningText $isMouseHover={isMouseHover}>WalletConnect support is temporarily disabled — WalletConnect v2 does not yet support some of the key function calls that Burning Souls uses.  The team is working on resolving this; thank you for your patience.</WarningText>
        </WalletEl>
        <ButtonEl>
          <CloseButton
            onClick={(e) => {
              openModalHandler(false, e);
            }}
          >
            Close
          </CloseButton>
        </ButtonEl>
      </SelectWalletEl>
    </ModalBackgroundEl>
  );
}
export default WalletConnectModal;
