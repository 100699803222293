import styled from "styled-components";
import { Colors, ScreenSizes } from "../../../../styles/Themes";
import { useSelector, useDispatch } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import { asyncGetSettingData } from "../../../../store/serverSlice";
import { asyncGetAllTokenInfo } from "../../../../store/web3TokenInfoSlice";
import { useEffect } from 'react';
import { componentActions } from '../../../../store/componentSlice';

const BurnSuccessEl = styled.div`
  width: 100%;
  height: auto;
  padding: 30px 0px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  align-items: center;
`;

const Explain = styled.span`
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  letter-spacing: -0.16px;
`;

const RedeenQuantity = styled.span`
  color: ${props => props.theme.highlight};
  font-size: 20px;
`;

const RedeemItemVideo = styled.video`
  width: ${(props)=>(props.$isEndStage ? "513px" : "344px")};
  height: auto;
`;

const CloseButton = styled.button`
  width: 112px;
  height: 34px;
  font-family: NanumSquare;
  font-weight: 700;
  font-size: 16px;
  color: ${Colors.gr02};
  cursor: pointer;
  border: none;
  border-radius: 8px;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 104px;
    height: 34px;
    font-size: 16px;
    margin-bottom: 8px;
  }
`;

function BurnSuccess({ showData, redeemQuantity, openModalHandler }) {
  const redeemTokenId = useSelector((state) => state.web3Burn.redeemTokenId);
  const { account, library } = useWeb3React();
  const dispatch = useDispatch();
  function closeHandler() {
    openModalHandler(false);
    dispatch(asyncGetSettingData());
    dispatch(asyncGetAllTokenInfo({ account, provider: library.provider }));
  }
  useEffect(()=>{
    dispatch(componentActions.setIsProcessing(true));
  },[]);
  return (
    <BurnSuccessEl>
      <Explain>
        SUCCESSFULLY CLAIMED
        <br />
        <RedeenQuantity>{redeemQuantity}</RedeenQuantity> OF THIS SOUL!
      </Explain>
      <a
        href={
          process.env.REACT_APP_OPENSEA_URL +
          showData.publicData.redeemContractAddress +
          "/" +
          redeemTokenId
        }
        target="_blank"
        rel="noreferrer"
      >
        <RedeemItemVideo $isEndStage={showData.stage === 999} autoPlay muted loop playsInline>
          <source src={showData.publicData.redeemAssetMedia} type="video/mp4" />
        </RedeemItemVideo>
      </a>
      <CloseButton onClick={closeHandler}>Close</CloseButton>
    </BurnSuccessEl>
  );
}
export default BurnSuccess;
