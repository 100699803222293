import { useEffect } from "react";
import styled from "styled-components";
import { Colors, ScreenSizes } from "../../styles/Themes";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useWeb3React } from "@web3-react/core";

const EndStageVideoEl = styled.div`
  width: 600px;
  height: auto;
  opacity: ${(props) => (props.$isUserOwn ? "1" : "0.5")};
  position: relative;
  &:hover {
    border: 1px solid ${Colors.archive};
  }
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 314px;
  }
`;

const EndStageVideo = styled.video`
  width: 100%;
  height: auto;
`;

const BurnCountEl = styled.div`
  position: absolute;
  bottom: 0px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 0px 10px;
`;

const BurnCountText = styled.span`
  color: #fff;
  text-align: center;
  font-family: NanumSquareOTF;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 38.4px */
  letter-spacing: -0.24px;
`;

function BTAArchiveEndStageVideo({
  archiveData,
  archiveModalHandler,
  chapter,
}) {
  const tokenIdArray = useSelector((state) => state.web3TokenInfo.tokenIdArray);
  const BTABurnSet = useSelector(
    (state) => state.server.BTAShowData.publicData.burnSet
  );
  const isBtaFinish = useSelector((state)=>state.server.isBtaFinish);
  const [isUserOwn, setIsUserOwn] = useState(false);
  const [burnCount, setBurnCount] = useState(0);

  const { account } = useWeb3React();

  useEffect(() => {
    for (let i = 0; i < BTABurnSet.length; i++) {
      if (
        BTABurnSet[i].items[0].uri.name ===
        archiveData[archiveData.length - 1].publicData.name
      ) {
        console.log(
          `BTAArchiveEndStageVideo ${BTABurnSet[i].items[0].uri.name} ${BTABurnSet[i].requiredCount}`
        );

        setBurnCount(BTABurnSet[i].requiredCount);
      }
    }

    let userOwnCount = 0;
    const startId = archiveData[archiveData.length - 1].burnRedeemInfo.startId;
    const finishId =
      archiveData[archiveData.length - 1].burnRedeemInfo.finishId;
    console.log("ArchiveEndStageVideo : ", archiveData[archiveData.length - 1]);
    for (let i = startId; i <= finishId; i++) {
      if (tokenIdArray[i] === account) userOwnCount++;
    }
    setIsUserOwn(userOwnCount > 0);
  }, []);
  return (
    <>
      {archiveData[archiveData.length - 1].stage === 999 && (
        <EndStageVideoEl
          $isUserOwn={isUserOwn}
          onClick={(e) => {
            archiveModalHandler(true, chapter, archiveData.length - 1, e);
          }}
        >
          <EndStageVideo autoPlay muted loop playsInline>
            <source
              src={
                archiveData[archiveData.length - 1].publicData.redeemAssetMedia
              }
              type="video/mp4"
            />
          </EndStageVideo>
          {!isBtaFinish && 
          <BurnCountEl>
            <BurnCountText>&#x1F525; X{burnCount}</BurnCountText>
          </BurnCountEl>}
        </EndStageVideoEl>
      )}
    </>
  );
}
export default BTAArchiveEndStageVideo;
