import styled from "styled-components";
import { ScreenSizes, Sizes } from "../styles/Themes";
import { useDispatch, useSelector } from "react-redux";
import WalletConnectModal from "../components/modal/WalletConnect/WalletConnectModal";
import { useWeb3React } from "@web3-react/core";
import TestInformation from "../components/modal/dev/TestInformation";
import LoginPage from "./LoginPage";
import BaseArchiveModal from "../components/modal/archive/BaseArchiveModal";
import { useEffect } from "react";
import BaseRedeemModal from "../components/modal/RedeemModal/BaseRedeemModal";
import BTAArchiveArea from "../components/archive/BTAArchiveArea";
import BTABurnRedeemButtonArea from "../components/main/BTABurnRedeemButtonArea";
import BTABaseBurnModal from "../components/modal/BurnRedeem/BTABaseBurnModal";
import { useNavigate } from "react-router-dom";
import BTABaseArchiveModal from "../components/modal/archive/BTABaseArchiveModal";
import { componentActions } from "../store/componentSlice";
import Explain from "../components/main/Explain";

const MainContentsEl = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  min-height: calc(100vh - ${Sizes.HeaderHeight} - ${Sizes.FooterHeight});
  padding-top: calc(${Sizes.HeaderHeight} + 24px);
  gap: 18px;
  padding-bottom: 120px;
  filter: ${(props) => (props.blur ? "blur(2px);" : "none;")};
  @media (max-width: ${ScreenSizes.tablet}) {
    padding-top: calc(${Sizes.HeaderHeight} + 8px);
    gap: 8px;
  }
  @media (max-width: ${ScreenSizes.mobile}) {
    padding-top: calc(${Sizes.MobileHeaderHeight} + 16px);
    gap: 10px;
    padding-bottom: 73px;
    min-height: calc(
      100vh - ${Sizes.HeaderHeight} - ${Sizes.MobileFooterHeight}
    );
  }
`;

const BTATitle = styled.span`
  color: ${props => props.theme.highlight};
  text-align: center;
  font-family: NanumSquareOTF;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 57.6px */
  letter-spacing: -0.36px;
`;

const BTAMainVideoEl = styled.div`
  width: 100%;
  /* padding: 16px 0px 16px 0px; */
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 0px 5px 0px 5px;
  }
`;

const BTAMainVideo = styled.video`
  width: 600px;
  height: auto;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 100%;
    height: auto;
  }
`;

const BTAExplain = styled.span`
  color: #f90;
  text-align: center;
  font-family: NanumSquareOTF;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 24px */
  letter-spacing: -0.15px;
`;

const FlameLeftEl = styled.div`
  position: fixed;
  left: 0px;
  top: 52px;
  z-index: 10;
  height: 100vh;
  @media (max-width: ${ScreenSizes.mobile}) {
    z-index: -1;
    left: -60px;
  }
`;

const FlameRightEl = styled.div`
  position: fixed;
  right: 0px;
  top: 52px;
  z-index: 10;
  height: 100vh;
  @media (max-width: ${ScreenSizes.mobile}) {
    z-index: -1;
    right: -20px;
  }
`;

const MobileBr = styled.br`
  display: none;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: inline-block;
  }
`;

const BTAThanksText = styled.span`
  color: #fff;
  text-align: center;
  font-family: NanumSquareOTF;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 32px */
  letter-spacing: -0.2px;
  padding: 23px 0px;
`;

const HighLight = styled.span`
  color: ${props => props.theme.highlight};
`;

function BTAPage({ setChapterColor }) {
  const isBlur = useSelector((state) => state.component.isBlur);
  const hiddenCount = useSelector((state) => state.component.hiddenCount);
  const settingData = useSelector((state) => state.server.settingData);
  const showData = useSelector((state) => state.server.showData);
  const isBtaFinish = useSelector((state)=>state.server.isBtaFinish);
  const btaUser = useSelector((state)=>state.server.btaUser);
  const dispatch = useDispatch();

  const { account } = useWeb3React();
  const isWalletModalOpen = useSelector(
    (state) => state.component.isWalletModalOpen
  );
  const isBurnModalOpen = useSelector(
    (state) => state.component.isBurnModalOpen
  );
  const isArchiveModalOpen = useSelector(
    (state) => state.component.isArchiveModalOpen
  );
  const isRedeemModalOpen = useSelector(
    (state) => state.component.isRedeemModalOpen
  );
  const settingDataStatus = useSelector(
    (state) => state.server.settingDataStatus
  );
  const getTokenInfoState = useSelector(
    (state) => state.web3TokenInfo.getTokenInfoState
  );
  const isBTAModalOpen = useSelector(
    (state) => state.component.isBTAModalOpen
  );

  function archiveModalHandler() {
    if (isBtaFinish) {
      dispatch(componentActions.setIsBTAModalOpen(true));
      dispatch(componentActions.setIsBlur(true));
    }
  }

  useEffect(() => {
    if (showData !== undefined) {
      if (isBtaFinish) {
        setChapterColor(0);
      }else{
        setChapterColor(5);
      }
    }
  }, []);

  return (
    <>
      {hiddenCount > 10 && <TestInformation settingData={settingData} />}

      {isWalletModalOpen && <WalletConnectModal />}

      {isBurnModalOpen && <BTABaseBurnModal />}

      {isArchiveModalOpen && <BaseArchiveModal />}

      {isRedeemModalOpen && <BaseRedeemModal showData={showData} />}

      {isBTAModalOpen && <BTABaseArchiveModal />}

      {account === undefined ? (
        <LoginPage setChapterColor={setChapterColor}/>
      ) : (
        !(settingDataStatus === 1 || getTokenInfoState === 1) && (
          <MainContentsEl blur={isBlur}>
            <FlameLeftEl>
              {isBtaFinish ? (
                <img
                  style={{ height: "100%" }}
                  src={"/images/smoke_left.png"}
                  alt="smoke left"
                />
              ) : (
                <img
                  style={{ height: "100%" }}
                  src={"/images/flame_left.gif"}
                  alt="flame left"
                />
              )}
            </FlameLeftEl>
            <FlameRightEl>
              {isBtaFinish ? (
                <img
                  style={{ height: "100%" }}
                  src={"/images/smoke_right.png"}
                  alt="flame right"
                />
              ) : (
                <img
                  style={{ height: "100%" }}
                  src={"/images/flame_right.gif"}
                  alt="flame left"
                />
              )}
            </FlameRightEl>
            {isBtaFinish ? (
              <BTATitle style={{ lineHeight: "100%" }}>
                THE BURNING OF THE SOULS
                <br />
                <span style={{ fontSize: "24px" }}>IS NOW COMPLETE.</span>
              </BTATitle>
            ) : (
              <BTATitle>THE FINAL ACT : EVERYTHING BURNS</BTATitle>
            )}
            {isBtaFinish && (
              <BTAThanksText>THANK YOU FOR YOUR SACRIFICES.</BTAThanksText>
            )}
            <BTAMainVideoEl onClick={archiveModalHandler}>
              <BTAMainVideo autoPlay muted loop playsInline>
                <source
                  src={"https://burningsouls.com/public/videos/BTA_artwork.mp4"}
                  type="video/mp4"
                />
              </BTAMainVideo>
            </BTAMainVideoEl>
            {isBtaFinish && (
              <BTAExplain style={{color:"white"}}>
                <HighLight>{btaUser.substr(0, 5) + "..." + btaUser.substr(37, 42)}</HighLight> completed the Burning of the Souls
                <br />
                by undertaking an insurmountable sacrifice.
              </BTAExplain>
            )}
            {!isBtaFinish && (
              <>
                <Explain />
                <BTABurnRedeemButtonArea />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="38"
                  height="51"
                  viewBox="0 0 38 51"
                  fill="none"
                >
                  <path
                    d="M20.7678 1.23223C19.7915 0.255924 18.2085 0.255924 17.2322 1.23223L1.32233 17.1421C0.346021 18.1184 0.346021 19.7014 1.32233 20.6777C2.29864 21.654 3.88155 21.654 4.85787 20.6777L19 6.53553L33.1421 20.6777C34.1184 21.654 35.7014 21.654 36.6777 20.6777C37.654 19.7014 37.654 18.1184 36.6777 17.1421L20.7678 1.23223ZM21.5 51L21.5 3L16.5 3L16.5 51L21.5 51Z"
                    fill="white"
                  />
                </svg>
                <BTAExplain>
                  THIS BURN REQUIRES{" "}
                  <span style={{ color: "#FFFB9A" }}>ALL</span> OF THE{" "}
                  <MobileBr />
                  FOLLOWING SOULS TO BE SACRIFICED :
                </BTAExplain>
              </>
            )}
            <BTAArchiveArea />
          </MainContentsEl>
        )
      )}
    </>
  );
}
export default BTAPage;
