import styled from "styled-components";
import { Colors, ScreenSizes } from "../../../../styles/Themes";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Web3 from "web3";
import { ethers } from "ethers";
import manifoldFeeInfo from "../../../../informations/manifoldFee.json";
import { useState } from 'react';

const FeeInfoEl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 8px 72px;
  box-sizing: border-box;
  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 0px 32px;
    gap: 12px;
  }
`;

const FeeInfoBoxEl = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 4px;
  @media (max-width: ${ScreenSizes.mobile}) {
    gap: 0px;
  }
`;

const ManifoldFeeInfoEl = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const BurnFeeInfoEl = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const FeeTitle = styled.span`
  color: #fff;
  font-size: 16px;
  font-family: NanumSquare;
  font-style: normal;
  letter-spacing: -0.16px;
  display: flex;
  align-items: center;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 11px;
    font-weight: 700;
    line-height: 160%;
    letter-spacing: -0.11px;
  }
`;

const FeeEl = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const Fee = styled.span`
  color: #fff;
  text-align: right;
  font-size: 16px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.16px;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 11px;
    letter-spacing: -0.11px;
  }
`;

const ETH = styled.span`
  color: #fff;
  text-align: right;
  font-size: 16px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.16px;
  color: ${Colors.gr200};
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 11px;
    letter-spacing: -0.11px;
  }
`;

const FeeLine = styled.div`
  width: 100%;
  height: 0px;
  border: 0.2px solid ${Colors.gr200};
`;

const TotalFeeInfoEl = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const TotalFeeTitle = styled.span`
  color: #fff;
  font-size: 16px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 800;
  line-height: 160%;
  letter-spacing: -0.16px;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 11px;
    letter-spacing: -0.11px;
  }
`;

const TotalFee = styled.span`
  color: #fff;
  text-align: right;
  font-size: 28px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.28px;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 20px;
    letter-spacing: -0.2px;
  }
`;

const QuestionEl = styled.div`
  margin-left: 5px;
  display: flex;
  align-items: center;
`;

const QuestionButton = styled.img`
  width: 14px;
  height: 14px;
  cursor: pointer;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 12px;
    height: 12px;
  }
`;

const QuestionBox = styled.span`
  position: absolute;
  background-color: ${Colors.gr04};
  padding: 15px 15px;
  border-radius: 10px 10px 10px 0px;
  bottom: 16px;
  left: 35%;
  width: 300px;
  line-height: 120%;
  height: auto;
  display: ${(props)=>(props.$isMouseHover ? "block" : "none")};
  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 10px;
    width: 230px;
    bottom: 20px;
    left: -15px;
    border-radius:10px;
  }
`;

function ConfirmFee({ showData, isMultiBurn, redeemQuantity, fees, setFees }) {
  const [isMouseHover, setIsMouseHover] = useState(false);
  const web3 = new Web3();
  useEffect(() => {
    const burnFee = ethers.BigNumber.from(showData.mintPrice)
      .mul(redeemQuantity)
      .toString();
    const manifoldFee = ethers.BigNumber.from(
      isMultiBurn ? manifoldFeeInfo.MULTI_BURN_FEE : manifoldFeeInfo.BURN_FEE
    )
      .mul(redeemQuantity)
      .toString();
    const totalFee = ethers.BigNumber.from(burnFee).add(manifoldFee).toString();
    setFees({ burnFee, manifoldFee, totalFee });
    // console.log("ConfirmFee fees", fees);
  }, []);
  return (
    <FeeInfoEl>
      <FeeInfoBoxEl>
        <ManifoldFeeInfoEl>
          <FeeTitle>
            Contract service fee
            <QuestionEl>
              <QuestionButton src="/icons/question.svg" onMouseEnter={()=>setIsMouseHover(true)} onMouseLeave={()=>setIsMouseHover(false)}/>
              <QuestionBox $isMouseHover={isMouseHover}>
                Burning Souls uses Manifold Studio's smart contract to operate
                its burn & redeem functions. Manifold Studio's smart contract
                automatically charges you with a service fee.
              </QuestionBox>
            </QuestionEl>
          </FeeTitle>
          <FeeEl>
            <Fee>{web3.utils.fromWei(fees.manifoldFee, "ether")}</Fee>
            <ETH>ETH</ETH>
          </FeeEl>
        </ManifoldFeeInfoEl>
        <BurnFeeInfoEl>
          <FeeTitle>Burn fee for creator</FeeTitle>
          <FeeEl>
            <Fee>{web3.utils.fromWei(fees.burnFee, "ether")}</Fee>
            <ETH>ETH</ETH>
          </FeeEl>
        </BurnFeeInfoEl>
      </FeeInfoBoxEl>
      <FeeLine />
      <TotalFeeInfoEl>
        <TotalFeeTitle>TOTAL</TotalFeeTitle>
        <FeeEl>
          <TotalFee>{web3.utils.fromWei(fees.totalFee, "ether")}</TotalFee>
          <ETH>ETH</ETH>
        </FeeEl>
      </TotalFeeInfoEl>
    </FeeInfoEl>
  );
}
export default ConfirmFee;
