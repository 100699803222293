import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { serverActions } from "./serverSlice";
import Web3 from "web3";
import axios from "axios";
import BaseContractInfo from "../contracts/BaseContractInfo.json";
import TestBaseContractInfo from "../contracts/TestBaseContractInfo.json";
import BurnRedeemContractInfo from "../contracts/ERC721BurnRedeem.json";
import TestBurnRedeemContractInfo from "../contracts/TestERC721BurnRedeem.json";

//  STATUS VALUE
//  0 : WAIT
//  1 : LOADING
//  2 : FINISH
//  3 : ERROR
const initialState = {
  tokenIdArray: [],
  getTokenInfoState: 0,
  tokenIdsInWallet: [],
  burnRedeemInfo: {},
  bonusBurnRedeemInfo: {},
  archiveData: undefined,
  bonusArchiveData: undefined,
};

const web3TokenInfoSlice = createSlice({
  name: "web3TokenInfoSlice",
  initialState,
  reducers: {
    initiate(state) {
      state.tokenIdMap = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncGetAllTokenInfo.pending, (state, action) => {
        state.getTokenInfoState = 1;
      })
      .addCase(asyncGetAllTokenInfo.fulfilled, (state, action) => {
        state.tokenIdArray = action.payload.tokenIdArray;
        state.tokenIdsInWallet = action.payload.tokenIdsInWallet;
        state.burnRedeemInfo = action.payload.burnRedeemInfo;
        state.bonusBurnRedeemInfo = action.payload.bonusBurnRedeemInfo;
        state.archiveData = action.payload.archiveData;
        console.log("test",action.payload.archiveData);
        state.bonusArchiveData = action.payload.bonusArchiveData;
        state.getTokenInfoState = 2;
        console.log("asyncGetAllTokenInfo", action.payload);
      })
      .addCase(asyncGetAllTokenInfo.rejected, (state, action) => {
        state.getTokenInfoState = 3;
        console.log("rejected",action.payload);
      });
  },
});

const asyncGetAllTokenInfo = createAsyncThunk(
  "web3CountSlice/asyncGetAllTokenInfo",
  async (params, { rejectWithValue, getState }) => {
    //const dispatch = useDispatch();

    const { account, provider } = params;
    const { server } = getState();
    const showData = server.BTAShowData;
    const bonusShowData = server.bonusShowData;
    const isBonusOpen = server.isBonusOpen;
    const archiveData = JSON.parse(JSON.stringify(server.rawArchiveData));
    const bonusArchiveData = JSON.parse(JSON.stringify(server.bonusRawArchiveData));
    console.log("provider", provider);
    const web3 = new Web3(provider);
    let ABI, ContractAddress, StartBlock, EndBlock, archiveStartId;
    if (process.env.REACT_APP_ENV_VER === "DEV") {
      ABI = TestBaseContractInfo.ABI;
      ContractAddress = TestBaseContractInfo.ContractAddress;
      StartBlock = TestBaseContractInfo.StartBlock;
      archiveStartId = TestBaseContractInfo.ClaimTokenCount;
      const alchemyRes = await axios.post(
        "https://eth-goerli.g.alchemy.com/v2/" +
          process.env.REACT_APP_ALCHEMY_API_KEY,
        {
          jsonrpc: "2.0",
          id: 0,
          method: "eth_getBlockByNumber",
          params: ["latest", false],
        }
      );
      EndBlock = parseInt(alchemyRes.data.result.number, 16);
      console.log("EndBlock : ", EndBlock);
    } else {
      ABI = BaseContractInfo.ABI;
      ContractAddress = BaseContractInfo.ContractAddress;
      StartBlock = BaseContractInfo.StartBlock;
      archiveStartId = BaseContractInfo.ClaimTokenCount;
      EndBlock = await web3.eth.getBlockNumber();
    }
    console.log("ContractAddress", ContractAddress);
    console.log("StartBlock", StartBlock);
    var contract = new web3.eth.Contract(ABI, ContractAddress);
    let tokenIdMap = new Map();
    let result = {};
    try {
      await readEventsRange(StartBlock, EndBlock);
      result.tokenIdArray = convertMapToArray(tokenIdMap);
      result.tokenIdsInWallet = getTokenIdsInWallet(
        result.tokenIdArray,
        account
      );
      
      result.burnRedeemInfo = await getBurnRedeemInfo(
        showData.publicData.extensionAddress,
        ContractAddress,
        showData.id,
        provider
      );
      
      if(isBonusOpen){
        result.bonusBurnRedeemInfo = await getBurnRedeemInfo(
          bonusShowData.publicData.extensionAddress,
          ContractAddress,
          bonusShowData.id,
          provider
        );
      }

      for (let chapter = 0; chapter < 6; chapter++) {
        for (let stage = 0; stage < archiveData[chapter].length; stage++) {
          const rawData = await getBurnRedeemInfo(
            archiveData[chapter][stage].publicData.extensionAddress,
            ContractAddress,
            archiveData[chapter][stage].id,
            provider
          );

          let data = JSON.parse(JSON.stringify(rawData));
          archiveStartId++;
          data.startId = archiveStartId;
          if(Number(data[2]) > 0){
            data.finishId = archiveStartId + Number(data[2]) - 1;
            archiveStartId = data.finishId;
          }else{
            data.finishId = archiveStartId;
            archiveStartId--;
          }
          console.log("Slice data.finishId : ",data.finishId);
          archiveData[chapter][stage].burnRedeemInfo = data;
        }
      }
      result.archiveData = archiveData;

      for (let stage = 0; stage < bonusArchiveData[4].length; stage++) {
        const rawData = await getBurnRedeemInfo(
          bonusArchiveData[4][stage].publicData.extensionAddress,
          ContractAddress,
          bonusArchiveData[4][stage].id,
          provider
        );

        let data = JSON.parse(JSON.stringify(rawData));
        archiveStartId++;
        data.startId = archiveStartId;
        if (Number(data[2]) > 0) {
          data.finishId = archiveStartId + Number(data[2]) - 1;
          archiveStartId = data.finishId;
        } else {
          data.finishId = archiveStartId;
          archiveStartId--;
        }
        console.log("Slice data.finishId : ", data.finishId);
        bonusArchiveData[4][stage].burnRedeemInfo = data;
      }
      result.bonusArchiveData = bonusArchiveData;

      return result;
    } catch (error) {
      return rejectWithValue(error);
    }

    async function readEventsRange(start, end) {
      return new Promise(function (resolve, reject) {
        (async function () {
          try {
            contract.getPastEvents(
              "Transfer",
              { fromBlock: start, toBlock: end },
              async function (errors, events) {
                if (!errors) {
                  for (var i = 0; i < events.length; i++) {
                    tokenIdMap.set(
                      events[i].returnValues.tokenId,
                      events[i].returnValues.to
                    );
                  }
                  resolve();
                } else {
                  if (
                    errors.message.includes(
                      "query returned more than 10000 results"
                    ) ||
                    errors.message.includes("block range is too wide")
                  ) {
                    const middle = Math.round((start + end) / 2);
                    console.log(
                      "reset start : ",
                      start + "middle :" + middle + "end : " + end
                    );
                    await readEventsRange(start, middle);
                    await readEventsRange(middle + 1, end);
                    resolve();
                  } else {
                    reject("uncaught error!");
                  }
                }
              }
            );
          } catch (error) {
            console.log("here");
          }
        })();
      });
    }
  }
);

function convertMapToArray(tokenIdMap) {
  let resultArray = [];
  resultArray.push("0x00");
  for (let i = 1; i <= tokenIdMap.size; i++) {
    resultArray.push(tokenIdMap.get(String(i)));
  }
  return resultArray;
}

function getTokenIdsInWallet(tokenIdArray, address) {
  let resultArray = [];
  for (let i = 1; i < tokenIdArray.length; i++) {
    if (tokenIdArray[i] === address) resultArray.push(i);
  }
  return resultArray;
}

async function getBurnRedeemInfo(extensionAddress, creatorContractAddress, instanceId, provider) {
  const web3 = new Web3(provider);
  let ABI, ContractAddress;
  if (process.env.REACT_APP_ENV_VER === "DEV") {
    ABI = TestBurnRedeemContractInfo.ABI;
    ContractAddress = extensionAddress;
  } else {
    ABI = BurnRedeemContractInfo.ABI;
    ContractAddress = extensionAddress;
  }

  const contract = new web3.eth.Contract(ABI, ContractAddress);
  let result = await contract.methods
    .getBurnRedeem(creatorContractAddress, instanceId)
    .call();
  console.log("getBurnRedeemInfo instanceId : ", instanceId);
  console.log("getBurnRedeemInfo", result);
  return result;
}

export { asyncGetAllTokenInfo };
export const web3TokenInfoActions = web3TokenInfoSlice.actions;
export default web3TokenInfoSlice.reducer;
