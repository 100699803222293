import styled from "styled-components";
import { networkID } from '../../../lib/connectors';

const TestInformationEl = styled.div`
  position: absolute;
  left: 100px;
  top: 100px;
  z-index: 1000;
  background-color: white;
  display: flex;
  flex-direction: column;
`;

function TestInformation({settingData}) {
  return (
    <TestInformationEl>
      <span>
        <b>TEST INFORMATION</b>
      </span>
      <span>REACT_APP_SETTING_DATA : {process.env.REACT_APP_SETTING_DATA}</span>
      <span>
        REACT_APP_MERKLE_BASE_URL : {process.env.REACT_APP_MERKLE_BASE_URL}
      </span>
      <span>networkID : {networkID}</span>
      <span>REACT_APP_RPC : {process.env.REACT_APP_RPC}</span>
      <span>REACT_APP_INFURA_KEY : {process.env.REACT_APP_INFURA_KEY}</span>
      <span>
        REACT_APP_CLAIM_EXTENSION_ADDRESS :{" "}
        {process.env.REACT_APP_CLAIM_EXTENSION_ADDRESS}
      </span>
      <span>REACT_APP_OPENSEA_URL : {process.env.REACT_APP_OPENSEA_URL}</span>
      <textarea rows={30}>{JSON.stringify(settingData, undefined, 4)}</textarea>
    </TestInformationEl>
  );
}
export default TestInformation;
