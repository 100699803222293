import styled from "styled-components";
import { Colors, ScreenSizes, Sizes } from "../styles/Themes";
import { useSelector } from "react-redux";

const MainContentsEl = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: calc(120px + ${Sizes.HeaderHeight});
  padding-bottom: 40px;
  @media (max-width: ${ScreenSizes.mobile}) {
    padding-top: calc(40px + ${Sizes.MobileHeaderHeight});
  }
  filter: ${(props) => (props.blur ? "blur(2px);" : "none;")};
`;

const TextContentsEl = styled.div`
  max-width: 1024px;
  padding: 0px 320px 0px 320px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 0px 16px 0px 16px;
    gap: 16px;
  }
`;

const TextBox = styled.p`
  font-family: NanumSquare;
  color: ${Colors.wt};
  font-size: 20px;
  line-height: 140%;
  font-weight: 300;
  & > a {
    color: ${Colors.wt};
    font-weight: 400;
  }
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 14px;
  }
`;

const TextBoxHighlight = styled.span`
  font-family: NanumSquare;
  color: ${props => props.theme.highlight};
  font-size: 20px;
  line-height: 140%;
  font-weight: 300;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 14px;
  }
`;

const TextBoxHighlightBold = styled.p`
  font-family: NanumSquare;
  color: ${props => props.theme.highlight};
  font-size: 20px;
  line-height: 140%;
  font-weight: 800;
  width: 100%;
  text-align: center;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 14px;
  }
`;

const TextBoxSignature = styled.p`
  font-family: NanumSquare;
  color: ${Colors.wt};
  font-size: 20px;
  line-height: 140%;
  font-weight: 300;
  width: 100%;
  text-align: center;
  & > a {
    font-weight: 700;
    color: ${Colors.wt};
  }
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 14px;
  }
`;

const ProfileEl = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 70%;
  overflow: hidden;
`;

const ProfileImg = styled.img`
  width: 100%;
  height: auto;
`;

const AboutMiddleImg = styled.img`
  width: 100%;
  height: auto;
`;

const AboutVideo = styled.video`
  width: 100%;
  height: auto;
`;

function AboutPage() {
  const isBlur = useSelector((state) => state.component.isBlur);
  return (
    <MainContentsEl blur={isBlur}>
      <TextContentsEl>
        <TextBox>
          <TextBoxHighlight>"Burning Souls"</TextBoxHighlight> began with the{" "}
          <a
            href="https://mirror.xyz/ghostsproject.eth/JFfI9m4fG2nfb6S_ra3TSTtG3U1UXw1C5I2WT0iuYnU"
            target="_blank"
            rel="noreferrer"
          >
            idea
          </a>{" "}
          of rewarding GhostsProject members with special artworks. While I had
          planned to release 12-24 artworks in the collection, I ended up
          expanding to around <TextBoxHighlight>100-130.</TextBoxHighlight> As a
          result, I’ve decided to open Burning Souls up to all of my previous
          collectors, and not just for GhostsProject collectors.
        </TextBox>
        <TextBox>
          The world and its history envelop each character’s existence, while
          the characters’ individual stories weave and intertwine with one
          another to form the promise of a world larger than what can initially
          be perceived by the beholder. Sometimes the camera might zoom in on
          humans doing good; other times, it might zoom in and catch them doing
          something sinister. They are also often seen contradicting themselves.
        </TextBox>
        {/* <AboutMiddleImg src={"/images/about_middle_image.gif"} /> */}
        <AboutVideo autoPlay muted loop playsInline>
          <source
            src={"https://burningsouls.com/public/videos/infographic_1024px.mp4"}
            type="video/mp4"
          />
        </AboutVideo>
        <TextBox>
          All of MrMisang’s NFT works from the past three years share the same
          world. This world that I’ve built — I wonder how it will turn out 10
          years from now.
        </TextBox>
        <TextBox>
          When you acquire a Burning Soul, what you’re getting is simply an <TextBoxHighlight>NFT
          artwork</TextBoxHighlight> of mine recorded on the Ethereum blockchain. Most pieces in
          the collection will be made available for you to collect through
          Manifold’s Burn & Redeem function, while others will be acquirable
          through different methods. <TextBoxHighlight>After all pieces are revealed, the project
          will come to its end.</TextBoxHighlight> Only those that find these terms acceptable
          should participate in this project.
        </TextBox>
        <TextBoxHighlightBold>
          "Keep, burn, or sell. The choice is yours."
        </TextBoxHighlightBold>
        <TextBoxSignature>
          From his studio in Korea,
          <br />
          <a
            href="https://twitter.com/mrmisang"
            target="_blank"
            rel="noreferrer"
          >
            MrMisang
          </a>
        </TextBoxSignature>
      </TextContentsEl>
      <ProfileEl>
        <ProfileImg src={"/images/MrMisang_Profile_Loop.gif"} />
      </ProfileEl>
    </MainContentsEl>
  );
}
export default AboutPage;
