import styled from "styled-components";
import { Colors, ScreenSizes } from "../../styles/Themes";

const TitleEl = styled.div`
  height: 171px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @media (max-width: ${ScreenSizes.tablet}) {
    height: 148px;
  }
  @media (max-width: ${ScreenSizes.mobile}) {
    height: 63px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const TitleText = styled.p`
  font-family: NanumSquare;
  font-size: 64px;
  line-height: 160%;
  font-weight: 400;
  margin: 0;
  color: ${props => props.theme.highlight};
  text-align: center;
  @media (max-width: ${ScreenSizes.tablet}) {
    font-size: 48px;
  }
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 26px;
  }
`;

const SubTitleText = styled.p`
  color: ${props => props.theme.highlight};
  font-family: NanumSquare;
  font-size: 40px;
  line-height: 132%;
  font-weight: 400;
  margin: 0;
  color: ${props => props.theme.highlight};
  text-align: center;
  @media (max-width: ${ScreenSizes.tablet}) {
    font-size: 30px;
  }
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 16px;
  }
`;

function Title() {
  return (
    <TitleEl>
      <TitleText >Keep, burn, or sell.</TitleText>
      <SubTitleText >The choice is yours.</SubTitleText>
    </TitleEl>
  );
}
export default Title;
