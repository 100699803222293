import styled from "styled-components";
import { useState } from "react";
import { Colors, ScreenSizes } from "../../../styles/Themes";
import { useDispatch, useSelector } from "react-redux";
import { componentActions } from "../../../store/componentSlice";
import { serverActions } from "../../../store/serverSlice";
import { useEffect } from "react";
import BurnQuantitySelect from "./BurnQuantitySelect/BurnQuantitySelect";
import ConfirmBurn from "./ConfirmBurn/ConfirmBurn";
import SetApprovalForAll from "./SetApprovalForAll/SetApprovalForAll";
import BurnLoading from "./BurnLoading/BurnLoading";
import BurnSuccess from "./BurnSuccess/BurnSuccess";
import BurnError from "./BurnError/BurnError";
import CheckSetApprovedForAll from "./SetApprovalForAll/CheckSetApprovedForAll";
import { web3BurnActions } from "../../../store/web3BurnSlice";

const ModalBackgroundEl = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 500;
  display: flex;
  justify-content: center;
  overflow: scroll;
  align-items: flex-start;
  padding-top: 50px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  @media (max-width: ${ScreenSizes.mobile}) {
    overflow: scroll;
    align-items: flex-start;
    padding-top: 20px;
  }
`;

const BaseBurnEl = styled.div`
  background-color: ${Colors.gr02};
  border: 1px solid ${Colors.wt};
  color: ${Colors.wt};
  font-family: NanumSquare;
  width: 608px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 280px;
    height: 725px;
  }
`;

const TitleEl = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.wt};
  padding: 24px 32px 24px 32px;
  box-sizing: border-box;
  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 12px 16px 12px 16px;
  }
`;

const Title = styled.span`
  font-size: 24px;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 18px;
  }
`;

const CloseIcon = styled.img`
  ${(props) => props.isMinting && "visibility: hidden;"}
  cursor: pointer;
`;

const ClaimEl = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
`;

function BaseBurnModal() {
  const [redeemQuantity, setRedeemQuantity] = useState(1);
  const dispatch = useDispatch();
  const isProcessing = useSelector((state) => state.component.isProcessing);
  const maxRedeemCount = useSelector((state) => state.server.maxRedeemCount);
  const burnModalStep = useSelector((state) => state.component.burnModalStep);
  const [fees, setFees] = useState({
    burnFee: "0",
    manifoldFee: "0",
    totalFee: "0",
  });
  const tokenIdsInWallet = useSelector(
    (state) => state.web3TokenInfo.tokenIdsInWallet
  );
  const burnRedeemInfo = useSelector(
    (state) => state.web3TokenInfo.burnRedeemInfo
  );
  const showData = useSelector((state) => state.server.showData);
  const isMultiBurn = useSelector((state) => state.server.isMultiBurn);

  function openModalHandler(isOpen) {
    dispatch(componentActions.setIsBurnModalOpen(isOpen));
    dispatch(componentActions.setIsBlur(isOpen));
    dispatch(web3BurnActions.initState());
  }

  useEffect(() => {
    dispatch(componentActions.setBurnModalStep(0));
    dispatch(componentActions.setIsProcessing(false));
    dispatch(serverActions.getMaxRedeemCount(tokenIdsInWallet));
  }, []);

  return (
    <ModalBackgroundEl>
      <BaseBurnEl>
        <TitleEl>
          <Title>Acquire New Soul</Title>
          <CloseIcon
            isMinting={isProcessing}
            src={"/icons/cross_modal.svg"}
            alt={"close button"}
            onClick={(e) => {
              openModalHandler(false, e);
            }}
          />
        </TitleEl>
        <ClaimEl>
          {burnModalStep === 0 && (
            <BurnQuantitySelect
              showData={showData}
              redeemQuantity={redeemQuantity}
              setRedeemQuantity={setRedeemQuantity}
              maxRedeemCount={maxRedeemCount}
              burnRedeemInfo={burnRedeemInfo}
            />
          )}
          {burnModalStep === 1 && (
            <ConfirmBurn
              showData={showData}
              isMultiBurn={isMultiBurn}
              redeemQuantity={redeemQuantity}
              fees={fees}
              setFees={setFees}
            />
          )}
          {burnModalStep === 2 && <CheckSetApprovedForAll />}
          {burnModalStep === 3 && <SetApprovalForAll />}
          {burnModalStep === 4 && (
            <BurnLoading redeemQuantity={redeemQuantity} fee={fees.totalFee} />
          )}
          {burnModalStep === 5 && (
            <BurnSuccess
              showData={showData}
              redeemQuantity={redeemQuantity}
              openModalHandler={openModalHandler}
            />
          )}
          {burnModalStep === 6 && (
            <BurnError openModalHandler={openModalHandler} />
          )}
        </ClaimEl>
      </BaseBurnEl>
    </ModalBackgroundEl>
  );
}
export default BaseBurnModal;
