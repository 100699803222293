import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import Web3 from "web3";
import BurnRedeemContractInfo from "../contracts/ERC721BurnRedeem.json";
import BatchBurnRedeemContractInfo from "../contracts/ERC721BatchBurnRedeem.json";
import TestBurnRedeemContractInfo from "../contracts/TestERC721BurnRedeem.json";
import TestBatchBurnRedeemContractInfo from "../contracts/TestERC721BatchBurnRedeem.json";
import BaseContractInfo from "../contracts/BaseContractInfo.json";
import TestBaseContractInfo from "../contracts/TestBaseContractInfo.json";
import { ethers } from "ethers";

//  STATUS VALUE
//  0 : WAIT
//  1 : LOADING
//  2 : FINISH
//  3 : ERROR
const initialState = {
  isApprovedForAllStatus: 0,
  isApprovedForAll: false,
  setApprovalForAllStatus: 0,
  setApprovalForAll: false,
  burnRedeemStatus: 0,
  redeemTokenId: undefined,
};

const web3BurnSlice = createSlice({
  name: "web3BurnSlice",
  initialState,
  reducers: {
    initState(state) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncWeb3IsApprovedForAll.pending, (state) => {
        console.log("asyncWeb3IsApprovalForAll", "loading");
        state.isApprovedForAllStatus = 1;
      })
      .addCase(asyncWeb3IsApprovedForAll.fulfilled, (state, action) => {
        state.isApprovedForAll = action.payload;
        state.isApprovedForAllStatus = 2;
        console.log("asyncWeb3IsApprovalForAll", state.isApprovedForAll);
      })
      .addCase(asyncWeb3IsApprovedForAll.rejected, (state, action) => {
        state.isApprovedForAllStatus = 3;
        console.log("asyncWeb3IsApprovalForAll Error", action.payload);
      });

    builder
      .addCase(asyncWeb3SetApprovalForAll.pending, (state) => {
        console.log("asyncWeb3SetApprovalForAll", "loading");
        state.setApprovalForAllStatus = 1;
      })
      .addCase(asyncWeb3SetApprovalForAll.fulfilled, (state, action) => {
        state.setApprovalForAll = action.payload;
        state.isApprovedForAll = true;
        state.setApprovalForAllStatus = 2;
        console.log("asyncWeb3SetApprovalForAll", state.isApprovedForAll);
      })
      .addCase(asyncWeb3SetApprovalForAll.rejected, (state, action) => {
        state.setApprovalForAllStatus = 3;
        console.log("asyncWeb3IsApprovalForAll Error", action.payload);
      });

    builder
      .addCase(asyncWeb3BurnRedeem.pending, (state) => {
        console.log("asyncWeb3BurnRedeem", "loading");
        state.burnRedeemStatus = 1;
      })
      .addCase(asyncWeb3BurnRedeem.fulfilled, (state, action) => {
        const txResult = action.payload.txResult;
        const redeemQuantity = action.payload.redeemQuantity;
        const rawBurnSetLenght = action.payload.rawBurnSetLenght
        console.log("txResult", txResult);
        const hexTokenId = txResult.events[String(redeemQuantity*(rawBurnSetLenght+1)*2-1)].raw["topics"][3];
        const resultTokenId = parseInt(hexTokenId);
        state.redeemTokenId = resultTokenId;
        state.burnRedeemStatus = 2;
        console.log("asyncWeb3BurnRedeem", state.redeemTokenId);
      })
      .addCase(asyncWeb3BurnRedeem.rejected, (state, action) => {
        state.burnRedeemStatus = 3;
        console.log("asyncWeb3BurnRedeem Error", action.payload);
      });
  },
});

const asyncWeb3IsApprovedForAll = createAsyncThunk(
  "web3BurnSlice/asyncWeb3IsApprovedForAll",
  async (params, { getState, rejectWithValue }) => {
    const { owner, provider } = params;
    const { server } = getState();
    const web3 = new Web3(provider);

    let BaseContract;
    let BurnRedeemContractAddress;

    if (process.env.REACT_APP_ENV_VER === "DEV") {
      BaseContract = new web3.eth.Contract(
        TestBaseContractInfo.ABI,
        TestBaseContractInfo.ContractAddress
      );
    } else {
      BaseContract = new web3.eth.Contract(
        BaseContractInfo.ABI,
        BaseContractInfo.ContractAddress
      );
    }

    const bonusState = server.bonusShowStage.state;
    if(bonusState !== 1){
      BurnRedeemContractAddress = server.showData.publicData.extensionAddress;
    }else{
      BurnRedeemContractAddress = server.bonusShowData.publicData.extensionAddress;
    }

    try {
      let result = await BaseContract.methods
        .isApprovedForAll(owner, BurnRedeemContractAddress)
        .call();
      console.log(result);
      return result;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const asyncWeb3SetApprovalForAll = createAsyncThunk(
  "web3BurnSlice/asyncWeb3SetApprovalForAll",
  async (params, { getState, rejectWithValue }) => {
    const { account, provider } = params;
    const { server } = getState();
    const web3 = new Web3(provider);

    let BaseContract;
    let BurnRedeemContractAddress;
    if (process.env.REACT_APP_ENV_VER === "DEV") {
      BaseContract = new web3.eth.Contract(
        TestBaseContractInfo.ABI,
        TestBaseContractInfo.ContractAddress
      );
    } else {
      BaseContract = new web3.eth.Contract(
        BaseContractInfo.ABI,
        BaseContractInfo.ContractAddress
      );
    }

    const bonusState = server.bonusShowStage.state;
    if(bonusState !== 1){
      BurnRedeemContractAddress = server.showData.publicData.extensionAddress;
    }else{
      BurnRedeemContractAddress = server.bonusShowData.publicData.extensionAddress;
    }

    try {
      let result = await BaseContract.methods
        .setApprovalForAll(BurnRedeemContractAddress, true)
        .send({ from: account });
      console.log(result);
      return result;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const asyncWeb3BurnRedeem = createAsyncThunk(
  "web3BurnSlice/asyncWeb3BurnRedeem",
  async (params, { getState, rejectWithValue }) => {
    const { redeemQuantity, account, provider, fee } = params;
    const { server, web3TokenInfo } = getState();
    const web3 = new Web3(provider);

    let BurnRedeemContract;
    let BatchBurnRedeemContract;
    let BaseContractAddress;
    let BurnRedeemContractAddress;

    const bonusState = server.bonusShowStage.state;
    if(bonusState !== 1){
      BurnRedeemContractAddress = server.showData.publicData.extensionAddress;
    }else{
      BurnRedeemContractAddress = server.bonusShowData.publicData.extensionAddress;
    }

    if (process.env.REACT_APP_ENV_VER === "DEV") {
      BurnRedeemContract = new web3.eth.Contract(
        TestBurnRedeemContractInfo.ABI,
        TestBurnRedeemContractInfo.ContractAddress
      );
      BatchBurnRedeemContract = new web3.eth.Contract(
        TestBatchBurnRedeemContractInfo.ABI,
        TestBatchBurnRedeemContractInfo.ContractAddress
      );
      BaseContractAddress = TestBaseContractInfo.ContractAddress;
    } else {
      BurnRedeemContract = new web3.eth.Contract(
        BurnRedeemContractInfo.ABI,
        BurnRedeemContractAddress
      );
      BatchBurnRedeemContract = new web3.eth.Contract(
        BatchBurnRedeemContractInfo.ABI,
        BurnRedeemContractAddress
      );
      BaseContractAddress = BaseContractInfo.ContractAddress;
    }

    const rawBurnSet = server.rawBurnSet;
    const bonusRawBurnSet = server.bonusRawBurnSet;
    const tokenIdsInWallet = web3TokenInfo.tokenIdsInWallet;
    let instanceId;
    let burnTokenSet;
    if(bonusState !== 1){
      burnTokenSet = createBurnTokenSet(rawBurnSet, tokenIdsInWallet);
      instanceId = server.showData.id;
    }else{
      burnTokenSet = createBurnTokenSet(bonusRawBurnSet, tokenIdsInWallet);
      instanceId = server.bonusShowData.id;
    }

    if (redeemQuantity === 1) {
      try {
        let result = {}
        let txResult = await BurnRedeemContract.methods
          .burnRedeem(
            BaseContractAddress,
            instanceId,
            redeemQuantity,
            burnTokenSet
          )
          .send({ from: account, value: fee });
        result.rawBurnSetLenght = rawBurnSet.length;
        result.txResult = txResult;
        result.redeemQuantity = redeemQuantity;
        return result;
      } catch (e) {
        return rejectWithValue(e);
      }
    } else {
      //Batch BurnRedeem
      let addresses = [];
      let ids = [];
      let burnTokenSetArray = [];
      let quantityArray = [];
      let tempTokenIdsInWallet = [...tokenIdsInWallet];
      for (let i = 0; i < redeemQuantity; i++) {
        addresses.push(BaseContractAddress);
        ids.push(instanceId);
        quantityArray.push(1);
        if(bonusState !== 1){
          burnTokenSetArray.push(
            createBatchBurnTokenSet(rawBurnSet, tempTokenIdsInWallet)
          );
        }else{
          burnTokenSetArray.push(
            createBatchBurnTokenSet(bonusRawBurnSet, tempTokenIdsInWallet)
          );
        }
      }
      try {
        let result = {}
        let txResult = await BatchBurnRedeemContract.methods
          .burnRedeem(
            addresses,
            ids,
            quantityArray,
            burnTokenSetArray
          )
          .send({ from: account, value: fee });
        result.rawBurnSetLenght = rawBurnSet.length;
        result.txResult = txResult;
        result.redeemQuantity = redeemQuantity;
        return result;
      } catch (e) {
        return rejectWithValue(e);
      }
    }
  }
);

function createBurnTokenSet(rawBurnSet, tokenIdsInWallet) {
  let tokenIds = [...tokenIdsInWallet];
  const initialSet = {
    groupIndex: 0,
    itemIndex: 0,
    contractAddress: "",
    id: 0,
    merkleProof: [ethers.utils.formatBytes32String("")],
  };
  let resultArray = [];
  for (let i = 0; i < rawBurnSet.length; i++) {
    const contractAddress = rawBurnSet[i].items[0].contractAddress;
    const burnId = findId(
      tokenIds,
      rawBurnSet[i].items[0].minTokenId,
      rawBurnSet[i].items[0].maxTokenId
    );
    console.log("findId", burnId);
    initialSet.groupIndex = i;
    initialSet.itemIndex = 0;
    initialSet.contractAddress = contractAddress;
    initialSet.id = burnId;
    initialSet.merkleProof = [ethers.utils.formatBytes32String("")];

    const newSet = JSON.parse(JSON.stringify(initialSet));
    resultArray.push(newSet);
  }

  console.log("resultArray", JSON.stringify(resultArray));
  return resultArray;
}

function createBatchBurnTokenSet(rawBurnSet, tokenIdsInWallet) {
  console.log("createBatchBurnTokenSet", tokenIdsInWallet);
  const initialSet = {
    groupIndex: 0,
    itemIndex: 0,
    contractAddress: "",
    id: 0,
    merkleProof: [ethers.utils.formatBytes32String("")],
  };
  let resultArray = [];
  for (let i = 0; i < rawBurnSet.length; i++) {
    const contractAddress = rawBurnSet[i].items[0].contractAddress;
    const burnId = findId(
      tokenIdsInWallet,
      rawBurnSet[i].items[0].minTokenId,
      rawBurnSet[i].items[0].maxTokenId
    );
    console.log("findId", burnId);
    initialSet.groupIndex = i;
    initialSet.itemIndex = 0;
    initialSet.contractAddress = contractAddress;
    initialSet.id = burnId;
    initialSet.merkleProof = [ethers.utils.formatBytes32String("")];

    const newSet = JSON.parse(JSON.stringify(initialSet));
    resultArray.push(newSet);
  }

  console.log("resultArray", JSON.stringify(resultArray));
  return resultArray;
}

function findId(tokenIds, minTokenId, maxTokenId) {
  console.log("findId", tokenIds);
  for (let i = 0; i < tokenIds.length; i++) {
    if (minTokenId <= tokenIds[i] && tokenIds[i] <= maxTokenId) {
      const id = tokenIds[i];
      tokenIds.splice(i, 1);
      console.log("findId.splice", tokenIds);
      return id;
    }
  }
}

export {
  asyncWeb3IsApprovedForAll,
  asyncWeb3SetApprovalForAll,
  asyncWeb3BurnRedeem,
};
export const web3BurnActions = web3BurnSlice.actions;
export default web3BurnSlice.reducer;
