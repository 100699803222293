import styled from "styled-components";
import { Colors, ScreenSizes } from "../../styles/Themes";
const TitleEl = styled.span`
  color: ${Colors.wt};
  font-family: NanumSquare;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 30px */
  letter-spacing: -0.2px;
  @media (max-width: ${ScreenSizes.mobile}) {
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    letter-spacing: -0.15 px;
  }
`;
function ChapterTitle({ showData }) {
  const ChapterTitleList = [
    "Chapter 1 : The Survivors",
    "Chapter 2 : The Adaptations",
    "Chapter 3 : The Carnival",
    "Chapter 4 : ",
    "Bonus Chapter"
  ];
  return <TitleEl>{ChapterTitleList[showData.chapter - 1]}</TitleEl>;
}
export default ChapterTitle;
