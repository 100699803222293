import styled from "styled-components";
import { Colors, ScreenSizes } from "../../../../styles/Themes";
import { useSelector } from "react-redux";

const EndStageRedeemQuantityEl = styled.div`
  width: 560px;
  height: auto;
  padding: 16px 24px;
  border-radius: 8px;
  background-color: ${Colors.gr700};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 262px;
    height: auto;
    flex-direction: column;
    padding: 12px 6px;
  }
`;

const EndStageRedeemItemEl = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 250px;
    height: auto;
    flex-direction: row-reverse;
    border-radius: 2px;
    border: 1px solid ${(props) => props.theme.highlight};
    gap: 0px;
  }
`;

const RedeemItemExplainEl = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const RedeemItemName = styled.div`
  width: 100%;
  color: ${Colors.wt};
  font-size: 24px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 106px;
    padding: 0px 16px;
    box-sizing: border-box;
    font-size: 16px;
  }
`;

const RedeemItemCount = styled.span`
  color: ${Colors.gr200};
  font-size: 14px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;

  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 11px;
  }
`;

const RedeemItemVideo = styled.video`
  width: 100%;
  height: auto;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 144px;
    height: auto;
  }
`;

const QuantityEl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const QuantityExplainText = styled.span`
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.14px;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 11px;
    font-weight: 700;
    letter-spacing: -0.11px;
  }
`;

const QuantitySelectEl = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const QuantityButton = styled.img`
  cursor: pointer;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 24px;
    height: auto;
  }
`;

const QuantityText = styled.span`
  width: 45px;
  height: 28px;
  border: 1px solid ${Colors.gr07};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  @media (max-width: ${ScreenSizes.mobile}) {
  }
`;
function BTAEndStageRedeemQuantity({
  showData,
  redeemQuantity,
  setRedeemQuantity,
  burnRedeemInfo,
}) {
  function quantityHandler(type) {
    if (type === "plus") {
      if (
        Number(burnRedeemInfo.redeemedCount) + Number(redeemQuantity) <
        Number(burnRedeemInfo.totalSupply)
      ) {
        setRedeemQuantity(redeemQuantity + 1);
      }
    } else {
      if (redeemQuantity > 1) {
        setRedeemQuantity(redeemQuantity - 1);
      }
    }
  }
  return (
    <EndStageRedeemQuantityEl>
      <EndStageRedeemItemEl>
        <RedeemItemExplainEl>
          <RedeemItemName>{showData.publicData.redeemTokenName}</RedeemItemName>
          <RedeemItemCount>
            {burnRedeemInfo.redeemedCount}
            {" "}Claimed
          </RedeemItemCount>
        </RedeemItemExplainEl>
        <RedeemItemVideo autoPlay muted loop playsInline>
          <source src={showData.publicData.redeemAssetMedia} type="video/mp4" />
        </RedeemItemVideo>
      </EndStageRedeemItemEl>
    </EndStageRedeemQuantityEl>
  );
}
export default BTAEndStageRedeemQuantity;
