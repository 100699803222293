import { configureStore } from "@reduxjs/toolkit";

import web3Reducer from "./web3Slice";
import componentReducer from "./componentSlice";
import serverReducer from "./serverSlice";
import web3ClaimReducer from "./web3ClaimSlice";
import web3MintReducer from "./web3MintSlice";
import web3TokenInfoReducer from './web3TokenInfoSlice';
import web3BurnReducer from './web3BurnSlice';

const store = configureStore({
  reducer: {
    web3: web3Reducer,
    component: componentReducer,
    server: serverReducer,
    web3Claim: web3ClaimReducer,
    web3Burn: web3BurnReducer,
    web3Mint: web3MintReducer,
    web3TokenInfo: web3TokenInfoReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
