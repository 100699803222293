import { createSlice } from "@reduxjs/toolkit";

const initialWeb3State = {
  balanceOf: 0,
};

const web3Slice = createSlice({
  name: "web3",
  initialState: initialWeb3State,
  reducers: {
    setBalanceOf(state, action) {
      // console.log("balanceOf",action.payload);
      state.balanceOf = action.payload;
    },
  },
});

export const web3Actions = web3Slice.actions;
export default web3Slice.reducer;
