import styled from "styled-components";
import { Colors, ScreenSizes } from "../../styles/Themes";
import BurnItem from "./BurnItem";
import RedeemItem from "./RedeemItem";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const BackgroundEl = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.gr02};
  height: auto;
  padding: 24px 32px 24px 32px;
  gap: 24px;
  box-sizing: border-box;
  @media (max-width: ${ScreenSizes.mobile}) {
    flex-direction: column-reverse;
    padding: 16px 10px;
    gap: 6px;
  }
`;

const BurnEl = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  @media (max-width: ${ScreenSizes.mobile}) {
    gap: 2px;
  }
`;

const ArrowImg = styled.img`
  width: 44px;
  height: auto;
  transform: rotate(90deg);
  @media (max-width: ${ScreenSizes.mobile}) {
    transform: rotate(0deg);
    gap: 2px;
  }
`;

function BurnRedeemArea() {
  const showData = useSelector((state) => state.server.showData);
  const burnRedeemInfo = useSelector((state) => state.web3TokenInfo.burnRedeemInfo);
  useEffect(()=>{
    console.log("BurnRedeemArea",showData);
  },[]);
  return (
    <>
      {showData !== undefined && (
        <BackgroundEl>
          <BurnEl>
            {showData.publicData.burnSet.map((burnSet, index) => {
              return (
                <BurnItem
                  burnSet={burnSet}
                  burnCount={showData.publicData.burnSet.length}
                  key={index}
                />
              );
            })}
          </BurnEl>
          <ArrowImg src={"/images/burn_arrow.svg"} />
          <RedeemItem showData={showData} burnCount={showData.publicData.burnSet.length} burnRedeemInfo={burnRedeemInfo}/>
        </BackgroundEl>
      )}
    </>
  );
}
export default BurnRedeemArea;
