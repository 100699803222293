import styled from "styled-components";
import { Colors, ScreenSizes } from "../../../styles/Themes";
import RedeemItem from "../../burnredeem/RedeemItem";
import { useSelector } from "react-redux";

const ExternalBurnAreaEl = styled.div`
  background-color: ${Colors.gr800};
  padding: 24px 32px;
  border-radius: 8px;
  display: flex;
  gap: 24px;
  align-items: center;
  @media (max-width: ${ScreenSizes.mobile}) {
    flex-direction: column-reverse;
    padding: 16px 8px;
    gap: 6px;
  }
`;

const ExplainEl = styled.div`
  display: flex;
  flex-direction: column;
  width: 296px;
  gap: 50px;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 263px;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
`;

const Title = styled.span`
  color: #fff;
  font-size: 24px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  @media (max-width: ${ScreenSizes.mobile}) {
text-align: center;
font-size: 16px;
  }
`;

const MobileBr = styled.br`
  display: none;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: inline-block;
  }
`;

const SubTitle = styled.span`
  color: #fff;
  font-size: 14px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  @media (max-width: ${ScreenSizes.mobile}) {
text-align: center;
font-size: 12px;
  }
`;

function ExternalBurnArea() {
  const showData = useSelector((state) => state.server.showData);
  const burnRedeemInfo = useSelector(
    (state) => state.web3TokenInfo.burnRedeemInfo
  );
  return (
    <ExternalBurnAreaEl>
      <ExplainEl>
        <Title>THIS BURN-REDEEM IS<MobileBr/>{" "}ACTIVE ON MANIFOLD.</Title>
        <SubTitle>
          The currently-active burn mandates the burning of an external
          token.Due to the special nature of this burn, we will have to redirect
          you to its Manifold page. Please click the button below to be
          transported there.
        </SubTitle>
      </ExplainEl>
      <RedeemItem
        showData={showData}
        burnCount={1}
        burnRedeemInfo={burnRedeemInfo}
      />
    </ExternalBurnAreaEl>
  );
}
export default ExternalBurnArea;
