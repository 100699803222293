import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import CollectionItem from "./CollectionItem";
import { componentActions } from "../../store/componentSlice";
import { ScreenSizes } from "../../styles/Themes";
import ArchiveEndStageVideo from "./ArchiveEndStageVideo";
import TheTimeStatus from "./TheTimeStatus/TheTimeStatus";

const ArchiveAreaEl = styled.div`
  padding-top: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  @media (max-width: ${ScreenSizes.mobile}) {
    padding-top: 24px;
    gap: 16px;
  }
`;

const CapterTitle = styled.span`
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 30px */
  letter-spacing: -0.2px;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%; /* 24px */
    letter-spacing: -0.15px;
  }
`;

const CollectionListEl = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 716px;
  gap: 8px;
  padding: 4px 32px;
  @media (max-width: ${ScreenSizes.mobile}) {
    gap: 4px;
    width: 330px;
    padding: 0px;
    justify-content: center;
  }
`;

function ArchiveArea() {
  const dispatch = useDispatch();
  const archiveData = useSelector((state) => state.web3TokenInfo.archiveData);

  function archiveModalHandler(isOpen, chapter, stage) {
    console.log("archiveModalOpenHandler",chapter,stage);
    dispatch(componentActions.setIsArchiveModalOpen(isOpen));
    dispatch(componentActions.setArchiveModalChapter(chapter));
    dispatch(componentActions.setArchiveModalStage(stage));
    dispatch(componentActions.setIsBlur(true));
  }
  return (
    <>
      {archiveData !== undefined && archiveData[3].length > 0 && (
        <ArchiveAreaEl>
          <CapterTitle>Archive - Chapter 4 : </CapterTitle>
          <ArchiveEndStageVideo
            archiveData={archiveData[3]}
            archiveModalHandler={archiveModalHandler}
            chapter={3}
          />
          <CollectionListEl>
            {archiveData[3].map((showData, index) => {
              return (
                <CollectionItem
                  chapter={3}
                  showData={showData}
                  archiveModalHandler={archiveModalHandler}
                  index={index}
                  key={index}
                />
              );
            })}
          </CollectionListEl>
        </ArchiveAreaEl>
      )}
      {archiveData !== undefined && archiveData[2].length > 0 && (
        <ArchiveAreaEl>
          <CapterTitle>Archive - Chapter 3 : The Carnival</CapterTitle>
          <ArchiveEndStageVideo
            archiveData={archiveData[2]}
            archiveModalHandler={archiveModalHandler}
            chapter={2}
          />
          <CollectionListEl>
            {archiveData[2].map((showData, index) => {
              return (
                <CollectionItem
                  chapter={2}
                  showData={showData}
                  archiveModalHandler={archiveModalHandler}
                  index={index}
                  key={index}
                />
              );
            })}
          </CollectionListEl>
        </ArchiveAreaEl>
      )}
      {archiveData !== undefined && archiveData[1].length > 0 && (
        <ArchiveAreaEl>
          <CapterTitle>Archive - Chapter 2 : The Adaptations</CapterTitle>
          <ArchiveEndStageVideo
            archiveData={archiveData[1]}
            archiveModalHandler={archiveModalHandler}
            chapter={1}
          />
          <CollectionListEl>
            {archiveData[1].map((showData, index) => {
              return (
                <CollectionItem
                  chapter={1}
                  showData={showData}
                  archiveModalHandler={archiveModalHandler}
                  index={index}
                  key={index}
                />
              );
            })}
          </CollectionListEl>
        </ArchiveAreaEl>
      )}
      {archiveData !== undefined && archiveData[0].length > 0 && (
        <ArchiveAreaEl>
          <CapterTitle>Archive - Chapter 1 : Survivors</CapterTitle>
          <ArchiveEndStageVideo
            archiveData={archiveData[0]}
            archiveModalHandler={archiveModalHandler}
            chapter={0}
          />
          <CollectionListEl>
            {archiveData[0].map((showData, index) => {
              return (
                <CollectionItem
                  chapter={0}
                  showData={showData}
                  archiveModalHandler={archiveModalHandler}
                  index={index}
                  key={index}
                />
              );
            })}
          </CollectionListEl>
        </ArchiveAreaEl>
      )}
      <TheTimeStatus/>
    </>
  );
}
export default ArchiveArea;
