import { useEffect } from "react";
import styled from "styled-components";
import { Colors, ScreenSizes } from "../../styles/Themes";

const BurnItemEl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (max-width: ${ScreenSizes.mobile}) {
    gap: 4px;
  }
`;

const BurnItemInfoEl = styled.div`
  width: ${(props) => (props.$burnCount > 1 ? "132px" : "184px")};
  height: ${(props) => (props.$burnCount > 1 ? "223px" : "291px")};
  border: 2px solid ${Colors.warning};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 102px;
    height: auto;
  }
`;

const BurnItemImgBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const BurnItemVideo = styled.video`
  width: 100%;
  height: auto;
  min-height: 70%;
`;

const BurnItemExplainEl = styled.div`
  width: 100%;
  height: 43px;
  border-top: 1px solid ${Colors.gr07};
  box-sizing: border-box;
  padding: 8px 0px 8px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 6px 16px;
  }
`;

const BurnItemName = styled.span`
  font-size: 15px;
  font-weight: 700;
  color: ${Colors.wt};
  line-height: 100%;
  font-family: NanumSquare;
  text-align: center;
  @media (max-width: ${ScreenSizes.mobile}) {
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
  }
`;

const BurnCountEl = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const BurnIcon = styled.img`
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 18px;
    height: auto;
  }
`;

const BurnCountText = styled.span`
  color: ${Colors.wt};
  font-size: 24px;
  line-height: 32px;
  font-family: NanumSquare;
  @media (max-width: ${ScreenSizes.mobile}) {
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
`;

const AllBurnImg = styled.img`
  width: 100%;
  height: auto;
  min-height: 70%;
`;

function BurnItem({ burnSet, burnCount }) {
  useEffect(() => {
    console.log("BurnItem burnSet : ", burnSet);
  });
  return (
    <BurnItemEl>
      {burnSet.items[0].uri !== undefined ? (
        <a
          href={"https://opensea.io/collection/burningsouls"}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <BurnItemInfoEl $burnCount={burnCount}>
            <BurnItemImgBox>
              <BurnItemVideo autoPlay muted loop playsInline>
                <source
                  src={burnSet.items[0].uri.animation_url}
                  type="video/mp4"
                />
              </BurnItemVideo>
            </BurnItemImgBox>
            <BurnItemExplainEl>
              <BurnItemName>{burnSet.items[0].uri.name}</BurnItemName>
            </BurnItemExplainEl>
          </BurnItemInfoEl>
        </a>
      ) : (
        <a
          href={"https://opensea.io/collection/burningsouls"}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <BurnItemInfoEl $burnCount={burnCount}>
            <BurnItemImgBox>
              <AllBurnImg src={"/images/ash.png"} />
            </BurnItemImgBox>
            <BurnItemExplainEl>
              <BurnItemName>All of this soul have been burned</BurnItemName>
            </BurnItemExplainEl>
          </BurnItemInfoEl>
        </a>
      )}
      <BurnCountEl>
        <BurnIcon src={"/icons/fire.svg"} />
        <BurnCountText> X {burnSet.requiredCount}</BurnCountText>
      </BurnCountEl>
    </BurnItemEl>
  );
}
export default BurnItem;
