import styled from "styled-components";
import { Colors, ScreenSizes } from "../../styles/Themes";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const ExplainEl = styled.div`
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const SubExplainText = styled.p`
  color: ${Colors.wt};
  font-family: NanumSquare;
  font-weight: 300;
  font-size: 16px;
  text-align: center;
  line-height: 30px;
  margin: 0;
  @media (max-width: ${ScreenSizes.tablet}) {
    font-size: 15.8px;
    line-height: 30px;
  }
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 13px;
    line-height: 24px;
  }
`;

const ManifoldFeeExplainText = styled.p`
  color: ${Colors.gr07};
  font-size: 12.6px;
  font-family: RovertSans;
  font-style: italic;
  font-weight: 300;
  line-height: 20px;
  margin: 0px;
  text-align: center;
  @media (max-width: ${ScreenSizes.tablet}) {
    font-size: 10.2px;
    line-height: 30px;
  }
  @media (max-width: ${ScreenSizes.mobile}) {
    text-align: center;
    font-size: 11px;
    line-height: 16px;
  }
`;

const HighLight = styled.span`
  color: ${props => props.theme.highlight};
`;

const MobileBr = styled.br`
  display: none;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: inline-block;
  }
`;

function BonusExplain() {
  const showData = useSelector((state) => state.server.bonusShowData);
  const [endMonth, setEndMonth] = useState("-");
  const [endDate, setEndDate] = useState("-");
  const [endHoursText, setEndHoursText] = useState("-");
  const [timezone, setTimezone] = useState("-");
  useEffect(() => {
    if (showData !== undefined) {
      const endDate = new Date(showData.publicData.endDate * 1000);
      const endHours = endDate.getHours() % 12;
      const endAMPM = endDate.getHours() >= 12 ? "PM" : "AM";
      setEndMonth(endDate.toLocaleString("en-US", { month: "short" }));
      setEndDate(endDate.getDate());
      setEndHoursText(endHours + endAMPM);
      setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    }
  }, [showData]);
  return (
    <ExplainEl>
      {(showData !== undefined) && (showData.publicData.startDate >
        Math.floor(new Date().getTime() / 1000)) && (
        <>
          <SubExplainText>
            This burn will start on{" "}
            <HighLight>
              {endMonth} {endDate}
            </HighLight>{" "}
            @ <HighLight>{endHoursText} </HighLight>
            {timezone}
          </SubExplainText>
        </>
      )}
      {showData !== undefined && showData.publicData.endDate !== 0 && (
        <>
          {showData.publicData.endDate >
          Math.floor(new Date().getTime() / 1000) ? (
            <>
              <SubExplainText>
                This burn will end on{" "}
                <HighLight>
                  {endMonth} {endDate}
                </HighLight>{" "}
                @ <HighLight>{endHoursText} </HighLight>
                {timezone}
              </SubExplainText>
            </>
          ) : (
            <>
              <SubExplainText>
                This burn has ended. Please look forward to the next burn.
              </SubExplainText>
            </>
          )}
        </>
      )}
      <ManifoldFeeExplainText>
        *A small service fee for Manifold will be included in the <MobileBr />
        gas you pay during minting and burning transactions.
      </ManifoldFeeExplainText>
    </ExplainEl>
  );
}
export default BonusExplain;
