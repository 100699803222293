import styled from "styled-components";
import { ScreenSizes } from "../../styles/Themes";
import { useSelector } from "react-redux";
import { useEffect } from 'react';
import { useState } from 'react';
import BurnRedeemButton from '../buttons/BurnRedeemButton';
// import LazyClaim from '../../extentions/lazyclaim/LazyClaim';
// import MerkleLazyClaim from '../../extentions/lazyclaim/MerkleLazyClaim';
// import BurnRedeem from '../../extentions/burnredeem/BurnRedeem';
// import SetApprove from '../../extentions/burnredeem/SetApprove';

const ClaimEl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 0px;
  }
`;

function BurnRedeemButtonArea() {
  const showData = useSelector((state) => state.server.showData);
  const [isBurnStart, setIsBurnStart] = useState(false);
  const burnRedeemInfo = useSelector((state) => state.web3TokenInfo.burnRedeemInfo);
  
  useEffect(()=>{
    if(showData !== undefined){
      const now = Math.floor((new Date()).getTime() / 1000);
      const start = Number(showData.publicData.startDate);
      const end = Number(showData.publicData.endDate);
      console.log("now : ",now);
      console.log("start : ",start);
      console.log("end : ",end);
      if((start <= now) && (end === 0)){
        setIsBurnStart(true);
      }
      if((start <= now) && (now <= end)){
        setIsBurnStart(true);
      }
      if(burnRedeemInfo.redeemedCount === burnRedeemInfo.totalSupply){
        setIsBurnStart(false);
      }
    }
  },[showData]);

  return (
    <ClaimEl>
      <BurnRedeemButton isBurnStart={isBurnStart}/>
    </ClaimEl>
  );
}
export default BurnRedeemButtonArea;
