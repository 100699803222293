export const ScreenSizes = {
  tablet: "1200px",
  mobile: "500px",
};

export const Colors = {
  gr01: "#050505",
  gr02: "#141414",
  gr03: "#292929",
  gr04: "#3D3D3D",
  gr05: "#5C5C5C",
  gr06: "#808080",
  gr07: "#BBBBBB",
  gr08: "#F2F2F2",
  gr200: "#BBBBBB",
  gr600: "#303036",
  gr700: "#1E1E1F",
  gr800: "#141414",
  wt: "#FFFFFF",
  highlight: "#FFFB9A",
  warning: "#F94D4D",
  archive: '#323232'
};

export const ChapterColors = [
  { highlight: "#FFFB9A" },
  { highlight: "#00CED1" },
  { highlight: "#8a2be2" },
  { highlight: "#ff69b4" },
  { highlight: "#778899" },
  { highlight: "#F90" },
];

export const Sizes = {
  HeaderHeight: "52px",
  MobileHeaderHeight: "40px",
  FooterHeight: "96px",
  MobileFooterHeight: "144px",
};
