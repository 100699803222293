import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import Web3 from "web3";
import CliamContractInfo from "../contracts/ERC721LazyPayableClaim.json";

const ClaimContractAddress = process.env.REACT_APP_CLAIM_EXTENSION_ADDRESS;

const initialState = {
  status: "wait",
  mintIndices: [],
  claimCount: -1,
};

const web3ClaimSlice = createSlice({
  name: "web3ClaimSlice",
  initialState,
  reducers: {
    selectData(state, action) {
      state.selectedData = current(state.rawData[action.payload]);
      console.log(state.selectedData);
      state.isSelected = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncWeb3CheckMintIndices.pending, (state) => {
        console.log("asyncWeb3CheckMintIndices","loading");
        state.status = "loading";
        state.claimCount = -1;
      })
      .addCase(asyncWeb3CheckMintIndices.fulfilled, (state, action) => {
        state.mintIndices = action.payload;
        state.status = "finish";
        console.log("asyncWeb3CheckMintIndices",state.mintIndices);
        let count = 0;
        for (let i = 0; i < state.mintIndices.length; i++) {
          if (state.mintIndices[i]) count++;
        }
        state.claimCount = count;
      })
      .addCase(asyncWeb3CheckMintIndices.rejected, (state, action) => {
        state.status = "error";
        console.log("asyncWeb3CheckMintIndices",action.payload);
        state.claimCount = -1;
      });
  },
});

const asyncWeb3CheckMintIndices = createAsyncThunk(
  "web3ClaimSlice/asyncWeb3CheckMintIndices",
  async (params, { rejectWithValue }) => {
    const { creatorContractAddress, instanceId, mintIndices, provider } =
      params;
    const web3 = new Web3(provider);
    let claimContract = new web3.eth.Contract(
      CliamContractInfo.abi,
      ClaimContractAddress
    );
    try {
      let result = await claimContract.methods
        .checkMintIndices(creatorContractAddress, instanceId, mintIndices)
        .call();
      return result;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export { asyncWeb3CheckMintIndices };
export const web3ClaimActions = web3ClaimSlice.actions;
export default web3ClaimSlice.reducer;
