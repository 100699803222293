import styled from "styled-components";
import { ScreenSizes, Sizes } from "../styles/Themes";
import { useSelector } from "react-redux";
import Title from "../components/main/Title";
import WalletConnectModal from "../components/modal/WalletConnect/WalletConnectModal";
import { useWeb3React } from "@web3-react/core";
import ChapterTitle from "../components/burnredeem/ChapterTitle";
import TestInformation from "../components/modal/dev/TestInformation";
import LoginPage from "./LoginPage";
import BaseArchiveModal from "../components/modal/archive/BaseArchiveModal";
import EndStageSubTitle from "../components/burnredeem/EndStage/EndStageSubTitle";
import EndStageVideo from "../components/burnredeem/EndStage/EndStageVideo";
import EndStageBurnArea from "../components/burnredeem/EndStage/EndStageBurnArea";
import { useEffect } from 'react';
import BonusArchiveArea from '../components/archive/BonusArchiveArea';
import BonusBurnRedeemArea from '../components/burnredeem/BonusBurnRedeemArea';
import BonusExplain from '../components/main/BonusExplain';
import BonusBaseBurnModal from '../components/modal/BurnRedeem/BonusBaseBurnModal';
import BonusBurnRedeemButtonArea from '../components/main/BonusBurnRedeemButtonArea';
import ExternalBurnArea from '../components/main/external/ExternalBurnArea';
import ExternalBurnRedeemButtonArea from '../components/main/external/ExternalBurnRedeemButtonArea';

const MainContentsEl = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  min-height: calc(100vh - ${Sizes.HeaderHeight} - ${Sizes.FooterHeight});
  padding-top: calc(${Sizes.HeaderHeight} + 16px);
  gap: 24px;
  padding-bottom: 120px;
  filter: ${(props) => (props.blur ? "blur(2px);" : "none;")};
  @media (max-width: ${ScreenSizes.tablet}) {
    padding-top: calc(${Sizes.HeaderHeight} + 8px);
    gap: 8px;
  }
  @media (max-width: ${ScreenSizes.mobile}) {
    padding-top: calc(${Sizes.MobileHeaderHeight} + 16px);
    gap: 10px;
    padding-bottom: 73px;
    min-height: calc(
      100vh - ${Sizes.HeaderHeight} - ${Sizes.MobileFooterHeight}
    );
  }
`;

function BonusPage({setChapterColor}) {
  const isBlur = useSelector((state) => state.component.isBlur);
  const hiddenCount = useSelector((state) => state.component.hiddenCount);
  const settingData = useSelector((state) => state.server.settingData);
  const showData = useSelector((state) => state.server.bonusShowData);
  const isExternal = useSelector((state) => state.server.bonusIsExternal);
  const { account } = useWeb3React();
  const isWalletModalOpen = useSelector(
    (state) => state.component.isWalletModalOpen
  );
  const isBonusBurnModalOpen = useSelector(
    (state) => state.component.isBonusBurnModalOpen
  );
  const isArchiveModalOpen = useSelector(
    (state) => state.component.isArchiveModalOpen
  );
  const settingDataStatus = useSelector(
    (state) => state.server.settingDataStatus
  );
  const getTokenInfoState = useSelector(
    (state) => state.web3TokenInfo.getTokenInfoState
  );

  useEffect(()=>{
    setChapterColor(0);
  },[]);

  return (
    <>
      {hiddenCount > 10 && <TestInformation settingData={settingData} />}

      {isWalletModalOpen && <WalletConnectModal />}

      {isBonusBurnModalOpen && <BonusBaseBurnModal />}

      {isArchiveModalOpen && <BaseArchiveModal />}

      {account === undefined ? (
        <LoginPage setChapterColor={setChapterColor}/>
      ) : (
        !(settingDataStatus === 1 || getTokenInfoState === 1) && (
          <MainContentsEl blur={isBlur}>
            <Title />
            <ChapterTitle showData={showData}/>

            {showData.stage !== 999 ? (
              <>
              {isExternal ? (
                  <>
                    <ExternalBurnArea />
                    <ExternalBurnRedeemButtonArea />
                  </>
                ) : (
                  <>
                <BonusBurnRedeemArea />
                <BonusBurnRedeemButtonArea />
                  </>
                )}
                <BonusExplain />
              </>
            ) : (
              <>
                <EndStageSubTitle />
                <EndStageVideo showData={showData} />
                <BonusBurnRedeemButtonArea />
                <BonusExplain />
                <EndStageBurnArea showData={showData} />
              </>
            )}
            {/* <ArchiveArea /> */}
            <BonusArchiveArea />
          </MainContentsEl>
        )
      )}
    </>
  );
}
export default BonusPage;
