import styled from "styled-components";
import { ScreenSizes, Sizes } from "../styles/Themes";
import ConnectWalletButton from "../components/buttons/ConnectWalletButton";
import LoginVideo from "../components/loginPage/LoginVideo";
import LoginExplain from "../components/loginPage/LoginExplain";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const TitleText = styled.span`
  color: ${(props) => props.theme.highlight};
  text-align: center;
  font-family: NanumSquareOTF;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 57.6px */
  letter-spacing: -0.36px;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%; /* 20.8px */
    letter-spacing: -0.13px;
  }
`;

const MainContentsEl = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  min-height: calc(100vh - ${Sizes.HeaderHeight} - ${Sizes.FooterHeight});
  padding-top: calc(${Sizes.HeaderHeight} + 16px);
  gap: 18px;
  padding-bottom: 120px;
  filter: ${(props) => (props.blur ? "blur(2px);" : "none;")};
  @media (max-width: ${ScreenSizes.mobile}) {
    padding-top: calc(${Sizes.MobileHeaderHeight} + 16px);
    gap: 10px;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
  }
`;

const BottomVideoEl = styled.div`
  width: 100%;
  /* padding: 16px 0px 16px 0px; */
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding-top: 22px;
  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 0px 5px 0px 5px;
  }
`;

const BottomVideo = styled.video`
  width: 600px;
  height: auto;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 100%;
    height: auto;
  }
`;

const FlameLeftEl = styled.div`
  position: fixed;
  left: 0px;
  top: 52px;
  z-index: 10;
  height: 100vh;
  @media (max-width: ${ScreenSizes.mobile}) {
    z-index: -1;
    left: -60px;
  }
`;

const FlameRightEl = styled.div`
  position: fixed;
  right: 0px;
  top: 52px;
  z-index: 10;
  height: 100vh;
  @media (max-width: ${ScreenSizes.mobile}) {
    z-index: -1;
    right: -20px;
  }
`;

function LoginPage({ setChapterColor }) {
  const isBtaFinish = useSelector((state) => state.server.isBtaFinish);
  console.log("LoginPage",isBtaFinish);
  useEffect(() => {
    if (isBtaFinish) {
      setChapterColor(0);
    } else {
      setChapterColor(5);
    }
  }, [isBtaFinish]);
  return (
    <>
      <MainContentsEl>
        <FlameLeftEl>
          {isBtaFinish ? (
            <img
              style={{ height: "100%" }}
              src={"/images/smoke_left.png"}
              alt="smoke left"
            />
          ) : (
            <img
              style={{ height: "100%" }}
              src={"/images/flame_left.gif"}
              alt="flame left"
            />
          )}
        </FlameLeftEl>
        <FlameRightEl>
          {isBtaFinish ? (
            <img
              style={{ height: "100%" }}
              src={"/images/smoke_right.png"}
              alt="flame right"
            />
          ) : (
            <img
              style={{ height: "100%" }}
              src={"/images/flame_right.gif"}
              alt="flame left"
            />
          )}
        </FlameRightEl>
        <TitleText>
          {isBtaFinish
            ? "THE BURNING OF THE SOULS IS NOW COMPLETE."
            : "THE FINAL ACT : EVERYTHING BURNS"}
        </TitleText>
        <LoginVideo />
        <LoginExplain />
        <ConnectWalletButton type={"main"} />
        <BottomVideoEl>
          {/* BTA종료에 따른 비디오 소스 분기 필요 */}
          <BottomVideo autoPlay muted loop playsInline>
            {isBtaFinish ? (
              <source
                src={"https://burningsouls.com/public/videos/Intro.mp4"}
                type="video/mp4"
              />
            ) : (
              <source
                src={"https://burningsouls.com/public/videos/BTA_intro.mp4"}
                type="video/mp4"
              />
            )}
          </BottomVideo>
        </BottomVideoEl>
      </MainContentsEl>
    </>
  );
}
export default LoginPage;
