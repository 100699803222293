import { useEffect } from "react";
import styled from "styled-components";
import { Colors, ScreenSizes } from "../../../../styles/Themes";
import { useDispatch, useSelector } from "react-redux";
import { asyncWeb3IsApprovedForAll } from "../../../../store/web3BurnSlice";
import { useWeb3React } from "@web3-react/core";
import { componentActions } from "../../../../store/componentSlice";


const CheckSetApprovedForAllEl = styled.div`
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
`;

const LoaingImg = styled.img`
  width: 48px;
`;

function CheckSetApprovalForAll() {
  const dispatch = useDispatch();
  const { account, library } = useWeb3React();
  const isApprovedForAllStatus = useSelector((state) => state.web3Burn.isApprovedForAllStatus);
  const isApprovedForAll = useSelector((state) => state.web3Burn.isApprovedForAll);
  useEffect(() => {
    dispatch(componentActions.setIsProcessing(true));
    dispatch(
      asyncWeb3IsApprovedForAll({ owner: account, provider: library.provider })
    );
  }, []);

  useEffect(() => {
    if (isApprovedForAllStatus === 2) {
      if(isApprovedForAll){
        dispatch(componentActions.setBurnModalStep(4));
      }else{
        dispatch(componentActions.setBurnModalStep(3));
      }
    }else if(isApprovedForAllStatus === 3){
      console.log("Redirect to Error in CheckSetApprovalForAll");
      dispatch(componentActions.setBurnModalStep(6));
    }
  }, [isApprovedForAllStatus]);
  return (
    <CheckSetApprovedForAllEl>
      <LoaingImg src={"/images/progress_gif.gif"} />
    </CheckSetApprovedForAllEl>
  );
}
export default CheckSetApprovalForAll;
