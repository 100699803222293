import styled from "styled-components";

const EndStageItemEl = styled.div`
  width: 102px;
  height: auto;
  border-radius: 2px;
  border: 1px solid #777;
`;

const ItemVideo = styled.video`
  width: 100%;
  height: auto;
  min-height: 130px;
  background-image: "./images/progress_gif.gif";
`;

function EndStageItem({ burnItem }) {
  return (
    <EndStageItemEl>
      {burnItem !== undefined && (
        <ItemVideo poster={"/images/progress_gif.gif"} autoPlay muted loop playsInline>
          <source src={burnItem.items[0].uri.animation} type="video/mp4" />
        </ItemVideo>
      )}
    </EndStageItemEl>
  );
}
export default EndStageItem;
