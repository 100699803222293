import styled from "styled-components";
import { Colors, ScreenSizes } from "../../styles/Themes";
import { useSelector } from "react-redux";

const Button = styled.button`
  color: ${Colors.gr01};
  width: auto;
  height: 59px;
  padding: 16px 24px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f2f2f2;
  background: ${props => props.theme.highlight};
  border-radius: 8px;
  font-family: NanumSquare;
  font-size: 24px;
  font-weight: 700;
  ${(props) => (props.$isBurnStart ? "" : "opacity:0.3")};
  ${(props) => (props.$isBurnStart ? "" : "cursor: pointer")};
  cursor: default;
  &:hover {
    filter: brightness(80%);
  }
  @media (max-width: ${ScreenSizes.mobile}) {
    height: 40px;
    border-radius: 6px;
    font-size: 18px;
  }
`;
function ExternalBurnRedeemButton({ isBurnStart }) {
  const showData = useSelector((state) => state.server.showData);

  function redirectHandler() {
    if (!isBurnStart) return;
    window.open("https://app.manifold.xyz/br/" + showData.slug, "_blank");
  }
  return (
    <Button $isBurnStart={isBurnStart} onClick={redirectHandler}>
      VISIT MANIFOLD PAGE
    </Button>
  );
}
export default ExternalBurnRedeemButton;
