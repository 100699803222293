import styled from "styled-components";
import { Colors, ScreenSizes } from "../../../styles/Themes";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const TheTimeStatusEl = styled.div`
  padding-top: 32px;
  display: flex;
  flex-direction: row;
  @media (max-width: ${ScreenSizes.mobile}) {
    padding-top: 14px;
  }
`;

const LeftEl = styled.div`
  width: 265px;
  height: 80px;
  overflow: hidden;
  position: relative;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 117px;
    height: 55px;
  }
`;

const BaseImg = styled.img`
  position: absolute;
  filter: blur(1.8px);
  @media (max-width: ${ScreenSizes.mobile}) {
    top: -12px;
    left: -20px;
    width: 184px;
    height: auto;
    display: none;
  }
`;

const BaseImgMobile = styled.img`
  display: none;
  @media (max-width: ${ScreenSizes.mobile}) {
    position: absolute;
    filter: blur(1.8px);
    width: auto;
    height: auto;
    display: inline-block;
  }
`;

const Overlay = styled.div`
  color: ${Colors.wt};
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  font-family: NanumSquare;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px; /* 200% */
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 9.6px;
    line-height: 19.2px; /* 200% */
  }
`;

const RightEl = styled.div`
  width: 265px;
  height: 80px;
  display: flex;
  flex-direction: column;
  padding: 10px 63px;
  box-sizing: border-box;
  background: #141313;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 117px;
    height: 55px;
    padding: 4px 10px;
  }
`;

const StatusText = styled.span`
  color: #fff;
  font-family: NanumSquare;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 11px;
    font-weight: 400;
    line-height: 16px; /* 145.455% */
  }
`;

export default function TheTimeStatus() {
  const tokenIdArray = useSelector((state) => state.web3TokenInfo.tokenIdArray);
  // console.log("TheTimeStatus", tokenIdArray);
  const [sacrificedCount, setSacrificedCount] = useState(0);
  useEffect(() => {
    let sacriCount = 0;
    for (let i = 1; i <= 7664; i++) {
      if (tokenIdArray[i] === "0x0000000000000000000000000000000000000000")
        sacriCount++;
    }
    setSacrificedCount(sacriCount);
  }, [sacrificedCount, tokenIdArray]);

  return (
    <TheTimeStatusEl>
      <LeftEl>
        <BaseImg src={"/images/status_desktop.gif"} />
        <BaseImgMobile src={"/images/status_mobile.gif"} />
        <Overlay>THE TIME</Overlay>
      </LeftEl>
      <RightEl>
        <StatusText>7,664 free-claimed</StatusText>
        <StatusText style={{ color: "#E14E2E" }}>
          {sacrificedCount
            .toString()
            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}{" "}
          sacrificed
        </StatusText>
        <StatusText>
          {(7664 - sacrificedCount)
            .toString()
            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}{" "}
          remaining
        </StatusText>
      </RightEl>
    </TheTimeStatusEl>
  );
}
