import { Link as ReactRouterDomLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Colors, ScreenSizes, Sizes } from "../../styles/Themes";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConnectWalletButton from "../buttons/ConnectWalletButton";
import MobileMenu from "./MobileMenu";
import { componentActions } from "../../store/componentSlice";
import { useWeb3React } from "@web3-react/core";
import { MainLogo } from "../../styles/Icons";
import ConnectWalletButtonEmergency from '../buttons/ConnectWalletButton_emergency';

const Link = ({ children, isActive, ...props }) => {
  return <ReactRouterDomLink {...props}>{children}</ReactRouterDomLink>;
};

const HeaderEl = styled.header`
  display: flex;
  position: fixed;
  top: 0px;
  width: 100%;
  height: ${Sizes.HeaderHeight};
  justify-content: space-between;
  align-items: center;
  background-color: ${Colors.gr02};
  box-sizing: border-box;
  z-index: 500;
  padding: 0px 32px 0px 32px;
  filter: ${(props) => (props.blur ? "blur(2px);" : "none;")};
  @media (max-width: ${ScreenSizes.mobile}) {
    height: ${Sizes.MobileHeaderHeight};
    background-color: ${Colors.gr02};
    padding-right: 8px;
    padding-left: 8px;
    padding-top: 0px;
    padding-bottom: ${(props) => (props.bottomLine ? "0px" : "0.8px")};
    border-bottom: ${(props) => (props.bottomLine ? "1px solid #F2F2F2" : "")};
  }
`;

const HeaderLeftEl = styled.div`
  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 0px 0px 0px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
`;

const HeaderLogoBox = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  cursor: pointer;
  @media (max-width: ${ScreenSizes.mobile}) {
    height: 12px;
    width: 137.4px;
  }
`;

const HeaderLogoImg = styled.img`
  width: auto;
  height: 16px;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: auto;
    height: 12px;
  }
`;

const HeaderRightEl = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: none;
  }
`;

const HeaderMenuItem = styled(Link)`
  width: 168px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.gr07};
  font-family: NanumSquare;
  font-size: 18px;
  text-decoration: none;
  &:hover {
    color: ${props => props.theme.highlight};
    border-bottom: 1px solid ${props => props.theme.highlight};
  }
`;

const HeaderMobileRightEl = styled.div`
  display: none;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: flex;
    align-items: center;
  }
`;

const MobileMenuButtonImg = styled.img``;

function Header() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const isBlur = useSelector((state) => state.component.isBlur);
  const isBonusOpen = useSelector((state) => state.server.isBonusOpen);
  const isBonusMain = useSelector((state) => state.server.isBonusMain);
  const dispatch = useDispatch();
  const { account } = useWeb3React();

  function mobileMenuHandler() {
    dispatch(componentActions.setMobileMenuOpen());
    dispatch(componentActions.setIsBlur(!isMobileMenuOpen));
    setIsMobileMenuOpen(!isMobileMenuOpen);
  }
  return (
    <>
      <HeaderEl bottomLine={isMobileMenuOpen} blur={isBlur}>
        <HeaderLeftEl>
          <HeaderLogoBox to={"/"}>
            {/* <HeaderLogoImg src={"/images/header_logo.svg"} alt="logo image" /> */}
            <MainLogo />
          </HeaderLogoBox>
        </HeaderLeftEl>
        {isBonusOpen ? (
          isBonusMain ? (
            <HeaderRightEl>
              {account !== undefined && (
                <HeaderMenuItem to={"/"}>Bonus</HeaderMenuItem>
              )}
              {account !== undefined && (
                <HeaderMenuItem to={"/bta"}>Burn Them All</HeaderMenuItem>
              )}
              <HeaderMenuItem to={"/about"}>About</HeaderMenuItem>
              <ConnectWalletButton />
            </HeaderRightEl>
          ) : (
            <HeaderRightEl>
              {account !== undefined && (
                <HeaderMenuItem to={"/bonus"}>Bonus</HeaderMenuItem>
              )}
              {account !== undefined && (
                <HeaderMenuItem to={"/"}>Burn Them All</HeaderMenuItem>
              )}
              <HeaderMenuItem to={"/about"}>About</HeaderMenuItem>
              <ConnectWalletButton />
            </HeaderRightEl>
          )
        ) : (
          <HeaderRightEl>
            <HeaderMenuItem to={"/about"}>About</HeaderMenuItem>
            <ConnectWalletButton />
          </HeaderRightEl>
        )}
        <HeaderMobileRightEl onClick={mobileMenuHandler}>
          <MobileMenuButtonImg src={"/icons/menu_24px.svg"} />
        </HeaderMobileRightEl>
      </HeaderEl>
      {isMobileMenuOpen && (
        <MobileMenu
          mobileMenuHandler={mobileMenuHandler}
          account={account}
          isBonusOpen={isBonusOpen}
        />
      )}
    </>
  );
}

export default Header;
