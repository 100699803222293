import styled from "styled-components";
import { Colors, ScreenSizes } from "../../styles/Themes";

const ExplainEl = styled.div``;

const SubExplainText = styled.p`
  color: ${Colors.wt};
  font-family: NanumSquare;
  font-weight: 300;
  font-size: 20px;
  text-align: center;
  line-height: 32px;
  margin: 0;
  @media (max-width: ${ScreenSizes.mobile}) {
    color: #fff;
    font-size: 14px;
    font-family: NanumSquare;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
`;

function LoginExplain() {
  return (
    <ExplainEl>
      <SubExplainText>
        Connect your wallet to access Burning Souls.
      </SubExplainText>
    </ExplainEl>
  );
}
export default LoginExplain;
