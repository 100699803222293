import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Web3 from "web3";
import CliamContractInfo from "../contracts/ERC721LazyPayableClaim.json";

const ClaimContractAddress = process.env.REACT_APP_CLAIM_EXTENSION_ADDRESS;

const ABI = CliamContractInfo.abi;

const initialState = {
  isMinting: 0,
  tokenId: 0,
};

const web3MintSlice = createSlice({
  name: "web3MintSlice",
  initialState,
  reducers: {
    initiateMinting(state) {
      state.isMinting = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncWeb3Mint.pending, (state) => {
        console.log("now minting");
        state.isMinting = 1;
      })
      .addCase(asyncWeb3Mint.fulfilled, (state, action) => {
        console.log(action.payload);
        const txResult = action.payload;
        const hexTokenId = txResult.events["0"].raw["topics"][3];
        const resultTokenId = parseInt(hexTokenId);
        console.log("tokenId", resultTokenId);
        state.tokenId = resultTokenId;
        state.isMinting = 2;
      })
      .addCase(asyncWeb3Mint.rejected, (state, action) => {
        console.log(action.payload);
        state.isMinting = 3;
      });

    builder
      .addCase(asyncWeb3MintBatch.pending, (state) => {
        console.log("now minting");
        state.isMinting = 1;
      })
      .addCase(asyncWeb3MintBatch.fulfilled, (state, action) => {
        console.log(action.payload);
        const txResult = action.payload;
        const hexTokenId = txResult.events["0"].raw["topics"][3];
        const resultTokenId = parseInt(hexTokenId);
        console.log("tokenId", resultTokenId);
        state.tokenId = resultTokenId;
        state.isMinting = 2;
      })
      .addCase(asyncWeb3MintBatch.rejected, (state, action) => {
        console.log(action.payload);
        state.isMinting = 3;
      });
  },
});

const asyncWeb3Mint = createAsyncThunk(
  "web3MintSlice/asyncWeb3Mint",
  async (params, { getState, rejectWithValue }) => {
    const { account, value, provider } = params;
    const { server } = getState();
    const { web3Claim } = getState();
    const creatorContractAddress =
      server.settingData.data.publicData.creatorContractAddress;
    const instanceId = server.settingData.data.id;
    const claimCount = web3Claim.claimCount;
    const mintIndex = server.proofs[claimCount].value;
    const merkleProof = server.proofs[claimCount].merkleProof;
    const mintFor = account;

    console.log(
      creatorContractAddress,
      instanceId,
      mintIndex,
      merkleProof,
      mintFor,
      account,
      value
    );

    const web3 = new Web3(provider);
    const claimContract = new web3.eth.Contract(ABI, ClaimContractAddress);
    try {
      let result = await claimContract.methods
        .mint(
          creatorContractAddress,
          instanceId,
          mintIndex,
          merkleProof,
          mintFor
        )
        .send({ from: account, value });
      return result;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const asyncWeb3MintBatch = createAsyncThunk(
  "web3MintSlice/asyncWeb3MintBatch",
  async (params, { getState, rejectWithValue }) => {
    const { account, value, provider, mintCount } = params;
    const { server } = getState();
    const { web3Claim } = getState();
    const creatorContractAddress =
      server.settingData.data.publicData.creatorContractAddress;
    const instanceId = server.settingData.data.id;
    const claimCount = web3Claim.claimCount;
    // const mintIndex = server.proofs[claimCount].value;
    let mintIndices = [];
    let merkleProofs = [];
    for (let i = 0; i < mintCount; i++) {
      mintIndices.push(server.proofs[claimCount + i].value);
      merkleProofs.push(server.proofs[claimCount + i].merkleProof);
    }
    const mintFor = account;

    console.log(
      creatorContractAddress,
      instanceId,
      mintCount,
      mintIndices,
      merkleProofs,
      mintFor,
      account,
      value
    );

    const web3 = new Web3(provider);
    const claimContract = new web3.eth.Contract(ABI, ClaimContractAddress);
    try {
      let result = await claimContract.methods
        .mintBatch(
          creatorContractAddress,
          instanceId,
          mintCount,
          mintIndices,
          merkleProofs,
          mintFor
        )
        .send({ from: account, value });
      return result;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export { asyncWeb3Mint, asyncWeb3MintBatch };
export const web3MintActions = web3MintSlice.actions;
export default web3MintSlice.reducer;
