import styled from "styled-components";
import { Colors, ScreenSizes } from "../../styles/Themes";
import { useDispatch } from 'react-redux';
import { componentActions } from '../../store/componentSlice';

const RedeemEl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const RedeemItemsEl = styled.div`
  width: ${(props) => (props.$burnCount > 3 ? "348px" : "280px")};
  height: auto;
  border: 2px solid ${props => props.theme.highlight};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 296px;
    height: auto;
    flex-direction: row-reverse;
  }
`;

const RedeemItemImgBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
`;
const RedeemItemVideo = styled.video`
  width: 100%;
  height: auto;
  object-fit: cover;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 176px;
    height: auto;
  }
`;

const RedeemItemExplainEl = styled.div`
  width: 100%;
  height: 98px;
  border-top: 1px solid ${Colors.gr07};
  box-sizing: border-box;
  padding: 16px 0px 16px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 120px;
    height: auto;
    padding: 12px;
    box-sizing: border-box;
  }
`;

const RedeemItemName = styled.span`
  font-size: 17px;
  font-weight: 800;
  color: ${Colors.wt};
  line-height: 130%;
  font-family: NanumSquare;
  text-align: center;
  @media (max-width: ${ScreenSizes.mobile}) {
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 130%;
  }
`;

const RedeemItemCount = styled.span`
  color: ${Colors.gr200};
  font-size: 14px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  text-align:center;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 11px;
  }
`;

function RedeemItem({ showData, burnCount, burnRedeemInfo }) {
  const dispatch = useDispatch();
  function redeemModalHandler(isOpen) {
    dispatch(componentActions.setIsRedeemModalOpen(isOpen));
    dispatch(componentActions.setIsBlur(true));
  }
  return (
    <RedeemEl>
      <RedeemItemsEl $burnCount={burnCount}>
        <RedeemItemImgBox>
          <RedeemItemVideo autoPlay muted loop playsInline onClick={(e)=>redeemModalHandler(true)}>
            <source
              src={showData.publicData.redeemAssetMedia}
              type="video/mp4"
            />
          </RedeemItemVideo>
        </RedeemItemImgBox>
        <RedeemItemExplainEl>
          <RedeemItemName>{showData.publicData.redeemTokenName}</RedeemItemName>
          <RedeemItemCount>
            ({burnRedeemInfo.redeemedCount}/{burnRedeemInfo.totalSupply})
            Claimed
          </RedeemItemCount>
        </RedeemItemExplainEl>
      </RedeemItemsEl>
    </RedeemEl>
  );
}
export default RedeemItem;
