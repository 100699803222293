import styled from "styled-components";
import { Colors, ScreenSizes } from "../../styles/Themes";
import { useWeb3React } from "@web3-react/core";
import { useDispatch } from "react-redux";
import { componentActions } from "../../store/componentSlice";
import { useState } from "react";

const ConnectWalletButtonEl = styled.button`
  width: ${(props) => (props.type === "main" ? "219px" : "160px")};
  height: ${(props) => (props.type === "main" ? "59px" : "40px")};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${props => props.theme.highlight};
  border-radius: 7px;
  color: ${props => props.theme.highlight};
  font-family: NanumSquare;
  font-weight: 400;
  font-size: ${(props) => (props.type === "main" ? "24px" : "18px")};
  background: none;
  cursor: pointer;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: ${(props) => (props.type === "main" ? "165px" : "165px")};
    height: ${(props) => (props.type === "main" ? "40px" : "40px")};
    font-size: ${(props) => (props.type === "main" ? "17px" : "17px")};
  }
  &:hover {
    background: ${props => props.theme.highlight};
    color: ${Colors.gr01};
    font-weight: 600;
  }
`;

function ConnectWalletButton({ type }) {
  const { account, deactivate } = useWeb3React();
  const [isHover, setIsHover] = useState(false);
  const dispatch = useDispatch();

  function openModalHandler(isOpen) {
    setIsHover(false);
    dispatch(componentActions.setIsWalletModalOpen(isOpen));
    dispatch(componentActions.setIsBlur(isOpen));
  }

  function disconnectHandler() {
    deactivate();
  }
  return (
    <>
      {account === undefined ? (
        <ConnectWalletButtonEl
          type={type}
          onClick={(e) => {
            openModalHandler(true, e);
          }}
        >
          Connect Wallet
        </ConnectWalletButtonEl>
      ) : (
        <ConnectWalletButtonEl
          onClick={disconnectHandler}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          {isHover
            ? "Disconnect"
            : account.substr(0, 5) + "..." + account.substr(37, 42)}
        </ConnectWalletButtonEl>
      )}
    </>
  );
}
export default ConnectWalletButton;
