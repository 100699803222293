import { Outlet } from "react-router-dom";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import LoadingPage from "./LoadingPage";
import { useSelector } from "react-redux";

function RootPage() {
  const settingDataStatus = useSelector(
    (state) => state.server.settingDataStatus
  );
  const getTokenInfoState = useSelector(
    (state) => state.web3TokenInfo.getTokenInfoState
  );
  return (
    <>
      {settingDataStatus === 1 || getTokenInfoState === 1 ? (
        <LoadingPage />
      ) : (
        <>
          <Header />
          <Outlet />
          <Footer />
        </>
      )}
    </>
  );
}
export default RootPage;
