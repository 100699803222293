import styled from "styled-components";
import { useSelector } from "react-redux";
import { Colors, ScreenSizes } from "../../../styles/Themes";
import { useEffect } from "react";
import { useState } from "react";
import { useWeb3React } from "@web3-react/core";

const ArchiveItemEl = styled.div`
  width: 100%;
  height: auto;
  padding: 24px 64px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 12px;
  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 16px 24px 0px 24px;
    gap: 16px;
    align-items: center;
  }
`;

const ItemVideo = styled.video`
  width: 720px;
  height: auto;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 144px;
  }
`;

const ItemInfoEl = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (max-width: ${ScreenSizes.mobile}) {
    gap: 6px;
  }
`;

const ItemName = styled.span`
  color: #fff;
  font-size: 24px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 12px;
  }
`;

const NFTInfoEl = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${ScreenSizes.mobile}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 9px;
  }
`;

const NFTOwnCount = styled.span`
  color: #fff;
  font-size: 16px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 10px;
    line-height: 140%;
  }
`;

const NFTOwnCountHighlight = styled.span`
  color: ${(props) => props.theme.highlight};
  font-size: 16px;
  font-family: NanumSquare;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 10px;
    line-height: 140%;
  }
`;

const LinkEl = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const LinkImg = styled.img`
  width: 19px;
  height: auto;
`;

const DescriptionEl = styled.div`
  max-width: 720px;
  max-height: 60px;
  overflow-y: auto;
  width: 100%;
  height: auto;
  border-radius: 8px;
  background: #1e1e1f;
  padding: 16px;
  box-sizing: border-box;
  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 12px 16px;
  }
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${Colors.gr07}; /* 스크롤바 색상 */
    border-radius: 3px; /* 스크롤바 둥근 테두리 */
  }
  &::-webkit-scrollbar-track {
    background: ${Colors.gr05}; /*스크롤바 뒷 배경 색상*/
  }
`;

const Description = styled.span`
  color: #fff;
  font-size: 16px;
  font-family: Robert Sans;
  font-style: italic;
  font-weight: 400;
  line-height: 28px;
  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 11px;
    line-height: 150%;
  }
`;

function BTAArchiveItem() {
  const chapter = 5;
  const stage = 0;
  const showData = useSelector((state) => state.server.BTAShowData);
  const archiveData = useSelector((state)=>state.server.BTARawArchiveData);
  const btaUser = useSelector((state)=>state.server.btaUser);

  const [ownCount, setOwnCount] = useState(0);

  const { account } = useWeb3React();

  useEffect(() => {
    console.log("BTAArchiveItem", archiveData[chapter][stage]);
    if (btaUser === account) {
      setOwnCount(1);
    }
  }, []);
  return (
    <>
      <ArchiveItemEl>
        {archiveData !== undefined && (
          <ItemVideo
            $isEndStage={stage === 999}
            autoPlay
            loop
            controls
            playsInline
          >
            <source
              src={archiveData[chapter][stage].publicData.redeemAssetMedia}
              type="video/mp4"
            />
          </ItemVideo>
        )}
        <ItemInfoEl>
          <ItemName>{archiveData[chapter][stage].publicData.name}</ItemName>
          <NFTOwnCount>
            You own : <NFTOwnCountHighlight>{ownCount}</NFTOwnCountHighlight>
          </NFTOwnCount>
          <NFTInfoEl>
            <LinkEl>
              <a
                href={
                  process.env.REACT_APP_ETHERSCAN_URL +
                  "address/" +
                  showData.publicData.redeemContractAddress
                }
                target="_blank"
                rel="noreferrer"
              >
                <LinkImg src={"/icons/etherscan.svg"} />
              </a>
              <a
                href={
                  process.env.REACT_APP_OPENSEA_URL +
                  showData.publicData.redeemContractAddress
                }
                target="_blank"
                rel="noreferrer"
              >
                <LinkImg src={"/icons/opensea.svg"} />
              </a>
            </LinkEl>
          </NFTInfoEl>
          <DescriptionEl $isEndStage={stage === 999}>
            <Description>
              {archiveData[chapter][stage].publicData.description}
            </Description>
          </DescriptionEl>
        </ItemInfoEl>
      </ArchiveItemEl>
    </>
  );
}
export default BTAArchiveItem;
