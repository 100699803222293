import styled from "styled-components";
import { ScreenSizes, Sizes } from "../styles/Themes";
import { useSelector } from "react-redux";
import Title from "../components/main/Title";
import Explain from "../components/main/Explain";
import WalletConnectModal from "../components/modal/WalletConnect/WalletConnectModal";
import { useWeb3React } from "@web3-react/core";
import ChapterTitle from "../components/burnredeem/ChapterTitle";
import BurnRedeemArea from "../components/burnredeem/BurnRedeemArea";
import TestInformation from "../components/modal/dev/TestInformation";
import LoginPage from "./LoginPage";
import BaseBurnModal from "../components/modal/BurnRedeem/BaseBurnModal";
import ArchiveArea from "../components/archive/ArchiveArea";
import BaseArchiveModal from "../components/modal/archive/BaseArchiveModal";
import EndStageSubTitle from "../components/burnredeem/EndStage/EndStageSubTitle";
import EndStageVideo from "../components/burnredeem/EndStage/EndStageVideo";
import EndStageBurnArea from "../components/burnredeem/EndStage/EndStageBurnArea";
import BurnRedeemButtonArea from "../components/main/BurnRedeemButtonArea";
import ExternalBurnArea from "../components/main/external/ExternalBurnArea";
import ExternalBurnRedeemButtonArea from "../components/main/external/ExternalBurnRedeemButtonArea";
import { useEffect } from 'react';
import BaseRedeemModal from '../components/modal/RedeemModal/BaseRedeemModal';

const MainContentsEl = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  min-height: calc(100vh - ${Sizes.HeaderHeight} - ${Sizes.FooterHeight});
  padding-top: calc(${Sizes.HeaderHeight} + 24px);
  gap: 24px;
  padding-bottom: 120px;
  filter: ${(props) => (props.blur ? "blur(2px);" : "none;")};
  @media (max-width: ${ScreenSizes.tablet}) {
    padding-top: calc(${Sizes.HeaderHeight} + 8px);
    gap: 8px;
  }
  @media (max-width: ${ScreenSizes.mobile}) {
    padding-top: calc(${Sizes.MobileHeaderHeight} + 16px);
    gap: 10px;
    padding-bottom: 73px;
    min-height: calc(
      100vh - ${Sizes.HeaderHeight} - ${Sizes.MobileFooterHeight}
    );
  }
`;

function MainPage({setChapterColor}) {
  const isBlur = useSelector((state) => state.component.isBlur);
  const hiddenCount = useSelector((state) => state.component.hiddenCount);
  const settingData = useSelector((state) => state.server.settingData);
  const showData = useSelector((state) => state.server.showData);
  const isExternal = useSelector((state) => state.server.isExternal);
  const { account } = useWeb3React();
  const isWalletModalOpen = useSelector(
    (state) => state.component.isWalletModalOpen
  );
  const isBurnModalOpen = useSelector(
    (state) => state.component.isBurnModalOpen
  );
  const isArchiveModalOpen = useSelector(
    (state) => state.component.isArchiveModalOpen
  );
  const isRedeemModalOpen = useSelector(
    (state) => state.component.isRedeemModalOpen
  )
  const settingDataStatus = useSelector(
    (state) => state.server.settingDataStatus
  );
  const getTokenInfoState = useSelector(
    (state) => state.web3TokenInfo.getTokenInfoState
  );
  useEffect(()=>{
    if(showData !== undefined){
      setChapterColor(showData.chapter-1);
      console.log("ChapterColor : ",showData.chapter-1);
    }
  },[]);

  return (
    <>
      {hiddenCount > 10 && <TestInformation settingData={settingData} />}

      {isWalletModalOpen && <WalletConnectModal />}

      {isBurnModalOpen && <BaseBurnModal />}

      {isArchiveModalOpen && <BaseArchiveModal />}

      {isRedeemModalOpen && <BaseRedeemModal showData={showData}/>}

      {account === undefined ? (
        <LoginPage />
      ) : (
        !(settingDataStatus === 1 || getTokenInfoState === 1) && (
          <MainContentsEl blur={isBlur}>
            {/* <Title /> */}
            <ChapterTitle showData={showData} />
            {showData.stage !== 999 ? (
              <>
                {isExternal ? (
                  <>
                    <ExternalBurnArea />
                    <ExternalBurnRedeemButtonArea />
                  </>
                ) : (
                  <>
                    <BurnRedeemArea />
                    <BurnRedeemButtonArea />
                  </>
                )}
                <Explain />
              </>
            ) : (
              <>
                <EndStageSubTitle />
                <EndStageVideo showData={showData} />
                <BurnRedeemButtonArea />
                <Explain />
                <EndStageBurnArea showData={showData} />
              </>
            )}
            <ArchiveArea />
          </MainContentsEl>
        )
      )}
    </>
  );
}
export default MainPage;
